<template>
    
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
        
        <StartPrompt v-if="isEscrowPrompt" :fiatString="fiatString" :sat="sat" :ad="ad"/>

        <div class="row modal-content center white" v-else>
            
            <div class = "col s12 center-align orange white-text">
                <h5>Detalles del Anuncio</h5>
            </div>
            
            <div class = "col s12 left-align grey-text text-darken-3 marginTop">
                
                <h6>
                    <b class="orange-text">{{ad.adType}}</b> {{ad.monto}} USD en <i class="fab fa-bitcoin orange-text"></i> ({{ sat }}) por <b>{{fiatString}}</b>.
                </h6>
                <h6 v-if="!ad.xchange">
                    <b class = "orange-text">Margen del Vendedor:</b> {{ad.margen}}%
                </h6>
                <h6 v-else>
                    <b class = "orange-text">Tasa de Cambio:</b> {{ad.xchange}} {{ ad.divisa == "cubano" ? "CUP" : "MLC" }} por 1 USD
                </h6>
                <h6>
                    <b class = "orange-text">Provincia:</b> {{ad.place}}
                </h6>
                <h6>
                    <b class = "orange-text">Metodo de Pago:</b> {{payMethods}}
                </h6>
            </div>
            
            <div class = "col s12 marginTop">
                <LargeButton color="green" icon="fas fa-comment-dots" :text="counterPartyRole" position="left" @clicked="reputation"/>
                <LargeButton color="orange" icon="fas fa-shopping-cart" :text="action" position="right" @clicked="isEscrowPrompt= !isEscrowPropt"/>
                <LargeButton color="blue" icon="fas fa-graduation-cap" :text="tutoriales" position="left" @clicked="goTutorials"/>
            </div>

        </div>

    </div>

</template>

<script>

import LargeButton from '@/components/UI/Buttons/LargeButton'
import StartPrompt from '@/components/Escrows/start/StartPrompt'
import { ref } from '@vue/reactivity';
import { onUpdated } from '@vue/runtime-core';
import { conversionX } from '@/composables/ratesX'
import useBoilerplate from '@/composables/useBoilerplate';

export default {

    props: ['ad', 'isActive'],
    components: { LargeButton, StartPrompt },
    emits: ['close', 'success', 'reputation'],
    
    setup(props, context){

        const { router, error, isLoading } = useBoilerplate(false);

        const tutoriales = "Tutoriales";
        const isEscrowPrompt=ref(false);
        const isReputation=ref(false);
        const fiatString = ref('');
        const counterPartyRole = ref('');
        const action = ref('');
        const payMethods = ref('');
        const sat = ref(0);

        onUpdated(() => {
            
            const conversions = conversionX(props.ad.monto, props.ad.margen, JSON.parse(sessionStorage.xRates), props.ad.xchange);
            fiatString.value = conversions[props.ad.divisa];
            sat.value = conversions.BTC + " BTC";
            action.value = props.ad.adType == "Compro" ? "Vende" : "Compra";
            counterPartyRole.value = props.ad.adType == "Compro" ? "Comprador" : "Vendedor";
            payMethods.value = props.ad.payMethods.join();
            
        })

        const reputation = () => {
            isReputation.value=true;
            context.emit('reputation', props.ad.user);
        }

        const goTutorials = () => {
            let destination = router.resolve({name: 'Tutorials'});
            context.emit('close');
            window.open(destination.href, '_blank');
        }

        const closeModal = () => {
            context.emit('close');
            isEscrowPrompt.value = false;
        }

        return { 
            fiatString, 
            tutoriales, 
            counterPartyRole, 
            payMethods,
            isEscrowPrompt,
            action, 
            isLoading, 
            error, 
            closeModal, 
            reputation,
            goTutorials,
            sat
        };
    }
}
</script>