<template>
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
        <div class="row modal-content center white">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    
    props: ['isActive'],
    emits: ['close'],
    
    setup(props, context){

        const closeModal = () => {
            context.emit('close');
        }

        return { closeModal };
    }
}
</script>