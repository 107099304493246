<template>
    <li>
        <div class = "collapsible-header grey white-text"><i class="fab fa-whatsapp"></i><span id ="userProfile">Whatsapp</span></div>
        <div class = "collapsible-body center-align grey darken-2">
            <p class = "white-text left-align">Compartir el numero de Whatsapp con los demas usuarios no es obligatorio, pero ayuda a establecer relaciones de mayor credibilidad y confianza con la controparte en las compraventas.</p>
            <form @submit.prevent="saveWA">
                <div class="input-field">
                    <input type="text" placeholder = 'Numero Whatsapp' v-model="myWhatsapp">
                </div>
                <Error v-if="error!=null && isLoading==false" :warning="error" />
                <Loader v-if="isLoading && error==null" />
                <p>
                    <button class="btn orange white-text">
                        <i class='fa fa-check'></i>
                    </button>
                </p>
            </form>
        </div>
    </li>
</template>

<script>

import { ref } from '@vue/reactivity'
import { check_wa } from '@/composables/sanitize'
import { post } from '@/composables/httpCalls'
import Error from '@/components/UI/Error'
import Loader from '@/components/UI/Loader'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { Error, Loader },
    props: ['phone'],
    setup(props){

        const { store, router, isLoading, error } = useBoilerplate(false);

        const myWhatsapp = ref(props.phone);
        
        const saveWA = async () => {

            if(myWhatsapp.value != ''){
                if(check_wa(myWhatsapp.value) == false){
                    return error.value = "El Numero de Telefono No es Correcto";
                }
            }
            
            isLoading.value = true;
            error.value = null;

            try{
                const url = "/api/saveWA";
                const vars = { wa: myWhatsapp.value };
                const res = await post(url, vars);

                if(res.status == 200){
                    isLoading.value = false;
                    const html = "Whatsapp Actualizado";
                    return M.toast({html});
                }else if(res.status == 412){
                    store.state.logUserOut();
                    return router.push({name: "Auth"});
                }else if(401){
                    isLoading.value = false;
                    return error.value = "El Numero de Telefono No es Correcto";
                }else{
                    isLoading.value = false;
                    return error.value = "No Se Pudo Actualizar el Numero de Whatsapp";
                }

            }catch(err){
                console.log(err);
                error.value = "Error de Conexion"
                isLoading.value = false;
            }
        }

        return { myWhatsapp, saveWA, error, isLoading }
    }
}
</script>

<style>

</style>