<template>
  <div class = "container">
    
    <CallError v-if="!isLoading && error!=null" :warning="error"/>

    <LoadingFullPage v-if="isLoading && !error"/>

    <MSGsList 
      v-if="!isLoading && !error" 
      :counterparty="counterparty"
      :isSpy="isSpy" 
      :picture="picture" 
      :messages="messages" 
      :room="room"
    />

    <Loader v-if="store.state.isLoadingMore">
        <h5 class="orange-text left-align">Cargando...</h5>
    </Loader>

  </div>

  <teleport to="body">
    <ScamAlertModal :isActive="isActive" :id="room" @close="isActive=false" @accept="acceptScamRisk"/>
  </teleport>
</template>

<script>
import LoadingFullPage from '@/components/UI/LoadingFullPage'
import Loader from '@/components/UI/Loader'
import CallError from '@/components/UI/CallError'
import MSGsList from '@/components/Chats/MSGsList'
import ScamAlertModal from '@/components/modals/ScamAlertModal'
import { ref } from '@vue/reactivity'
import { onMounted, watchEffect } from '@vue/runtime-core';
import { post } from '@/composables/httpCalls';
import { formatPicUrl } from '@/composables/pictures'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
  
  props:['room'],
  components: { Loader, LoadingFullPage, MSGsList, CallError, ScamAlertModal },
  
  setup(props){
    
    const {store, router, error, isLoading, isActive} = useBoilerplate(true);
    
    const isSpy = ref(null);

    const messages = ref([]);
    const counterparty = ref(null);
    const picture = ref('https://qbita.org/assets/images/profiles/anonimous.png');

    const checkScamAlert = async (room) => {
      try {
        const url = '/api/scam-alert/'+room;
        const res = await fetch(url, {cache: "no-cache"});
        if(res.status == 404){
          isActive.value = true;
        }else if(res.status == 200){
          console.log("scam alert acknowledged");
        }else{
          console.log("Server Error: ", res.status);
        }
      } catch (err) {
        console.log(err)
      }  
    }

    const acceptScamRisk = async () => {
      
      try{
        isActive.value = false;
        const url = "/api/acknowledge-scam-risk";
        const vars = {room: props.room};
        await post(url, vars);
      }catch(err){
        return error.value = "No Se Pudo Cancelar el Anuncio";
      }

    }

    //Update ads list every time ads  changes in globalstate
    watchEffect(() => {
      messages.value = store.state.messages;
      store.state.setInfinity(messages.value.length);
    })
    
    onMounted(async () => {
      
      M.AutoInit();
      store.state.reset('messages');
      store.state.setRoom(props.room);

      try{
        
        const url = '/api/getFirstmessages/'+props.room;
        const res = await fetch(url, {cache: "no-cache"});
      
        //Handle Bad Response
        if(res.status == 300){
            return router.push({name: 'Welcome'});
        }else if(res.status == 401){
            return router.push({name: 'TwoFA'});
        }else if(res.status == 403){
            return router.push({name: 'Blocked'});
        }else if(res.status == 412){
            store.state.logUserOut();
            return router.push({name: 'Auth'});
        }else if(res.status == 200){ //Handles Successfull Response

            const data = await res.json();
            
            //Update Ads List
            store.state.loadFirst(data.messages);
            counterparty.value = data.counterparty;
            isSpy.value = data.isSpy;
            picture.value = formatPicUrl(data.counterpartyPicture);
            store.state.isBlocked = data.isBlocked && data.isBlocked == true ? true : false;
            store.state.blocker = data.blocker && data.blocker.length > 2 ? data.blocker : '';
            isLoading.value = false;
            return checkScamAlert(props.room);

        }else{
            isLoading.value = false;
            return error.value = "Error De Sistema"
        }

      }catch(err){
        console.log(err);
        isLoading.value = false;
        return error.value = "Problema de Conexion"
      }

    })
    
    return { picture, isSpy, messages, counterparty, store, isLoading, isActive, error, acceptScamRisk };
  }

}
</script>