<template>
    
    <div class="col s2 left-align">
        
        <h6 
            v-if="store.state.isBlocked==false" 
            @click="isActive=true"
        >
            <i class="fas fa-ban white-text"></i>
        </h6>
        
        <h6 
            v-if="store.state.isBlocked==true && store.state.alias == store.state.blocker"
            @click="isActive=true"
        >
            <i class="fas fa-check white-text"></i>
        </h6>

        <h6 
            v-if="store.state.isBlocked==true && store.state.alias != store.state.blocker"
        >
            <i class="fas fa-minus-circle white-text"></i>
        </h6>

    </div>

    <teleport to="body">
        <BlockModal 
            :isActive="isActive" 
            :counterparty="counterparty" 
            @close="isActive=false" 
        />
    </teleport>

</template>

<script>
import BlockModal from '@/components/modals/BlockModal'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { BlockModal },
    props: ['counterparty'],
    setup(){
        const { store, isActive } = useBoilerplate(false);
        return { store, isActive }
    }
}
</script>

<style scoped>
    h6{
        margin-top: 20px;
        color: white;
        font-weight: bold;
    }
</style>