<template>
  <div class = "container">
    
    <CallError v-if="!isLoading && error!=null" :warning="error"/>

    <LoadingFullPage v-if="isLoading && !error"/>

    <RoomsList v-if="!isLoading && !error" :rooms="rooms" />

    <Loader v-if="store.state.isLoadingMore">
        <h5 class="orange-text left-align">Cargando...</h5>
    </Loader>

  </div>
</template>

<script>
import LoadingFullPage from '@/components/UI/LoadingFullPage'
import Loader from '@/components/UI/Loader'
import CallError from '@/components/UI/CallError'
import RoomsList from '@/components/Chats/RoomsList'
import { ref } from '@vue/reactivity'
import { onMounted, watchEffect } from '@vue/runtime-core';
import useBoilerplate from '@/composables/useBoilerplate'

export default {
  
  components: { Loader, LoadingFullPage, RoomsList, CallError },
  
  setup(){

    const { error, isLoading, router, store } = useBoilerplate(true);
    
    const rooms = ref([]);

    //Update ads list every time ads  changes in globalstate
    watchEffect(() => {
      rooms.value = store.state.rooms;
      store.state.setInfinity(rooms.value.length);
    })
    
    onMounted(async () => {
      
      M.AutoInit();
      store.state.reset('rooms');
      try{
  
        const url = "/api/getFirstRooms";
        const res = await fetch(url, {cache: "no-cache"});
      
        //Handle Bad Response
        if(res.status == 300){
            return router.push({name: 'Welcome'});
        }else if(res.status == 401){
            return router.push({name: 'TwoFA'});
        }else if(res.status == 403){
            return router.push({name: 'Blocked'});
        }else if(res.status == 412){
            store.state.logUserOut();
            return router.push({name: 'Auth'});
        }else if(res.status == 200){ //Handles Successfull Response

            const data = await res.json();
            //Update Ads List
            store.state.loadFirst(data.rooms);

            return isLoading.value = false;

        }else{
            isLoading.value = false;
            return error.value = "Error De Sistema"
        }

      }catch(err){
        console.log(err);
        isLoading.value = false;
        return error.value = "Problema de Conexion"
      }

    })
    
    return { rooms, store, isLoading, error };
  }

}
</script>