<template>
  
  <NewEscrowSuccess v-if="newEscrowId!=null" :id="newEscrowId"/>

  <div class="row modal-content center grey darken-3" v-else>
    
    <PromptSeller 
      v-if="ad.adType=='Compro'" 
      :cards="cards" 
      :fiatString="fiatString"
      @cardSelected="setCard"
    />

    <PromptBuyer v-else :fiatString="fiatString" :sat="sat" />
    
    <ConfirmContact :text="contactText" @checked="handleCheck"/>
    
    <div class="col s12">
      <Error :warning="error" v-if="isLoading==false && error!=null"/>
    </div>
    
    <Loader v-if="isLoading" />
    <LargeButton :color="color" icon="" text="Adelante" position="none" @clicked="escrowStart"/>

  </div>

</template>

<script>

import NewEscrowSuccess from '@/components/Escrows/start/NewEscrowSuccess'
import PromptBuyer from '@/components/Escrows/start/PromptBuyer';
import PromptSeller from '@/components/Escrows/start/PromptSeller';
import ConfirmContact from '@/components/Escrows/start/ConfirmContact';
import LargeButton from '@/components/UI/Buttons/LargeButton'
import Loader from '@/components/UI/Loader'
import Error from '@/components/UI/Error'
import { post } from '@/composables/httpCalls'
import { conversionX } from '@/composables/ratesX'
import { getQBitaFee, getQBitaFeeVip, buyerSatsVSvip, isSpecialFee } from '@/composables/fee'
import { computed, onMounted, ref } from '@vue/runtime-core';
import { socket } from '@/composables/socket'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
  
  components: { PromptBuyer, PromptSeller, ConfirmContact, LargeButton, NewEscrowSuccess, Loader, Error },
  props: ['fiatString', 'sat', 'ad'],

  setup(props){

    const {store, router, isLoading, error} = useBoilerplate(false);

    const newEscrowId = ref(null);
    const isChecked = ref(false);
    const color = ref('disabled');
    let selectedCard = '';
    const cards = ref([
        {_id: '', cardNumber: "Tarjeta Para Recibir el Pago"},
        {_id: 'new', cardNumber: "Agregar Nueva Tarjeta"}
    ]); 

    const setCard = value => {
      console.log("User picked card: ", value);
      if(value == 'new'){
        return router.push({name: "NewCard"});
      }
      selectedCard = value;
      console.log("As a matter of fact, selectedCard is: ", selectedCard);
    }

    const btc4Buyer = computed(() => {
      const split = props.sat.split(' ');
      return Number(split[0]);
    })

    const contactText = computed(() => {
      return props.ad.adType == "Compro" ? 'Ya contacté el comprador' : 'Ya contacté el vendedor'; 
    })
    
    const handleCheck = () => {
      isChecked.value = !isChecked.value;
      if(isChecked.value == false){
        color.value = 'disabled';
      }else{
        color.value = 'green';
      }
    }

    const escrowStart = async () => {
      
      if(isChecked.value == false){
        return;
      }

      if(selectedCard == '' && props.ad.adType == "Compro"){
        return error.value = "Es Necesario Seleccionar una Tarjeta Para Recibir el Pago";
      }

      error.value = null;
      isLoading.value = true;
      const url = '/api/newEscrow';
      
      try {

        const conversions = conversionX(props.ad.monto, props.ad.margen, JSON.parse(sessionStorage.xRates));
        
        //Determin who is going to pay qbita fee
        const isSpecialCommissionScheme = isSpecialFee(store.state.alias, props.ad); 
        const qbitaFee = isSpecialCommissionScheme == true ? getQBitaFeeVip(props.ad.monto, conversions): getQBitaFee(props.ad.monto, conversions);
        const btc4Seller = btc4Buyer.value + Number(qbitaFee);

        const vars = {
            id: props.ad._id,
            cardId: props.ad.adType == "Compro" ? selectedCard : props.ad.cardId,
            btc4Buyer: isSpecialCommissionScheme == true ? buyerSatsVSvip(btc4Buyer.value, Number(qbitaFee)) : Number(btc4Buyer.value.toFixed(8)),
            btc4Seller: isSpecialCommissionScheme == true ? Number(btc4Buyer.value.toFixed(8)) : Number(btc4Seller.toFixed(8)),
            type: props.ad.adType == "Compro" ? "sell" : "buy",
            qbitaFee: Number(qbitaFee.toFixed(8)),
            toBePaid: props.fiatString, 
            xPub: sessionStorage.getItem('xPub')
        }

        console.log("NEW ESCROW:");
        console.log(vars);
      
        const res = await post(url, vars);

        if(res.status == 500){
          isLoading.value = false;
          return error.value = "Error de Sistema";
        }else if(res.status == 401){
          isLoading.value = false;
          return error.value = "La compraventa no està autorizada";
        }else if(res.status == 412){
          store.state.logUserOut();
          return router.push({name: 'Auth'});
        }else if(res.status==200){
          const data = await res.json();
          isLoading.value = false;
          newEscrowId.value = data.escrow._id;
          socket.emit('new escrow stage', data.escrow);
        }else{
          isLoading.value = false;
          return error.value = "No Se Pudo Crear la Compraventa";
        }
      } catch (err) {
        console.log(err);
        isLoading.value = false;
        error.value = "Error de Conexion";
      }
    
    }

    onMounted(async () => {
      try {
        const url = "/api/listCards";
        const res = await fetch(url, {cache: "no-cache"});
        if(res.status == 200){
          const data = await res.json();
          const relevantCards = data.cards.filter(item => item.currency == props.fiatString.split(' ')[1]);
          cards.value = [...cards.value, ...relevantCards];
          console.log("CARDS FOUND: ", cards.value);
        }
      } catch (err) {
        console.log(err);
      }
    })

    return { 
      contactText, escrowStart, newEscrowId, isChecked, 
      color, isLoading, error, handleCheck, cards, setCard }
  }
}
</script>