<template>
    <div class="col s12 msgBox grey darken-4">
        <h5 class="orange-text center">
            <i class="fas fa-ban orange-text"></i> Chat Bloqueado
        </h5>
    </div>
</template>

<style scoped>
    .msgBox{
        margin: 0 auto !important;
        position: absolute;
        width: 100% !important;
        bottom: 0px !important;
        left: 0% !important;
        height: 15%;
        border-top: 5px solid orange;
    }
</style>