<template>
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
        <div class="row modal-content center white">
            <div class = "col s12 center-align orange-text">
                <h2>
                    <i class = "fas fa-user-secret red-text"></i>
                </h2>
                <h5 class="red-text">Cuidado con las Estafas</h5>
            </div>
            <div class = "col s12 center-align grey-text text-darken-4">
                <p>Transar fuera de la plataforma es peligroso!</p>
                <p>Si alguien le propone <b>hacer la compraventa por fuera</b>, probablemente es un <b>estafador</b></p>
            </div>

            <LargeButton color="red" icon="fa fa-check" text="Entiendo el Riesgo" position="left" @clicked="acceptScamRisk"/>

        </div>
    </div>
</template>

<script>
import LargeButton from '@/components/UI/Buttons/LargeButton';

export default {

    props: ['isActive'],
    components: { LargeButton },
    emits: ['close', 'accept'],
    
    setup(props, context){

        const closeModal = () => {
            context.emit('close');
        }

        const acceptScamRisk = async () => {
          context.emit('accept');
        }

        return { closeModal, acceptScamRisk };
    }
}
</script>