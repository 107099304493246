<template>
    <div class="col s12 center">
        <h6 class = "grey-text text-darken-4">Evaluacion Promedio: <b>{{cleanScore}}/5</b></h6>
        <Stars :score="score" :isBig="true"/>
        <h6 class = "grey-text text-darken-4">Compraventas Completadas con Exito: <b>{{ successRate }}</b></h6>

    </div>
    
</template>

<script>
import Stars from "@/components/Reputation/Stars"
import { computed } from '@vue/runtime-core'
export default {
    props: ['score', 'successRate', 'reviews'],
    components: { Stars },
    setup(props){
        const cleanScore = computed(() => isNaN(props.score) ? 0 : props.score);
        return { cleanScore }
    }
}
</script>