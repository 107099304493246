<template>
  <p class = 'left-align grey-text text-darken-3'>
    Los <b class = 'orange-text'>Bitcoin</b> fueron enviados a la dirección Escrow multiSig. 
    <b> La transacción todavía no está confirmada en la blockchain. </b> 
    Espere que la transacción tenga 1 confirmación para seguir adelante con el proceso de compraventa.
  </p>
  <p class = "left-align" v-if="store.state.escrow.escrow.hash != ''">
    <a :href="url" target="_blank">
      Reastrear Bitcoin Enviados
    </a>
  </p>
</template>

<script>
import useBoilerplate from '@/composables/useBoilerplate'
import { computed } from '@vue/runtime-core';
export default {
  setup() {
    const { store } = useBoilerplate();
    const url = computed(() => `https://mempool.space/es/tx/${store.state.escrow.escrow.hash}`)
    return { url , store};
  },
}
</script>

<style scoped>
  a{font-weight: bold};
</style>