<template>
    <MenuButton isMob="true" target="Exchange" text="Anuncios" icon="fas fa-bullhorn"/>
    <MenuButton isMob="true" target="WalletBTC" text="Monedero" icon="fas fa-wallet" extraClass="wallet"/>
    <MenuButton isMob="true" target="AllRooms" text="Chat" icon="fas fa-comment-dots" extraClass="chats"/>
    <MenuButton isMob="true" target="AllEscrows" text="Compraventas" icon="fas fa-random" extraClass="escrows"/>
    <MenuButton isMob="true" target="Settings" text="Ajustes" icon="fas fa-cog"/>
    <MenuButton v-if="menu=='admin'" isMob="true" target="AdminPanel" text="Admin" icon="fas fa-users-cog"/>
    <MenuButton isMob="true" target="Stats" text="Datos" icon="far fa-chart-bar"/>
    <MenuButton isMob="true" target="ContactUs" text="Contactenos" icon="fas fa-envelope"/>
    <MenuButton isMob="true" target="Auth" text="Salir" icon="fas fa-sign-out-alt"/>
</template>

<script>

import MenuButton from '@/components/UI/Buttons/MenuButton';
import { socket } from '@/composables/socket';
import { onMounted } from '@vue/runtime-core';
import { isItBlinking } from '@/composables/blink';
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    props: ['menu'],
    components: { MenuButton },
    setup(){
        const {store} = useBoilerplate(false);

        socket.on('connect me', nicks => {
            store.state.updateConnectedUsers(nicks);
        })
        
        onMounted(async () => {
            setTimeout(()=> isItBlinking(), 1500);
        })
    }
}
</script>

<style>

</style>