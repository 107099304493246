import { reactive, ref } from '@vue/runtime-core'
import { post } from '@/composables/httpCalls';

const useNewAd = (error, isActive, isSuccess, isLoading, router, store, props, context) => {

    const provincia = ref(null);
    const payMethod = ref(null);
    const divisa = ref('cubano');
    const margen = ref(0);
    const amount = ref(null);
    const opType = ref('Vendo');
    const userInputs = reactive({});
    const selectedCard = ref('');

    const cards = ref([
        {_id: '', cardNumber: "Tarjeta Para Recibir el Pago"},
        {_id: 'new', cardNumber: "Agregar Nueva Tarjeta"}
    ]);

    

    const handleCard = value => {
        if(value == 'new'){
            return router.push({name: 'NewCard'});
        }
        selectedCard.value = value;
        console.log(selectedCard.value);
    }

    const handlePayment = value => payMethod.value = value;
    const handleProvince = value => provincia.value = value;

    

    const previewAd = () => {

        error.value = null;
  
        userInputs.selectedProvince = provincia.value;
        userInputs.selectedDivisa = divisa.value;
        userInputs.selectedMethod = payMethod.value;
        userInputs.amount = amount.value;
        userInputs.margen = 1;
        userInputs.xchange = margen.value;
        userInputs.type = opType.value;
        userInputs.card = selectedCard.value;
        
        if(userInputs.selectedProvince == ''){
          return error.value = "Es Necesario Seleccionar la Provincia";
        }
        if(userInputs.card == '' && userInputs.type == 'Vendo' && userInputs.selectedMethod == 'Trasferencia Bancaria - TransferMovil / EnZona'){
            return error.value = "Es Necesario Seleccionar una Tarjeta Para Recibir el Pago";
        }
        if(userInputs.selectedDivisa == ''){
          return error.value = "Es Necesario Seleccionar la Divisa";
        }
        if(userInputs.selectedMethod == ''){
          return error.value = "Es Necesario Seleccionar el Metodo de Pago";
        }
        if(userInputs.amount < 10 || userInputs.amount > 100000 || isNaN(userInputs.amount)){
          return error.value = "El Monto Debe Ser Mayor de 10 y Menor de 100000";
        }
        
        isActive.value = true;
        
    }

    const publishAd = async () => {

        try{
        
            error.value = false;
            isLoading.value = true;
            
            const xPub = sessionStorage.getItem('xPub');
            
            const url = "/api/publishNewAd";

            const vars = {
                adType: props.ad.type,
                cardId: props.ad.card,
                place: props.ad.selectedProvince,
                divisa: props.ad.selectedDivisa,
                monto: props.ad.amount,
                payMethods: props.ad.selectedMethod,
                xchange: props.ad.xchange,
                xPub: xPub
            }
            
            const res = await post(url, vars);

            if(res.status == 200){
                context.emit('success');
            }else if(res.status == 412){
                store.state.logUserOut();
                return router.push({name: 'Auth'});
            }else if(res.status == 404){
                isLoading.value = false;
                return error.value = `Seleccionar una tarjeta en ${props.ad.selectedDivisa == 'MLC' ? 'MLC' : 'CUP'}`
            }else{
                isLoading.value = false;
                return error.value = "No Se Pudo Publicar el Anuncio";
            }

        }catch(err){
            console.log(err);
            isLoading.value = false;
            error.value = "No Se Pudo Publicar el Anuncio";
        }

    }

    return {
        provincia, payMethod, divisa, margen, cards, selectedCard,
        amount, opType, userInputs, handleCard,
        previewAd, publishAd, handlePayment, handleProvince
    }

}

export default useNewAd;