import { reactive } from 'vue';
import { connectME, disconnectME } from '@/composables/socket';

const state = reactive({
    isLogged: false,
    isAdmin: false,
    alias: '',
    collection: null,
    ads: [],
    rooms: [],
    room: null,
    isBlocked: false,
    blocker: null,
    escrows: [],
    messages: [],
    counter: 1,
    infinity: 0,
    isLoadingMore: false,
    isLoadingMoreMessages: false,
    escrow: {},
    connectedUsers: {},
    cards: [],

    logUserIn(){
        this.isLogged = true;
    },
    logUserOut(){
        disconnectME(this.alias);
        this.isLogged = false;
        this.isAdmin = false;
        this.alias = '';
        fetch('/api/logout')
        sessionStorage.clear();
    },
    setAlias(alias){
        this.alias = alias;
        if(alias == 'QBita'){
            this.isAdmin = true;
        }
        sessionStorage.setItem('alias', alias);
        connectME(alias);
    },
    setCards(allCards){
        this.cards = allCards;
    },
    removeCard(id){
        this.cards = this.cards.filter(card => card._id != id);
    },
    removeAd(id){
        this.ads = this.ads.filter(ad => ad._id != id);
    },
    loadFirst(data){
        
        this[this.collection] = data;
    },
    setInfinity(n){
        this.infinity = n - 1;
    },
    async loadMore(){
        try {
            if(this.collection == "messages"){
                this.isLoadingMoreMessages = true;
            }else{
                this.isLoadingMore = true;
            }
            const url = this.collection == 'messages' ? `/api/getMore${this.collection}/${this.counter}/${this.room}` 
                                                    : `/api/getMore${this.collection}/${this.counter}`;
            const res = await fetch(url , {cache: "no-cache"});
            const data = await res.json();
            this.counter = this.counter + 1;
            this[this.collection] = [...this[this.collection], ...data[this.collection]];
            if(this.collection == "messages"){
                this.isLoadingMoreMessages = false;
            }else{
                this.isLoadingMore = false;
            }
        } catch (error) {
            console.log(error);
            throw new Error('Error de Conexion');
        }
    },
    reset(array){
        this[array] = [];
        this.collection = array;
        this.counter = 1;
        this.infinity = 0;
        this.isLoadingMore = false;
        this.isLoadingMoreMessages = false;
        this.room = null,
        this.isBlocked = false,
        this.blocker = null
    },
    resetEscrow(){
        this.escrow = {}
    },
    setEscrow(obj){
        this.escrow = obj;
    },
    escrowReplace(obj){
        this.escrow.escrow = obj;
    },
    updateEscrowStage(stage){
        this.escrow.escrow.stage = stage;
    },
    setRoom(room){
        this.room = room;
    },
    updateConnectedUsers(nicks){
        this.connectedUsers = nicks;
    },
    updateMSGlist(obj){
        if(this.messages.length > 0 && this.messages[0]._id && this.messages[0]._id == obj._id) return;
        this.messages.unshift(obj);
        const chatSpace = document.querySelector('.chatSpace');
        if(chatSpace){
            chatSpace.scroll(0, 0);
        }
    },
    updateRooms(obj){
        const rooms = this.rooms.filter(item => item._id != obj.id);
        this.rooms = rooms;
        this.rooms.unshift(obj);
    },
    updateEscrows(obj){
        const escrows = this.escrows.filter(item => item._id != obj._id);
        this.escrows = escrows;
        this.escrows.unshift(obj);
    }

});

export default { state }; 