<template>
    
  <section class="slider">
    <ul class="slides">
      
      <li>
        <img src="@/assets/images/home/bitcoin-cuba-oro.jpg" alt="Bitcoin en Cuba">
        <div class="caption left-align">
          <h1>Bitcoin en Cuba</h1>
            <h5 class="light grey-text text-lighten-3 hide-on-small-only">Nada de Bloqueo</h5>
          <a href="#" @click="isActive=true" class="btn btn-large orange learnMore">Descarga</a>
        </div>
      </li>

      <li>
        <img src="@/assets/images/home/inflation2.jpeg" alt="Bitcoin te protege de la inflacción en Cuba">
        <div class="caption center-align">
          <h1 class = 'white-text text-darken-2'>Zero Inflacción</h1>
          <h5 class="light white-text text-darken-2 hide-on-small-only">Cuida Tus Ahorros</h5>
          <a href="#" @click="isActive=true" class="btn btn-large orange learnMore">Descarga</a>
        </div>
      </li>

      <li>
        <img src="@/assets/images/home/bitcoin-seguro.jpeg" alt="Bitcoin Seguro en Cuba">
        <div class="caption right-align">
          <h1 class = 'white-text'>Seguridad Total</h1>
          <h5 class="light grey-text text-lighten-3 hide-on-small-only">con tecnologia MultiFirma</h5>
          <a href="#" @click="isActive=true" class="btn btn-large orange learnMore">Descarga</a>
        </div>
      </li>

    </ul>
  </section>

  <teleport to="body">
    <EmptyModal :isActive="isActive" @close="isActive=false">
      <Downloads />    
    </EmptyModal>
  </teleport>

</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import EmptyModal from '@/components/modals/EmptyModal'
import Downloads from '@/components/home/Downloads'

export default {
  
  components: { EmptyModal, Downloads },

  setup(){

    const isActive = ref(false);

    onMounted(() => {
      const slider = document.querySelector('.slider');
      M.Slider.init(slider, {
        indicators: false,
        height: 500,
        transition: 500,
        interval: 6000
      });
    });

    return { isActive }
  }
}
</script>