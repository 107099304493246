<template>
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
        <div class="row modal-content center white">
            <div class = "col s12 center-align orange-text">
                <h2>
                    <i class = "fas fa-exclamation-circle red-text"></i>
                </h2>
                <h5 class = "red-text">STOP</h5>
            </div>
            <div class = "col s12 left-align grey-text text-darken-4">
                <p>La interrupcion de la compraventa va a reflejarse en la reputacion de su perfil.</p>
                <p><b>Seguro que quiere parar la compraventa?</b></p>
            </div>
            <div class = "col s12 center-align grey-text text-darken-4 margin">
                
                <Loader v-if="isLoading"/>
                <Error :warning="error" v-if="!isLoading && error!=null"/>
              
            </div> 
            
            <LargeButton 
              color="red" 
              icon="fa fa-trash" 
              text="Cancela" 
              position="left" 
              @clicked="stopTX"
            />

        </div>
    </div>
</template>

<script>
import LargeButton from '@/components/UI/Buttons/LargeButton';
import Loader from '@/components/UI/Loader';
import Error from '@/components/UI/Error';
import { post } from '@/composables/httpCalls';
import { socket } from '@/composables/socket';
import useBoilerplate from '@/composables/useBoilerplate';

export default {

    props: ['isActive'],
    components: { Loader, Error, LargeButton },
    emits: ['close'],
    
    setup(props, context){

        const { store, error, isLoading } = useBoilerplate(false);

        const closeModal = () => {
            context.emit('close');
        }

        const stopTX = async () => {

          error.value = null;
          isLoading.value = true;

          try{
        
            const url = "/api/stopTX";
            const vars = {id: store.state.escrow.escrow._id};
            const res = await post(url, vars);
            
            if(res.status == 409){
              isLoading.value = false;
              return error.value = "Usted No Esta Autorizado a Cancelar la Compraventa";
            }else if(res.status == 200){
              isLoading.value = false;
              const data = await res.json();
              store.state.escrowReplace(data);
              M.toast({html: "Compraventa Cancelada"});
              socket.emit('new escrow stage', data);
              return context.emit('close');
            }else{
              isLoading.value = false;
              return error.value = "No Se Pudo Cancelar el Anuncio";
            }

          }catch(err){
            console.log(err);
            isLoading.value = false;
            return error.value = "No Se Pudo Cancelar el Anuncio";
          }
        }

        return { isLoading, error, closeModal, stopTX };
    }
}
</script>