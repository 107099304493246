<template>
  
    <div class="container" v-if="isSuccess" >
        <div class="row">
            <Success title="Tarjeta Agregada" @goBack="router.push({name: 'Settings'})">
                <CardSuccess />
            </Success>
        </div>
    </div>
  
    <div v-else class = "container">
        <div class="row grey darken-1 marginTop">

            <div class = "col s12 orange">
            <h5 class = "white-text center-align">Agregar Nueva Tarjeta</h5>
            </div>

            <LateralNav />

            <div class="col s12 l8 left-align grey darken-2 new-card-form-box">
                
                <form @submit.prevent="addCard">
                    <br>
                    <div class = "col s12 input-field">
                        <input type = 'text' v-model='cardNumber' required>
                        <label for="cardNumber"># de la Tarjeta</label>
                    </div>

                    <div class = "col s12 input-field">
                        <input type = 'text' v-model='phone'>
                        <label for="phone"># para SMS de confirmación</label>
                    </div>

                    <div class="input-field col s12">
                        <Select @changed="handleDivisa">
                            <option value="">Divisa de la Tarjeta</option>
                            <option value="CUP">CUP</option>
                            <option value="MLC">MLC</option>
                        </Select>
                    </div>

                    <div class = "col s12 center-align">
                        <Error :warning="error" v-if="error" />
                        <Loader v-if="isLoading" />
                    </div>

                    <div class = "col s12 center-align">
                        <button class = "btn orange white-text green-text margin">
                            <i class = 'fas fa-check'></i>
                        </button>
                    </div>

                </form>
            </div>

            <FloatingButton 
                icon="fas fa-arrow-left"
                @click="router.push({name: 'Settings'})"
            />
        </div>
    </div>

</template>

<script>

import Loader from '@/components/UI/Loader';
import Select from '@/components/UI/Select';
import Error from '@/components/UI/Error';
import CardSuccess from '@/components/Cards/CardSuccess';
import Success from '@/components/UI/Success';
import FloatingButton from '@/components/UI/Buttons/FloatingButton'
import LateralNav from '@/components/nav/LateralNav'
import useBoilerplate from '@/composables/useBoilerplate';
import useNewCard from '@/composables/useNewCard'

export default {
  
  components: { Select, FloatingButton, Error, Loader, Success, CardSuccess, LateralNav },

  setup(){

    const { error, isLoading ,isSuccess, router } = useBoilerplate(false); 
    
    const { phone, cardNumber, addCard, handleDivisa } = useNewCard(error, isLoading, isSuccess, router);

    return { router, addCard, error, isLoading, isSuccess, handleDivisa, cardNumber, phone }
  
  }

}

</script>

<style scoped>
  .text-darken-3{
    font-weight: bold;
  }
  .new-card-form-box{
      min-height: 600px !important;
  }
</style>