<template>
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
        <div class="row modal-content center white">
            <div class = "col s12 center-align orange white-text">
                <h5>Anteprima Anuncio</h5>
            </div>
            <div class = "col s12 left-align grey-text text-darken-4 marginTop">
                <h6><b class = "orange-text">{{ad.type}}</b> {{ad.amount}} $ en <i class="fab fa-bitcoin orange-text"></i> por {{ fiatString }}</h6>
                <h6><b class = "orange-text">Metodo de Pago:</b> {{ad.selectedMethod}}</h6>
                <h6><b class = "orange-text">Tasa de Cambio:</b> {{ad.xchange}} {{ ad.selectedDivisa == "MLC" ? "MLC" : "CUP"}} por 1 USD</h6>
                <h6><b class = "orange-text">Provincia:</b> {{ad.selectedProvince}}</h6>
            </div>
            <div class = "col s12 center-align grey-text text-darken-4" style ="margin-bottom: 20px">
                
                <Loader v-if="isLoading"/>
                <Error :warning="error" v-if="!isLoading && error!=null"/>                

            </div> 

            <LargeButton color="green" icon="fas fa-bullhorn" text="Publicar" position="left" @clicked="publishAd"/>

        </div>
    </div>
</template>

<script>

import LargeButton from '@/components/UI/Buttons/LargeButton';
import Loader from '@/components/UI/Loader';
import Error from '@/components/UI/Error';
import { computed } from '@vue/runtime-core';
import { conversionX } from '@/composables/ratesX'
import useBoilerplate from '@/composables/useBoilerplate';
import useNewAd from '@/composables/useNewAd'

export default {

    props: ['ad', 'isActive'],
    components: { Loader, Error, LargeButton },
    emits: ['close', 'success'],
    
    setup(props, context){

        const { error, isActive, isSuccess, isLoading, router, store } = useBoilerplate(false);
        const { publishAd } = useNewAd(error, isActive, isSuccess, isLoading, router, store, props, context);

        const fiatString = computed(() => {
            const conversions = conversionX(props.ad.amount, props.ad.margen, JSON.parse(sessionStorage.xRates), props.ad.xchange);
            return conversions[props.ad.selectedDivisa];
        });

        const closeModal = () => {
            context.emit('close');
        }

        return { fiatString, isLoading, error, closeModal, publishAd };
    }
}
</script>