<template>
  <div class = "container">
    <CallError v-if="!isLoading && error!=null" :warning="error"/>
    <LoadingFullPage v-if="isLoading && !error"/>
    <EscrowParentComponent 
      v-if="!isLoading && !error" 
      :id="id" 
      :isSpy="isSpy" 
      :chat="chat"
    />
  </div>
</template>

<script>
import LoadingFullPage from '@/components/UI/LoadingFullPage'
import CallError from '@/components/UI/CallError'
import EscrowParentComponent from '@/components/Escrows/transaction/EscrowParentComponent'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { isItBlinking } from '@/composables/blink'
import useBoilerplate from '@/composables/useBoilerplate';
import { socket } from '@/composables/socket'
export default {
  
  props: ['id'],
  components: { LoadingFullPage, CallError, EscrowParentComponent },
  
  setup(props, context){
    
    const {store, router, isLoading, error} = useBoilerplate(true);

    const escrowData = ref({});
    const isSpy = ref(false);
    const chat = ref(null);

    socket.on('new escrow stage', data => {
      
      const canIchange = data._id===props.id && store.state.escrow.escrow._id === data._id;
      if(canIchange===true){
          store.state.escrowReplace(data);
      }else{
        console.log("ID doesn 't match");
        return;
      }
    })

    onMounted(async() => {

      M.AutoInit();
      store.state.resetEscrow();
      
      try{
        
        const url = "/api/getEscrow/"+props.id;
        const res = await fetch(url, {cache: "no-cache"});
      
        //Handle Bad Response
        if(res.status == 300){
            return router.push({name: 'Welcome'});
        }else if(res.status == 401){
            return router.push({name: 'TwoFA'});
        }else if(res.status == 403){
            return router.push({name: 'Blocked'});
        }else if(res.status == 412){
            store.state.logUserOut();
            return router.push({name: 'Auth'});
        }else if(res.status == 200){ //Handles Successfull Response
            const escrowData = await res.json();
            store.state.setEscrow(escrowData);
            isSpy.value = escrowData.isSpy;
            chat.value = escrowData.chat;
            isLoading.value = false;
            return isItBlinking();
        }else{
            isLoading.value = false;
            return error.value = "Error De Sistema"
        }

      }catch(err){
        console.log(err);
        isLoading.value = false;
        return error.value = "Problema de Conexion"
      }

    })

    return { isLoading, error, escrowData, isSpy, chat}
  }
}
</script>