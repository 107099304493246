<template>
    
    <p class = "left-align grey-text text-darken-3">
        No se puede abrir una disputa en esta etapa de la compraventa. Sin embargo,
        Usted puede tambien interrumpir la compraventa haciendo click en el boton rojo.
    </p>
    <br>
    <p class = "center">
        <LargeButton 
            color="red"
            icon="fas fa-trash"
            position="left"
            text="Stop"
            @clicked="isActive=!isActive"
        />
    </p>

    <teleport to="body">
        <StopTXmodal :isActive="isActive" @close="isActive=!isActive"/>
    </teleport>
</template>

<script>
import StopTXmodal from '@/components/modals/StopTXmodal'
import LargeButton from '@/components/UI/Buttons/LargeButton'
import { ref } from '@vue/reactivity'
export default {
    components: { StopTXmodal, LargeButton },
    setup(){
        const isActive = ref(false);
        return { isActive }
    }
}
</script>

<style>

</style>