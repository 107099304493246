<template>
    <div class="row">
        <div class="col s10 offset-s1">
            <h4 class = 'orange-text'>
                Ultimas Compraventas
            </h4>
        </div>
        <Loader v-if="txs.length < 1" />
        <div class="col s10 offset-s1" v-else>
            <div v-for="tx in txs" :key="tx.id" >
                <SingleTX :tx="tx" />
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/UI/Loader'
import SingleTX from './SingleTX.vue'
import { onMounted, ref } from '@vue/runtime-core';
export default {
    components: { Loader, SingleTX },
    setup(){
        const txs = ref([]);
        onMounted(async() => {
            const url = '/api/public/getLastTXS';
            const res = await fetch(url, {cache: "no-cache"});
            txs.value = await res.json();
            console.log("TXS: ", txs.value)
        })
        return { txs }
    }
}
</script>

<style>

</style>