<template>

  <p class = 'left-align red-text'>
    <i class='fas fa-exclamation-circle icon20'></i> Disputa Abierta
  </p>
  
  <p class = 'left-align grey-text text-darken-4'>
    Coopere con QBita enviando de forma tempestiva todas las informaciones y las evidencias que se le pidan.
  </p>

  <p class = 'left-align grey-text text-darken-4'>
    <b class = "red-text">IMPORTANTE: </b>  
    No enviar evidencias dentro de los términos requeridos, puede resultar en una resolución de la disputa favorable a la contraparte.
  </p>
  
</template>