<template>
  <p class = "error center-align red white-text">{{ warning }}</p>
</template>

<script>
export default {
    props:['warning']
}
</script>

<style scoped>
    .error{
        padding: 10px;
    }
</style>