<template>
    <div class="row grey darken-1 marginTop">

        <ChatTop 
            :picture="picture"
            :counterparty="counterparty"
            :room="room"
            v-if="isSpy==false"
        />

        <LateralNav />

        <div class="col s12 l8 white chatWindow">
            
            <div class="chatSpace">

                <ZeroResults v-if="messages.length < 1" text="Mensajes"/>

                <div v-for="(msg, index) in messages" :key="msg._id" >
                    <SingleMSG :msg="msg" :index="index" :room="room" />
                </div>

                <Loader v-if="store.state.isLoadingMoreMessages"/>

            </div>

            <div v-if="store.state.isBlocked == false && isSpy == false">
                <NewMsgForm 
                    :room="room" 
                    :counterparty="counterparty"
                    v-if="isPicture==false"
                    @switch="isPicture=true"
                />

                <NewPictureForm 
                    :room="room" 
                    :counterparty="counterparty"
                    v-if="isPicture==true"
                    @switch="isPicture=false"
                />
            </div>

            <BlockedChat v-if="store.state.isBlocked == true && isSpy == false"/>

            <FloatingButton 
                icon="fas fa-arrow-circle-left" 
                @click="goBack"
                v-if="isSpy" 
            />

        </div>
    </div>

    
</template>

<script>

import SingleMSG from '@/components/Chats/SingleMSG'
import { onMounted, ref } from '@vue/runtime-core';
import ZeroResults from '@/components/UI/ZeroResults'
import FloatingButton from '@/components/UI/Buttons/FloatingButton'
import { isVisible } from '@/composables/infinity';
import LateralNav  from '@/components/nav/LateralNav';
import NewMsgForm from './NewMsgForm.vue'
import ChatTop from './ChatTop.vue'
import BlockedChat from './BlockedChat'
import NewPictureForm from './NewPictureForm.vue'
import Loader from '@/components/UI/Loader.vue'
import { socket } from '@/composables/socket'
import useBoilerplate from '@/composables/useBoilerplate';
import bipopen from '@/assets/sounds/bipopen.mp3'


export default {
    props: ["messages", "counterparty", "picture", "room", "isSpy"],
    components: { Loader, ZeroResults, NewMsgForm, SingleMSG, LateralNav, ChatTop, FloatingButton, NewPictureForm, BlockedChat },
    
    setup(props){

        const { store, router } = useBoilerplate(false);

        const isPicture = ref(false);
        
        const infinityScrolling = async () => {
            const infinityTrigger = document.querySelector('.infinity');
            if(infinityTrigger){
                if(isVisible(infinityTrigger) == true ){
                    infinityTrigger.classList.remove('infinity');
                    await store.state.loadMore();
                }
            }
        }

        const goBack = () => router.go(-1);

        onMounted(() => {
            const chatSpace = document.querySelector('.chatSpace');
            chatSpace.addEventListener('scroll', () => {
                infinityScrolling();
            });
        })

        socket.on('new message', data => {
            const canIchange = data.roomId===props.room && data.roomId===store.state.room;
            console.log("Can I Change? ", canIchange);
            if(canIchange===true){
                store.state.updateMSGlist(data);
                const audio = new Audio(bipopen);
                audio.play();
            }
        })

        return { store, infinityScrolling, isPicture, goBack };
    }
}

</script>

<style scoped>

    .chatWindow{
        position: relative;
        height: 80vh;
        overflow-x: hidden;
         overflow-y: hidden;
        max-height: 510px;
    }

    i{
        font-size: 18px;
    }

    .chatSpace{
        margin-top: 10px;
        max-height: 360px !important;
        overflow-y: scroll;
    }

    @media screen and (max-height: 640px) {
        .chatSpace{
            margin-top: 10px;
            max-height: 290px !important;
            overflow-y: scroll;
        }
        .chatWindow{
            position: relative;
            height: 73vh;
            overflow-x: hidden;
            overflow-y: hidden;
            max-height: 480px;
        }
    }

</style>