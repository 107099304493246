<template>
    <div class="row grey darken-1 marginTop">

        <div class="col s12 center-align orange">
            <h5 class="white-text">{{ id }}</h5>
        </div>
        
        <LateralNav />

        <div class="col s12 l8 white">

            <ul class = "collapsible">

                <Collapsible 
                    title="Proximo Paso" 
                    :isActive="true"
                    icon="fas fa-shoe-prints"
                    color="green-text"
                    v-if="isSpy==false"
                >
                    <NextStep />
                </Collapsible>
                
                <Collapsible 
                    title="Detalles" 
                    :isActive="false"
                    icon="fas fa-info-circle"
                    color="blue-text"
                >   
                    <AdminDetails v-if="isSpy==true" :chat="chat"/>
                    <Details v-else />
                </Collapsible>

                <Collapsible 
                    title="Modifica Estado" 
                    :isActive="false"
                    icon="fas fa-exchange-alt"
                    color="red-text"
                    v-if="isSpy==true"
                >
                    <ChangeState />
                </Collapsible>
                
                <Collapsible 
                    title="Disputa" 
                    :isActive="false"
                    icon="fas fa-fist-raised"
                    color="red-text"
                    v-if="isSpy==false"
                >
                    <Dispute />
                </Collapsible>

                <Collapsible 
                    title="Eventos" 
                    :isActive="false"
                    icon="fas fa-list-alt"
                    color="brown-text"
                    @clicked="getEvents"
                >
                    <Events :events="events" />
                </Collapsible>
                
            </ul>
        </div>

    </div>

    <FloatingButton 
        icon="fas fa-arrow-circle-left" 
        @click="goBack"
        v-if="isSpy" 
    />
</template>

<script>

import Collapsible from './Collapsible.vue'
import NextStep from './NextStep.vue'
import Details from './Details.vue'
import AdminDetails from './AdminDetails.vue'
import Dispute from './Dispute.vue'
import ChangeState from './ChangeState.vue'
import FloatingButton from '@/components/UI/Buttons/FloatingButton';
import Events from './Events.vue'
import LateralNav from '@/components/nav/LateralNav'
import useBoilerplate from '@/composables/useBoilerplate'
import { onMounted, ref } from '@vue/runtime-core'

export default {

    props: ['id', 'isSpy', 'chat'],
    components: { Collapsible, NextStep, ChangeState, Details, Dispute, Events, LateralNav, AdminDetails, FloatingButton },
    
    setup(props){

        const { router, store } = useBoilerplate();
        const events = ref([]);

        const getEvents = async () => {
            events.value = [];
            try {
                const url = '/api/events/'+props.id;
                const res = await fetch(url, {cache: "no-cache"});
                events.value = await res.json();
            } catch (error) {
                console.log(error);
            }
        }
        const goBack = () => router.go(-1);
        onMounted(() => {
            M.AutoInit();
            console.log("CARD: ", store.state.escrow.card);
        });

        return { events, getEvents, goBack, store }
    }
}
</script>

<style>

</style>