<template>
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
        <div class="row modal-content center grey darken-3">
            <div class="col s12 center-align orange">
                <h5 class="white-text">
                    Filtra Anuncios
                </h5>
            </div>

            <div class="col s12 left-align options">
                <form>
                    <h6 class="white-text left-align">Quiero Ver:</h6>
                    <div class="col s12 input-type left-align">

                        <p>
                            <label>
                            <input 
                                v-model="adType" 
                                type="radio" 
                                value = "Vendo" 
                            />
                            <span class="white-text">Solo Anuncios de Venta</span>
                            </label>
                        </p>

                        <p>
                            <label>
                            <input 
                                v-model="adType" 
                                type="radio" 
                                value = "Compro" 
                            />
                            <span class="white-text">Solo Anuncios de Compra</span>
                            </label>
                        </p>

                    </div>
                </form>
            </div>
            
            <LargeButton color="green" icon="fa fa-filter" text="Filtrar" position="left" @clicked="applyFilter"/>

        </div>
    </div>
</template>

<script>
import LargeButton from '@/components/UI/Buttons/LargeButton';
import { ref } from '@vue/reactivity';

export default {

    props: ['isActive'],
    components: { LargeButton },
    emits: ['close', 'filterChosen'],
    
    setup(props, context){

        const adType = ref(null);

        const closeModal = () => {
            context.emit('close');
        }

        const applyFilter = () => {
            context.emit('filterChosen', adType.value);
        }
        
        return { closeModal, applyFilter, adType };
    }
}
</script>

<style scoped>
    .options{
        margin-bottom: 20px;
    }
    h6{
        font-weight: bold;
    }
</style>