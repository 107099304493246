import { post } from "./httpCalls";

export const postBuyerSignature = async (psbt, id) => {
    const url = "/api/buyerSigns";
    const vars = { psbt, id };
    
    try {
        const res = await post(url, vars);
        
        if(res.status == 200){
            const myResponse = await res.json();
            return { status: true, error: null, escrow: myResponse.escrow}
        }else{
            return { status: false, error: "No Se Pudo Firmar la Transacción"}
        }
    } catch (error) {
        console.log(error);
        return { status: false, error: "Error de Conexión"}
    }
}