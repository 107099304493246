<template>
    <li>
        <div class = "collapsible-header grey white-text"><i class="fas fa-lock "></i><span id ="localizationChangePW"> Cambia Contraseña</span></div>
        <div class = "collapsible-body grey darken-2">
        <form class = 'changePWform' @submit.prevent="changePW">
            <div class = "input-field">
            <!--<i class = 'fas fa-key grey-text prefix' style = 'font-size: 20px'></i>-->
            <input type = "password" v-model="oldPw" >
            <label for = "oldPw" class='grey-text'><i class="fas fa-key"></i> <span id='oldPwLabel'>Contraseña</span></label>
            </div>
            <div class = "input-field">
            <!--<i class = 'fas fa-key grey-text prefix' style = 'font-size: 20px'></i>-->
            <input type = "password" v-model="newPw" >
            <label for = "newPw" class='grey-text'><i class="fas fa-key"></i> <span id='newPwLabel'>Nueva Contraseña</span></label>
            </div>
            <div class = "input-field">
            <!--<i class = 'fas fa-key grey-text prefix' style = 'font-size: 20px'></i>-->
            <input type = "password" v-model="newPwRep" >
            <label for = "newPwRep" class='grey-text'><i class="fas fa-key"></i> <span id='repNewPwLabel'>Repita Contraseña</span></label>
            </div>
            <br>
            <Loader v-if="isLoading"/>
            <Error v-if="error && isLoading==false" :warning="error"/>
            <p class = "center">
                <button class = "btn orange white-text margin">
                    <i class="fas fa-check"></i>
                </button>
            </p>
        </form>
        </div>
    </li>
</template>

<script>
import Loader from '@/components/UI/Loader'
import Error from '@/components/UI/Error'
import { ref } from '@vue/reactivity'
import { post } from '@/composables/httpCalls'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { Loader, Error },
    setup(){

        const { router, store, isLoading, error } = useBoilerplate(false);
        
        const oldPw=ref('');
        const newPw=ref('');
        const newPwRep=ref('');

        const changePW = async () => {

            try {

                isLoading.value = true;
                error.value = null;

                if(newPw.value != newPwRep.value){
                    isLoading.value = false;
                    return error.value="Es Necesario Repetir la Contraseña";
                }
                
                const url = "/api/changePW"
                const vars = {
                    oldpw: oldPw.value,
                    newpw: newPw.value
                }

                const res = await post(url, vars);

                if(res.status == 200){
                    isLoading.value = false;
                    M.toast({html: "Contraseña Modificada"});
                    oldPw.value = '';
                    newPw.value = '';
                    newPwRep.value = '';
                }else if(res.status == 412){
                    store.state.logUserOut();
                    return router.push({name: 'Auth'})
                }else{
                    const data = await res.json();
                    isLoading.value = false;
                    const errorMSG = data.message ? data.message : "No Se Pudo Cambiar la Contraseña";
                    return error.value=errorMSG;
                }
            } catch (err) {
                console.log(err);
                isLoading.value = false;
                error.value = "Error de Conexion"
            }

        }

        return { error, isLoading, oldPw, newPw, newPwRep, changePW }
    }
}
</script>