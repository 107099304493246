<template>
  <div class = "container">
    
    <CallError v-if="!isLoading && error!=null" :warning="error"/>

    <LoadingFullPage v-if="isLoading && !error"/>

    <AdsList v-if="!isLoading && !error" :ads="ads" />

    <Loader v-if="store.state.isLoadingMore">
        <h5 class="orange-text left-align">Cargando...</h5>
    </Loader>

    <FloatingButton 
      icon="fas fa-filter" 
      v-if="!filter"
      @click="promptFilter"
    />

    <FloatingButton 
      icon="fas fa-redo-alt" 
      v-if="filter!=null"
      @click="resetFilter"
    />
    
  </div>

  <teleport to="body">
    <FilterModal 
      :isActive="isActive" 
      @filterChosen="updateFilter"
      @close="isActive=false"
    />
  </teleport>

</template>

<script>
import LoadingFullPage from '@/components/UI/LoadingFullPage'
import Loader from '@/components/UI/Loader'
import CallError from '@/components/UI/CallError'
import AdsList from '@/components/Ads/AdsList'
import FilterModal from '@/components/modals/FilterModal'
import FloatingButton from '@/components/UI/Buttons/FloatingButton' 
import { ref } from '@vue/reactivity'
import { onMounted, watchEffect } from '@vue/runtime-core';
import useBoilerplate from '@/composables/useBoilerplate';
import { decryptKey } from '@/composables/encryption';
import { post } from '@/composables/httpCalls'
import { load } from '@/composables/address'

export default {
  
  components: { LoadingFullPage, Loader, AdsList, CallError, FloatingButton, FilterModal },
  
  setup(){
    
    const { store, router, error, isLoading, isActive } = useBoilerplate(true);
    
    const filter = ref(null);
    const myAlias = ref('');
    
    const ads = ref([]);

    const updateFilter = value => {
      filter.value = value;
      isActive.value = false;
    }

    //Update ads list every time ads  changes in globalstate
    watchEffect(() => {
      if(filter.value != null){
        ads.value = store.state.ads.filter(item => item.adType == filter.value)
      }
      else{
        ads.value = store.state.ads;
      }
      store.state.setInfinity(ads.value.length);
    })

    const getFirstAds = async () => {

      try{
        
        const url = "/api/getFirstAds";
        const res = await fetch(url, {cache: "no-cache"});
        
        if(res.status == 200){
          const data = await res.json();

          //Update Ads List
          store.state.loadFirst(data.ads);
          isLoading.value = false;

        }else{
          isLoading.value = false;
          throw new Error("No Se Pudieron Encontrar los Anuncios");
        }

      }catch(err){
        console.log(err);
        isLoading.value = false;
        throw new Error("Problema de Conexion");
      }

    }

    const initUser = async () => {
      
      try{

        const url = "/api/userInit";
        const vars = {string: "Init"};
        const res = await post(url, vars);

        if(res.status == 300){
          return router.push({name: 'Welcome'});
        }else if(res.status == 401){
          return router.push({name: 'TwoFA'});
        }else if(res.status == 403){
          return router.push({name: 'Blocked'});
        }else if(res.status == 412){
          store.state.logUserOut();
          return router.push({name: 'Auth'});
        }else if(res.status == 200){ //Handles Successfull Response
          
          const data = await res.json();
         
          //Save Rates
          sessionStorage.setItem('xRates', JSON.stringify(data.rates));

          //Set Alias
          myAlias.value = data.alias;
          store.state.setAlias(myAlias.value);
          const encryptedXPRIV = localStorage.getItem(myAlias.value);
          if(encryptedXPRIV == '' || encryptedXPRIV == null || data.encKey == '' || data.encKey == null){
            return router.push({name: "WalletRecover"});
          }
          const xPriv = decryptKey(encryptedXPRIV, data.encKey);
          const xPub = window.deriveXpubFromXpriv(xPriv);          
          sessionStorage.setItem('xPub', xPub);

          const address = window.makeAddress(xPub);
          sessionStorage.setItem('address', address);
          return load(address);
          
        }else{
          isLoading.value = false;
          throw new Error("Error de Sistema");
        }  

      }catch(err){
        console.log(err);
        isLoading.value = false;
        throw new Error(err);
      }

    }
    
    onMounted(async () => {
      
      M.AutoInit();
      store.state.reset('ads');
      
      //Init User (enc key, rates and alias)
      try{
        await initUser();
        await getFirstAds();
        isLoading.value = false;
      }catch(err){
        console.log(err.message);
        isLoading.value = false;
        return error.value = err.message;
      }
       
    })

    const promptFilter = () => {
      isActive.value = true;
    }

    const resetFilter = () => {
      filter.value = null;
    }
    
    return { store, ads, myAlias, 
            isLoading, isActive, 
            filter, promptFilter, resetFilter, 
            updateFilter, error 
          };
  }

}
</script>