<template>
  
    <div class="container" v-if="isLoading" >
      <LoadingFullPage v-if="isLoading"/>
    </div>
  
    <div v-else>

        <div class="row" v-if="isSuccess">
          <Success title="Anuncio Publicado" @goBack="isSuccess=false">
            <AdSuccess />
          </Success>
        </div>
        
        <div class="row grey darken-1 marginTop" v-else>

          <div class = "col s12 orange">
            <h5 class = "white-text center-align">Nuevo Anuncio</h5>
          </div>

          <LateralNav />

          <NewAdForm
            :allCards="cards"
            :xChangeData="xChangeData"
            @success="isSuccess=true"
          />

        </div>

    </div>
    
</template>

<script>

import LoadingFullPage from '@/components/UI/LoadingFullPage'
import AdSuccess from '@/components/Ads/AdSuccess';
import NewAdForm from '@/components/Ads/NewAdForm';
import Success from '@/components/UI/Success';
import LateralNav from '@/components/nav/LateralNav'
import useBoilerplate from '@/composables/useBoilerplate';
import { onMounted, reactive, ref } from '@vue/runtime-core';

export default {
  
  components: { 
    Success, AdSuccess, LateralNav, LoadingFullPage, NewAdForm
  },

  setup(){

    const { isSuccess, isLoading, router, store } = useBoilerplate(true); 
    
    const xChangeData = reactive({});
    const cards = ref([]);

    onMounted(async () => {
      try {
        const url = "/api/listCards";
        const res = await fetch(url, {cache: "no-cache"});
        if(res.status == 200){
          const data = await res.json();
          cards.value = data.cards;
          xChangeData.minMarginCUP = Number(data.cup) - 20;
          xChangeData.maxMarginCUP = Number(data.cup) + 20;
          xChangeData.minMarginMLC = Number(data.mlc) - 0.2;
          xChangeData.maxMarginMLC = Number(data.mlc) + 0.2;
          xChangeData.margen = Math.round(data.cup);
          xChangeData.cup = Math.round(data.cup);
          xChangeData.mlc = Number(data.mlc);
          console.log("CARDS: ", cards.value);
          console.log("XCHANGE DATA: ", xChangeData);
          isLoading.value = false;
        }else if(res.status == 412){
          store.state.logUserOut();
          return router.push({name: 'Auth'});
        }else{
          console.log("Error: ", res.status);
        }
      } catch (err) {
        console.log(err);
      }
    })

    return { xChangeData, isLoading, isSuccess, cards }
  
  }

}

</script>