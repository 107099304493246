import {vips} from './vips'

export const getQBitaFee = (fiat, rates) => {
  let fee = 0;
  if(fiat <= 100){
    fee = Number(rates.oneDollar);
  }else if(fiat > 1000){
    const excessAmount = rates.BTC - rates.kDollars;
    const lowFee = excessAmount / 200;
    fee = Number(rates.tenDollars) + Number(lowFee);
  }else{
    fee = rates.BTC / 100;
  }
  return fee;
}

export const getQBitaFeeVip = (fiat, rates) => {
  const baseFee = (rates.oneDollar * 2.5).toFixed(8);
  const variableFee = ((rates.BTC / 100) * 0.85).toFixed(8);
  let fee = (Number(baseFee) + Number(variableFee)).toFixed(8);
  return Number(fee);
}

export const buyerSatsVSvip = (sats, fee) => {
  const btcAmount = sats - fee;
  return btcAmount.toFixed(8);
}

export const isSpecialFee = (alias, ad) => {
  //Add here, into the vips array all users' aliases 
  //that will work under the special qbita fee arrangement
  if(ad.adType == "Vendo" && vips.indexOf(ad.user) >= 0){
    return true;
  }else if(ad.adType == "Compro" && vips.indexOf(alias) >= 0){
    return true;
  }else{
    return false;
  }
}

/* 
  TX Size:
  1 input and 1 output:
  "size": 215,
  "vsize": 133

  1 input and 2 outputs:
  "size": 247,
  "vsize": 165

  2 inputs and 2 outputs
  "size": 419,
  "vsize": 256
*/

export const getSize = utxos => {
  let size = 74;
  utxos.forEach(() => size += 92);
  return size;
}
