<template>
    <div class="fixed-action-btn">
        <a class="btn-floating btn-large green">
            <i :class="icon"></i>
        </a>
    </div>
</template>

<script>
export default {
    props: ['icon']
}
</script>

<style>

</style>