<template>
    <div class="row light-green-text center">
        <div class="col s12 l10 offset-l1 left-align">
            <h4 class="orange-text">Tasas de Cambio</h4>
        </div>
        <div class="col s12 l5 offset-l1 black" v-if="isLoading==true">
            <h5><b>...Cargando</b></h5>
        </div>
        <div class="col s12 l5 offset-l1 black" v-else>
            <h5 class="grey-text text-lighten-3 center-align">
               BTC/CUP:
            </h5>
            <h2>
                <i class="fas fa-arrow-circle-up light-green-text" v-if="displayRates[4]==true"></i>
                <i class="fas fa-arrow-circle-down red-text" v-if="displayRates[4]==false"></i>
                <b>{{ ' ' + displayRates[0] }} </b>
            </h2>
        </div>
        <div class="col s12 l5  black" v-if="isLoading==true">
            <h5><b>...Cargando</b></h5>
        </div>
        <div class="col s12 l5  black" v-else>
            <h5 class = "center-align grey-text text-lighten-3">
                BTC/MLC:
            </h5>
            <h2>
                <i class="fas fa-arrow-circle-up light-green-text" v-if="displayRates[5]==true"></i>
                <i class="fas fa-arrow-circle-down red-text" v-if="displayRates[5]==false"></i>
                <b>{{ ' ' + displayRates[1] }} </b>
            </h2>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import useBoilerplate from '@/composables/useBoilerplate'
import { onMounted } from '@vue/runtime-core';
export default {
    setup(){

        const { isLoading } = useBoilerplate(true);
    
        let displayRates = ref([]);
        
        onMounted(async () => {
            
            const url = '/api/public/getRates';
            const res = await fetch(url, {cache: "no-cache"});
            const data = await res.json();
            displayRates.value = data;
            isLoading.value = false;
        })

        return { isLoading, displayRates }
    }
}
</script>

<style scoped>
    .black{
        margin-bottom: 20px !important;
    }
</style>