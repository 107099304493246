<template>
    <p>
        <span class = 'white-text green' v-if="level=='Experto'">
            <i class = 'fas fa-graduation-cap'></i> {{ level }}
        </span>
        
        <span class = 'white-text red' v-if="level=='Admin'">
            <i class = 'fas fa-user-shield'></i> {{ level }}
        </span>

        <span class = 'white-text red' v-if="level=='Posible Estafador'">
            <i class = 'fas fa-exclamation-circle'></i> {{ level }}
        </span>

        <span class = 'white-text light-blue' v-if="level=='Nuevo'">
            <i class = 'fas fa-child'></i> {{ level }}
        </span>

        <span class = 'white-text grey darken-2' v-if="level=='Silver'">
            <i class = 'fas fa-coins'></i> {{ level }}
        </span>

        <span class = 'yellow-text black' v-if="level=='Gold'">
            <i class = 'fas fa-coins'></i><i class = 'fas fa-coins'></i> {{ level }}
        </span>

        <span class = 'yellow-text black text-lighten-4' v-if="level=='Platinum'">
            <i class = 'fas fa-coins'></i><i class = 'fas fa-coins'></i><i class = 'fas fa-coins'></i> {{ level }}
        </span>

        <span class = 'grey darken-3 white-text' v-if="level=='Diamond'">
            <i class = 'fas fa-gem'></i> {{ level }}
        </span>

        <span class = 'orange white-text' v-if="level=='Satoshi'">
            <i class = 'fab fa-bitcoin'></i> {{ level }}
        </span>
    </p>

</template>

<script>
export default {
    props: ['level']
}
</script>

<style scoped>
    span{
        position: absolute;
        top: 105px;
        border-radius: 5px; 
        padding: 7px;
        font-size: 20px;
    }
</style>