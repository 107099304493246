<template>
    <li>
        <div class = "collapsible-header white-text grey darken-1"><i class = "fas fa-bell"></i> Notificaciones Push</div>
        <div class = "collapsible-body grey darken-2" id = 'pushCollapsible'>
        <label for="pushCheck">
            <input type="checkbox" id = "pushCheck" :checked="push" ref="pushSwitch" @click="handlePush">
            <span class = "white-text">Notificaciones Push</span>
        </label>
        <p class = "white-text">Seleccione esta opción para recibir notificaciones push a su dispositivo cada vez que recibe un mensaje por chat y cada vez que se actualize el estado de una de sus compraventas.</p>
        </div>
    </li>
</template>

<script>
import { ref } from '@vue/reactivity'
import { pushSub, pushUnsub } from '@/composables/push';
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    props: ['push'],
    setup(props){
        
        const {store} = useBoilerplate(false);

        const pushSwitch = ref();
        const handlePush = () => {
            const pushValue = pushSwitch.value.checked;
            if(pushValue == true){
                return pushSub();
            }else{
                return pushUnsub(store.state.alias);
            }
        }
        return { pushSwitch, handlePush }
    }
}
</script>

<style>

</style>