<template>
    <div class="col s12 center-align loaderPad">
        <slot></slot>
        <div class="progress"><div class="indeterminate"></div></div>
    </div>
</template>

<style scoped>
    .loaderPad{
        padding-top: 20px
    }
</style>