<template>

    <div class = "container" >
        <div class="row grey darken-1 marginTop">

            <div class = "col s12 orange">
                <h5 class = "white-text center-align">Monedero Bitcoin</h5>
            </div>

            <LateralNav />

            <div class="col s12 l8 left-align grey darken-2 wallet-box" >
                
                <LoadingFullPage v-if="isLoading" />
                
                <section class = "center" v-show="isLoading == false">
                    <h5 class = "grey-text">BTC/USD: {{xChange}} $</h5>
                    <h3 class = 'white-text'>{{balanceBTC}} <i class="fab fa-bitcoin orange-text"></i></h3>

                    <h5 class = "grey-text" v-if="currencyPreference=='USD'">{{balanceUSD}} USD</h5>
                    <h5 class = "grey-text" v-if="currencyPreference=='CUP'">{{balanceCUP}} CUP</h5>
                    <h5 class = "grey-text" v-if="currencyPreference=='MLC'">{{balanceMLC}} MLC</h5>
                    <br>
                    <div class="col s4 right-align">
                        <b 
                            class = "white-text pref" 
                            :class="currencyPreference == 'CUP' ? 'preferred' : ''"
                            @click="handleCurrencyPreference('CUP')"
                        >
                            CUP
                        </b>
                    </div>
                    <div class="col s4 center">
                        <b 
                            class = "white-text pref" 
                            :class="currencyPreference == 'USD' ? 'preferred' : ''"
                            @click="handleCurrencyPreference('USD')"
                        >
                            USD
                        </b>
                    </div>
                    <div class="col s4 left-align">
                        <b
                            class = "white-text pref" 
                            :class="currencyPreference == 'MLC' ? 'preferred' : ''"
                            @click="handleCurrencyPreference('MLC')"
                        >
                            MLC
                        </b>
                    </div>

                    <Address :address="address" />
                    
                    <TXS :address="address" :xChange="xChange" @listed="updateBalances"/>
                    
                    <div class="col s12 m6 marginTop">
                        <LargeButton 
                            color="green"
                            text="Enviar" 
                            icon="fas fa-paper-plane"
                            position="left"
                            @click="router.push({name: 'SendBTC'})"
                        />
                    </div>

                </section>
            
            </div>
            
        </div>
    </div>

</template>

<script>

import Error from '@/components/UI/Error';
import LoadingFullPage from '@/components/UI/LoadingFullPage';
import LargeButton from '@/components/UI/Buttons/LargeButton';
import Address from '@/components/Wallet/Address';
import TXS from '@/components/Wallet/TXS';
import FloatingButton from '@/components/UI/Buttons/FloatingButton'
import LateralNav from '@/components/nav/LateralNav'
import useBoilerplate from '@/composables/useBoilerplate';
import { onMounted, ref } from '@vue/runtime-core';

export default {
  
    components: { 
        FloatingButton, Error, LoadingFullPage, 
        LateralNav, LargeButton, Address, TXS
    },

    setup(){

        const { error, isLoading, router, store } = useBoilerplate(true); 
        
        const balanceBTC = ref(0);
        const balanceUSD = ref(0);
        const balanceCUP = ref(0);
        const balanceMLC = ref(0);
        const xChange = ref(0);
        const address = ref('');
        const currencyPreference = ref('USD');

        const handleCurrencyPreference = value => {
            currencyPreference.value = value;
            localStorage.setItem('currencyPreference', currencyPreference.value);
        }

        const updateBalances = obj => {
            balanceBTC.value = obj.balanceBTC;
            balanceUSD.value = obj.balanceUSD;
            xChange.value = obj.rates;
        }

        onMounted(async () => {
            try {

                 address.value = sessionStorage.getItem('address');

                if(localStorage.currencyPreference){
                    currencyPreference.value = localStorage.getItem('currencyPreference');
                }
                
                const url = `/api/wallet/${address.value}`;
                const res = await fetch(url);

                if(res.status == 200){
                    const data = await res.json();
                    balanceBTC.value = data.balanceBTC;
                    balanceUSD.value = data.balanceUSD;
                    balanceCUP.value = data.balanceCUP;
                    balanceMLC.value = data.balanceMLC;
                    xChange.value = data.BTC_USD;
                    return isLoading.value = false;
                }else if(res.status == 412){
                    store.state.logUserOut();
                    router.push({name: 'Auth'});
                }else{
                    isLoading.value = false;
                    return error.value = "No Se Pudo Cargar el Monedero";
                }
                
            } catch (err) {
                console.log(err);
                error.value = "Error";
                isLoading.value = false;
            }
        })

        return { 
            router, error, isLoading, updateBalances,
            balanceBTC, balanceUSD, balanceCUP, balanceMLC, 
            xChange, address, currencyPreference, handleCurrencyPreference
        }
    
    }

}

</script>

<style scoped>
  .wallet-box{
      padding-bottom: 60px;
  }

  .preferred{
      color: orange !important;
      font-size: 17px;
  }

  .pref{
      cursor: pointer;
  }
  
</style>