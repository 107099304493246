<template>
    <div class="col s12 marginTop grey darken-3">
        <h4 class="orange-text center-align">Mi Dirección</h4>
        <p class = "center-align">
            <canvas id="qrCanvas" width="300" height="300"></canvas>
        </p>

        <p class="white-text left-align copy" @click="handleCopy">
            <i class="fas fa-copy"></i> Copiar Dirección
        </p>
        <h5 class = "white orange-text address"> {{ cutKey(address, 4)}} </h5>
        
    </div>
</template>

<script>
import { cutKey } from '@/composables/helpers'
import { showMeQR } from '@/composables/qr'
import { onMounted, watch } from '@vue/runtime-core'
export default {
    props: ['address'],
    emits: ['copied'],
    setup(props){
        watch(() => props.address, () => showMeQR(props.address, "qrCanvas", "true"));
        const handleCopy = () => {
            navigator.clipboard.writeText(props.address);
            M.toast({html: "Dirección Copiada"});
        }
        return { cutKey, handleCopy }
    }
}
</script>

<style scoped>
    canvas{
        width: 180px !important;
        height: 180px !important;
    }
    .address{ padding: 15px !important }
    .copy{
        cursor: pointer;
    }
</style>