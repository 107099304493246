<template>
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
        <div class="row modal-content center white">
            <div class = "col s12 center-align orange-text">
                <h2>
                    <i class = "fas fa-exclamation-circle red-text"></i>
                </h2>
                <h5>Borrar Anuncio</h5>
            </div>
            <div class = "col s12 center-align grey-text text-darken-4">
                <p>El anuncio no serà más visible</p>
                <p>Seguro que quiere borrar el anuncio?</p>
            </div>
            <div class = "col s12 center-align grey-text text-darken-4">
                
                <Loader v-if="isLoading"/>
                <Error :warning="error" v-if="!isLoading && error!=null"/>
              
            </div> 

            <LargeButton color="red" icon="fa fa-trash" text="Si, Quiero Borrarlo" position="left" @clicked="deleteAd"/>

        </div>
    </div>
</template>

<script>
import LargeButton from '@/components/UI/Buttons/LargeButton';
import Loader from '@/components/UI/Loader';
import Error from '@/components/UI/Error';
import { post } from '@/composables/httpCalls';
import useBoilerplate from '@/composables/useBoilerplate';

export default {

    props: ['id', 'isActive'],
    components: { Loader, Error, LargeButton },
    emits: ['close'],
    
    setup(props, context){
      
        const { store, error, isLoading } = useBoilerplate(false);

        const closeModal = () => {
            context.emit('close');
        }

        const deleteAd = async () => {

          error.value = null;
          isLoading.value = true;

          try{
        
            const url = "/api/deleteAd";
            const vars = {id: props.id};
            const res = await post(url, vars);
            
            if(res.status == 409){
              isLoading.value = false;
              return error.value = "Usted No Esta Autorizado a Cancelar el Anuncio";
            }else if(res.status == 200){
              isLoading.value = false;
              store.state.removeAd(props.id);
              M.toast({html: "Anuncio Cancelado"});
              return context.emit('close');
            }else{
              isLoading.value = false;
              return error.value = "No Se Pudo Cancelar el Anuncio";
            }

          }catch(err){
            console.log(err);
            isLoading.value = false;
            return error.value = "No Se Pudo Cancelar el Anuncio";
          }
        }

        return { isLoading, error, closeModal, deleteAd };
    }
}
</script>