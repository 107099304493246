<template>
    <div class="col s12 msgBox grey darken-4">
        <div class="col s2 center">
            <p>
                <i class="fas fa-paperclip orange-text" @click="switchView"></i> 
            </p>
        </div>
        <form @submit.prevent="sendMsg">
            <div class="col s7 ">
                <div class="input-field">
                    <input 
                        type="text" 
                        v-model="message" 
                        placeholder="Mensaje"
                        @keyup="userTyping"
                    >
                </div>
            </div>
            <div class="col s3 center">
                <p>
                    <button class = "btn-small orange">
                        <i class="fas fa-paper-plane white-text"></i> 
                    </button>
                    
                </p>
            </div>
        </form>
    </div>
</template>

<script>

import { socket } from '@/composables/socket'
import { ref } from '@vue/reactivity'
import { saveMSG } from '@/composables/saveMSG'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    
    props: ['room', 'counterparty'],
    
    setup(props, context){

        const { store, router } = useBoilerplate(false);
        
        const message = ref('');

        const switchView = () => {
            context.emit('switch');
        }

        const sendMsg = () =>{
            
            if(message.value.length < 1){
                return;
            }

            if(store.state.alias == ''){
                return router.push({name: 'Auth'});
            }
            
            //Set up object
            const roomClear = store.state.alias > props.counterparty ? 
                                store.state.alias + "---" + props.counterparty :
                                props.counterparty + "---" + store.state.alias; 
            
            const msg2BeSaved = {
                message: message.value,
                room: roomClear,
                roomId: props.room,
                sender: store.state.alias,
                counterparty: props.counterparty
            }

            
            //Sed MSG through Socket
            socket.emit('send message', msg2BeSaved);
            
            //Update UI locally
            msg2BeSaved.timestamp = '';
            store.state.updateMSGlist(msg2BeSaved);

            socket.emit('stopped', {
                room: props.room,
                counterparty: props.counterparty
            })

            saveMSG(msg2BeSaved);

            message.value = '';

        }

        const userTyping = () =>{
            if(message.value.length > 1){
                socket.emit('typing', {
                    room: props.room,
                    counterparty: props.counterparty
                });
            }else{
                socket.emit('stopped', {
                    room: props.room,
                    counterparty: props.counterparty
                })
            }
        }

        return { message, sendMsg, userTyping, switchView }
    
    }

    
}
</script>

<style scoped>
    .msgBox{
        margin: 0 auto !important;
        position: absolute;
        width: 100% !important;
        bottom: 0px !important;
        left: 0% !important;
        height: 100px;
        border-top: 5px solid orange;
        padding-bottom: 10px;
    }

    .fa-paperclip{
        font-size: 18px;
        position: relative;
        top: 15px;
    }
    button{
        position: relative;
        top: 5px;
    }

    @media screen and (max-height: 640px) {
        .msgBox{
            margin: 0 auto !important;
            position: absolute;
            width: 100% !important;
            bottom: 0px !important;
            left: 0% !important;
            height: 100px;
            border-top: 5px solid orange;
            padding-bottom: 10px;
        }
    }

</style>