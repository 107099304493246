<template>
    <div class="col s12 left-align">
        <label>
            <input type = "checkbox" @change="handleCheck"/><span class = "white-text marginBottom marginTop">{{ text }}</span>
        </label>
    </div>
</template>

<script>
export default {
    props: ['text'],
    emits: ['checked'],
    setup(props, context){
        const handleCheck = () => {
            context.emit('checked');
        }
        return { handleCheck };
    }
}
</script>

<style>

</style>