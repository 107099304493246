<template>
  <div class="col s5 center">
      <Stars :score="review.userRating" :isBig="false" />
  </div>
  <div class="col s7 center-align">
      <h6 class = "grey darken-3 white-text">{{ review.author }}</h6>
  </div>
  <div class="col s12">
      <p class = "left-align">
          <b class = "left-align">{{ review.text }}</b>
      </p>
      <p class = "right-align">{{ timeAgo }}</p>
  </div>
</template>

<script>
import Stars from "@/components/Reputation/Stars"
import { computed } from '@vue/runtime-core'
import { timeDiff } from '@/composables/time'
export default {
    components: { Stars },
    props: ['review'],
    setup(props){
        const timeAgo = computed(() => timeDiff(props.review.dateCreated))
        return { timeAgo }
    }
}
</script>

<style scoped>
    h6{
        padding: 5px;
        border-radius: 10px;
    }
</style>