<template>
  
  <div 
    v-if="store.state.escrow.escrow.winner != store.state.alias"
  >
    
    <p class = 'left-align red-text'>
      <i class='fas fa-exclamation-circle icon20'></i> Disputa Resuelta
    </p>

    <p class = 'left-align grey-text text-darken-3'>
      <b class = 'orange-text'>QBITA</b> resolvió la disputa en favor de {{ store.state.escrow.escrow.winner }}.
    </p>  
      
  </div>

  <div 
    v-if="store.state.escrow.escrow.winner == store.state.alias"
  >
    
    <p class = 'left-align green-text'>
      <i class='fas fa-exclamation-circle icon20'></i> Disputa Resuelta
    </p>

    <p class = 'left-align grey-text text-darken-3'>
      <b class = 'orange-text'>QBITA</b> resolvió la disputa en su favor.
    </p>  

    <p class = 'left-align grey-text text-darken-3'>
      Especifique los costos de envio (fee de mineria) y la dirección donde quiere recibir los Bitcoin (puede ser de cualquier billetera)
    </p>  

    <SignTXform />
    
  </div>

</template>

<script>

import useBoilerplate from '@/composables/useBoilerplate';
import CountDown from '@/components/UI/CountDown'
import SignTXform from '@/components/Escrows/Bitcoin/SignTXform'

export default {
  components: { CountDown, SignTXform },
  setup(){
    const {store} = useBoilerplate(false);
    return { store };
  }
}
</script>
