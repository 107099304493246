<template>
    
    <div class="col s12 center top">
        
        <h4 class="green-text">
            {{ title }}
        </h4>

        <slot></slot>
    
    </div>

    <div class="col s12 center bottom">
        <a class="btn orange white-text margin" @click="goBack">
            <i class="fas fa-check"></i>
        </a>
    </div>

</template>

<script>
export default {
    props: ['title'],
    emits: ['goBack'],

    setup(props, context){
        
        const goBack = () => {
            context.emit('goBack')
        } 

        return { goBack };
    }
}
</script>

<style scoped>
    .top{
        margin-top: 100px;
    }
    .bottom{
        margin-bottom: 90px;
    }
</style>