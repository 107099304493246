<template>
    <li :class="{active: isActive}">
        <div 
            class="collapsible-header white orange-text"
            @click="signalOpen"
        >
            <h6 :class="color">
                <i :class="icon"></i>
                {{ title }}
            </h6>
        </div>
        <div class = "collapsible-body left-align white">
            <slot></slot>
        </div>
    </li>
</template>

<script>
import { ref } from '@vue/reactivity';
export default {
    props: ['title', 'isActive', 'icon', 'color'],
    emits: ['clicked'],
    setup(props, context){
        let isOpen = false;
        const signalOpen = () => {
            isOpen = !isOpen;
            if(isOpen == true){
                context.emit('clicked');
            }
        };
        return { signalOpen }
    }
}
</script>

<style scoped>
    h6{font-weight: bold;}
</style>