<template>

    <div class = "container" >

        <div class="row grey darken-1 marginTop ">

            <div class = "col s12 orange">
                <h5 class = "white-text center-align">Enviar Bitcoin</h5>
            </div>

            <LateralNav />

            <div class="col s12 l8 left-align grey darken-2 wallet-box" >
                <LoadingFullPage v-if="isLoading" />
                <SendBTCform 
                  :max="balanceBTC" 
                  :xChange="xChange" 
                  :fees="fees" 
                  :utxos="utxos"
                  v-else
                />
            </div>
            
        </div>

    </div>

</template>

<script>

import Error from '@/components/UI/Error';
import LoadingFullPage from '@/components/UI/LoadingFullPage';
import LateralNav from '@/components/nav/LateralNav'
import useBoilerplate from '@/composables/useBoilerplate';
import SendBTCform from '@/components/Wallet/SendBTCform';

import { onMounted, ref } from '@vue/runtime-core';

export default {
  
    components: { 
        Error, LoadingFullPage, LateralNav, SendBTCform
    },

    setup(){

        const { error, isLoading, router, store } = useBoilerplate(true); 
        
        const balanceBTC = ref(0);
        const xChange = ref(0);
        const fees = ref(null);
        const utxos = ref([]);

        onMounted(async () => {
            try {
                console.log('loaded');
                const address = sessionStorage.getItem('address');
                const url = `/api/wallet/${address}`;
                const res = await fetch(url);
                if(res.status == 200){
                    const data = await res.json();
                    console.log("DATA:\n\n", data);
                    balanceBTC.value = data.balanceBTC;
                    xChange.value = data.BTC_USD;
                    fees.value = data.fees;
                    utxos.value = data.UTXOS;
                    return isLoading.value = false;
                }else if(res.status == 412){
                    store.state.logUserOut();
                    router.push({name: 'Auth'});
                }else{
                    isLoading.value = false;
                    return error.value = "No Se Pudo Cargar el Monedero";
                }
                
            } catch (err) {
                console.log(err);
                error.value = "Error";
                isLoading.value = false;
            }
        })

        return { 
            router, error, isLoading, xChange, balanceBTC, fees, utxos
        }
    
    }

}

</script>

<style scoped>
  .wallet-box{
      padding-top: 25px !important;
      padding-bottom: 80px !important;
  }
</style>