<template>
  <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
    <div class="row modal-content center grey darken-3">
            <div class = "col s12 center-align orange white-text">
                <h5>{{ title }}</h5>
            </div>
            
            <div class = "col s12 left-align white-text marginTop" 
              v-if="store.state.escrow.escrow.stage=='Pago Efectuado'"
            >
                <p> Usted va a entregar los Bitcoin a al comprador <b class="orange-text">{{store.state.escrow.escrow.buyer}}</b>.</p>
                <p> Asegurese que Usted recibió los <b class="orange-text">{{store.state.escrow.escrow.toBePaid}}</b>.</p>
                <p> <b class="red-text">Esta acción no es reversible.</b></p>
            </div>
            <div class = "col s12 left-align white-text marginTop" v-else>
                <p> Usted va a recibir los Bitcoin a la dirección:</p>
                <p><b class = "orange-text">{{ cutAddress }}</b></p>
                <p> Se van a aplicar los costos de envio (mineria) de: <b class = "orange-text">{{miningFee}} sat/byte</b></p>
            </div>

            <div class = "col s12 left-align grey-text text-darken-4 marginTop">
                <label class = "left-align">
                <input type = "checkbox" required @click="toggleWarnings('warningOne')"/>
                <span><b class = "white-text">{{ warning_one }}</b></span>
                </label>
            </div>

            <div class = "col s12 left-align grey-text text-darken-4 marginTop">
                <label class = "left-align">
                <input type = "checkbox" required @click="toggleWarnings('warningTwo')"/>
                <span><b class = "white-text">{{ warning_two }}</b></span>
                </label>
            </div>

            <div class = "col s12 center-align grey-text text-darken-4" style ="margin-bottom: 20px">
                
                <Loader v-if="isLoading"/>
                <Error :warning="error" v-if="error!=null"/>                

            </div> 

            <LargeButton 
              :color="color" 
              :icon="icon" 
              :text="textBTN" 
              position="left" 
              @clicked="handleClick"
            />

        </div>

    </div>

</template>

<script>

import Loader from '@/components/UI/Loader'
import Error from '@/components/UI/Error'
import LargeButton from '@/components/UI/Buttons/LargeButton'
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import { broadcast } from '@/composables/broadcast'
import { postBuyerSignature } from '@/composables/postBuyerSignature'
import { socket } from '@/composables/socket'
import { cutKey } from '@/composables/helpers'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { Loader, Error, LargeButton },
    props: ['signedTX', 'hex', 
            'address', 'miningFee', 
            'isActive'
          ],
    emits: ['success', 'close'],

    setup(props, context){

      const {store, router, error, isLoading} = useBoilerplate(false);
      
      const color = ref('green disabled');
      
      const icon = computed(() => {
        return store.state.escrow.escrow.stage == "BTC depositados en Escrow multiSig" ?
                                                  "fas fa-pen" : "fab fa-bitcoin"
      });
      
      const textBTN = computed(() => {
        if(store.state.escrow.escrow.stage == "BTC depositados en Escrow multiSig"){
          return "Firma"
        }else if(store.state.escrow.escrow.stage=="Pago Efectuado"){
          return "Entrega"
        }else if(store.state.escrow.escrow.stage=="Compraventa Completada" || store.state.escrow.escrow.stage=="Compraventa Cancelada"){
          return "Accelera"
        }else{
          return "Recibe"
        }
      })

      const title = computed(() => {
        if(store.state.escrow.escrow.stage == "BTC depositados en Escrow multiSig"){
          return "Firma"
        }else if(store.state.escrow.escrow.stage=="Pago Efectuado"){
          return "Liberar Bitcoin"
        }else if(store.state.escrow.escrow.stage=="Compraventa Completada" || store.state.escrow.escrow.stage=="Compraventa Cancelada"){
          return "RBF"
        }else{
          return "Recibir Bitcoin"
        }
      })

      const cutAddress = computed(() => props.address ? cutKey(props.address, 4) : '');

      const warning_one = computed(() => {
        if(store.state.escrow.escrow.stage=="Pago Efectuado"){
          return `He revisado el saldo de mi tarjeta`
        }else{
          return `La dirección es correcta.`
        }
      })

      const warning_two = computed(() => {
        if(store.state.escrow.escrow.stage=="Pago Efectuado"){
          return `He recibido todo mi dinero`
        }else{
          return `La fee de mineria es correcta`
        }
      })

      const warnings = {
        warningOne: false,
        warningTwo: false
      }

      const toggleWarnings = item => {
        warnings[item] = !warnings[item];
        if(warnings.warningOne == true && warnings.warningTwo == true){
          color.value = "green";
        }else{
          color.value = "green disabled"
        }
      } 
      
      const handleClick = async () => {
        isLoading.value = true;
        error.value = null;
        let data = null;

        try {
          
          //If it's the first signature....
          if(store.state.escrow.escrow.stage=="BTC depositados en Escrow multiSig"){
            data = await postBuyerSignature(props.signedTX, store.state.escrow.escrow._id);
          }else{ //If it is a last signature
            data = await broadcast(props.hex, store.state.escrow.escrow._id);
          }

          isLoading.value = false;

          if(data.status == false){  
            return error.value = data.error;
          }
          
          //After function execution

          //If it was 1st signature
          if(store.state.escrow.escrow.stage=="BTC depositados en Escrow multiSig"){
            store.state.escrowReplace(data.escrow);
            const html = "Transacción Firmada";
            M.toast({html});
            socket.emit('new escrow stage', data.escrow);
            isLoading.value = false;
            return closeModal();
          }else{ //Emit Success if it was a Broadcast
            isLoading.value = false;
            return router.push({
                name: "EscrowSuccess", 
                params: {escrow: store.state.escrow.escrow._id}
              });
          }

        } catch (err) {
          console.log(err);
          error.value = "Error"
          isLoading.value = false;
        }
        
      }

      const closeModal = () => {
        error.value = null;
        context.emit('close');
      }

      return { 
              isLoading, error, 
              toggleWarnings, title, 
              cutAddress, warning_one, warning_two,
              color, icon, textBTN, handleClick, store, closeModal
            }
      }

}
</script>

<style>

</style>