import bell from '@/assets/sounds/bell.mp3'
import bipChat from '@/assets/sounds/bip_chat.mp3'

export const isItBlinking = async () => {
    
    //reference DOM elements
    const chats = document.querySelectorAll('.chats');
    const escrows = document.querySelectorAll('.escrows');
    const menuBTN = document.querySelector('.sidenav-trigger');

    //Clean Pulsations
    if(menuBTN){
        menuBTN.classList.remove('pulse');
    }
    chats.forEach(item => item.classList.remove('pulse'));
    escrows.forEach(item => item.classList.remove('pulse'));

    //Query DB
    const url = '/api/blink';
    const res = await fetch(url , {cache: "no-cache"});

    //Handle results and render pulsations
    if(res.status == 200){
        const data = await res.json();
        if(data.hasUnseenRooms == true){
            chats.forEach(item => item.classList.add('pulse'));
        }
        if(data.hasUnseenEscrows == true){
            escrows.forEach(item => item.classList.add('pulse'));
        }
        if(menuBTN && (data.hasUnseenRooms == true || data.hasUnseenEscrows == true)){
            menuBTN.classList.add('pulse');
        }
    }
}

export const play = (type) => {
    const audio = type == "chat" ? new Audio(bipChat) : new Audio(bell);
    audio.play();
}

export const stopBlinkChat = () => {
    const chats = document.querySelectorAll('.chats');
    chats.forEach(item => item.classList.remove('pulse'));
}

export const stopBlinkEscrow = () => {
    const escrows = document.querySelectorAll('.escrows');
    escrows.forEach(item => item.classList.remove('pulse'));
}