<template>
    <a :href="url" target="_blank">
        <div class="col s12 l8 offset-l2 white z-depth-3 marginTop">
            <div class="col s12 left-align" v-if="tx.isConfirmed==true">
                <h5 v-if="tx.type=='IN'" class="green-text">
                    + {{tx.value}} BTC
                </h5>
                <h5 v-if="tx.type=='OUT'" class="red-text">
                    - {{tx.value}} BTC
                </h5>
            </div>
            <div class="col s12 left-align grey-text unbox" v-else>
                <h5 class = "center-text">
                    <span v-if="tx.type=='IN'">+</span> 
                    <span v-if="tx.type=='OUT'">-</span> {{tx.value}} BTC <b class="orange-text no-conf right-align"><i class="far fa-clock"></i></b>
                </h5>
            </div>
            <div class="col s12 center-align grey-text text-darken-3 ">
                <h5>{{ cutKey(tx.hash, 7) }}</h5>
                <p class="right-align"><b class="light-blue-text">VISUALIZAR</b></p>
            </div>
        </div>
    </a>
  
</template>

<script>
import { computed } from '@vue/runtime-core'
import { cutKey } from '@/composables/helpers'

export default {
    props: ['tx'],
    setup(props){
        const url = computed(() => "https://mempool.space/es/tx/" + props.tx.hash);
        return { url, cutKey };
    }
}
</script>

<style scoped>
    .padTop{
        padding-top: 10px !important;
    }

    .unbox{ position: relative; }
    .no-conf{ font-size: 36px; position: absolute; right: 15px !important }
    
</style>