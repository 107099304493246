import { useRouter } from 'vue-router';
import { post } from './httpCalls'

export const saveMSG = async (msg) => {
    const router = useRouter();
    const url = "/api/saveMSG";
    const vars = { msg }
    const res = await post(url, vars);
    if(res.status != 200){
        return router.push({name: 'Auth'});
    }
}