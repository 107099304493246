<template>

    <li class = "grey-text text-darken-3">
        Hola, <b>{{ store.state.alias}}</b>
    </li>
    <li>
        <button class ="btn orange" @click="logout">
            <i class="fas fa-sign-out-alt white-text"></i> Salir
        </button>
    </li>

</template>

<script>

import MenuButton from '@/components/UI/Buttons/MenuButton';
import useBoilerplate from '@/composables/useBoilerplate';
import { socket } from '@/composables/socket'

export default {
    props: ['menu'],
    components: { MenuButton },
    setup(){

        const { store, router } = useBoilerplate(false);
        
        const logout = () => {
            store.state.logUserOut();
            router.push({name: "Auth"})
        }

        socket.on('connect me', nicks => {
            store.state.updateConnectedUsers(nicks);
        })


        return { logout, store }
    }
}
</script>
<style scoped>
    li{
        margin-right: 30px;
    }
    button{
        border-radius: 10px;;
    }
</style>