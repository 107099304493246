<template>
    <li>
        
        <p 
            class = 'left-align grey-text text-darken-4'
            v-if="store.state.escrow.escrow.stage=='Disputa Abierta'"
        >
            <i class = "fas fa-exclamation-circle green-text"></i> Disputa Abierta. Coopere con QBita para llegar a una resolucion rapida de la disputa.
        </p>
        
        <p 
            class = 'left-align red-text'
            v-else-if="store.state.escrow.escrow.stage=='Disputa Resuelta'"
        >
            <b>
                <i class = "fas fa-exclamation-circle red-text"></i> Disputa Resuelta y cerrada
            </b>
        </p>


        <StopTX v-else-if="store.state.escrow.escrow.stage=='Inicio'"/>
        
        
        <p 
            class = 'left-align grey-text text-darken-4'
            v-else-if="store.state.escrow.escrow.stage=='BTC enviados a Escrow multiSig' ||
            store.state.escrow.escrow.stage=='BTC depositados en Escrow multiSig'"
        >
            <i class = "fas fa-exclamation-circle red-text"></i> No es posible abrir una disputa en esta etapa de la compraventa.<br>
            <span v-if="store.state.escrow.escrow.seller==store.state.alias">
            Si el comprador no paga los BTC en el plazo establecido (6 horas desde la primera confirmación), 
            el vendedor va a poder recuperar los BTC sin necesidad de abrir la disputa.
            </span>
        </p>

        <p 
            class = 'left-align grey-text text-darken-4'
            v-else-if="store.state.escrow.escrow.stage=='Compraventa Completada' ||
            store.state.escrow.escrow.stage=='Compraventa Cancelada'"
        >

            <i class = "fas fa-exclamation-circle red-text"></i> No es posible abrir una disputa porque la compraventa ya llegó a su conclusión
        
        </p>

        <p 
            class = 'left-align grey-text text-darken-4'
            v-else-if="store.state.escrow.escrow.stage=='Depósito Parcial en Escrow multiSig' ||
            store.state.escrow.escrow.stage=='Pago Fuera Tiempo Maximo'"
        >
            <i class = "fas fa-exclamation-circle red-text"></i> No es posible abrir una disputa en esta etapa.
        </p>

        <p 
            class = 'left-align grey-text text-darken-4'
            v-else
        >
            Para abrir la disputa, haga click en el boton abajo.<br><br>
            <br><br>
            <span class = "center-align" style="display: block"> 
            <LargeButton 
                text="Abrir Disputa" 
                icon="fas fa-fist-raised" 
                position="left" 
                color="red" 
                @clicked="goOpenDispute"
            />
            </span>

        </p>

    </li>

</template>

<script>
import LargeButton from '@/components/UI/Buttons/LargeButton'
import StopTX from '@/components/Escrows/Bitcoin/StopTX'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { LargeButton, StopTX },
    setup(){
        const { router, store } = useBoilerplate(false);
        const destination = {name: "OpenDispute", params: {id: store.state.escrow.escrow._id}};
        const goOpenDispute = () => router.push(destination)
        return { goOpenDispute, store }
    }
}
</script>

<style>

</style>