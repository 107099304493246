<template>
    
    <main>

        <div v-if="isSuccess" class = "container white successRow">
            <div class="row marginTop ">
                <Success title="Registración Efectuada con Exito" @goBack="isSuccess=false">
                    <NewUserSuccess />
                </Success>
            </div>
        </div>
        
        <div class = "container grey darken-2 marginTop authTab" v-else>
            
            <div class="row margin" id = 'tabsRow' >

                <AuthTabs />

                <Login />
                
                <Signup @newUserSuccess="isSuccess=true"/>
            
            </div>

        </div>

    </main>

</template>

<script>

import AuthTabs from '@/components/Auth/AuthTabs'
import Login from '@/components/Auth/Login'
import Signup from '@/components/Auth/Signup'
import NewUserSuccess from '@/components/Auth/NewUserSuccess'
import Success from '@/components/UI/Success'
import useBoilerplate from '@/composables/useBoilerplate';

import { ref } from '@vue/runtime-core'

export default {
    components: { AuthTabs, Login, Signup, Success, NewUserSuccess },
    setup(){

        const { isSuccess } = useBoilerplate(false);

        return { isSuccess }
    }
}
</script>

<style scoped>

    .authTab{
        margin-top: 50px;
        max-width: 360px;
        border: 1px solid grey;
    }

    .successRow{
        max-width: 500px;
    }

</style>