<template>
  <div 
    v-if="store.state.escrow.escrow.seller == store.state.alias"
  >

    <p 
      class = 'left-align grey-text text-darken-3'
      v-if="store.state.escrow.escrow.card != null"
    >
      El comprador va a realizar el pago de 
      <b class = 'orange-text'>{{ ' ' + store.state.escrow.escrow.toBePaid + ' '}}</b>
      a la tarjeta indicada abajo:
    </p>

    <div v-if="store.state.escrow.card != null">
      <SingleCard :card="store.state.escrow.card" />
    </div>
    
    <p 
      class = 'left-align grey-text text-darken-3'
      v-else
    >
      Envíe al comprador las instrucciones para pagar los Bitcoin y espere que el comprador le envíe 
      <b class = 'orange-text'>{{ store.state.escrow.escrow.toBePaid}}</b>
    </p>
    
    <p class = 'left-align grey-text text-darken-3'>
      El comprador debe realizar el pago dentro de:
    </p>
    
    <CountDown />
    
    <p class = 'left-align grey-text text-darken-3'>
      Recibido el dinero, autorize la entrega de los Bitcoin al comprador
    </p>
      
  </div>

  <div 
    v-if="store.state.escrow.escrow.buyer == store.state.alias"
  >

    <div v-if="store.state.escrow.escrow.isSigned == true">

      <p class = 'left-align grey-text text-darken-3'>
        Realize el pago de 
        <b class = 'orange-text'>{{ ' ' + store.state.escrow.escrow.toBePaid + ' '}}</b>
        <span v-if="store.state.escrow.card != null"> a la tarjeta indicada abajo:</span>
      </p>

      <div v-if="store.state.escrow.card != null">
        <SingleCard :card="store.state.escrow.card" />
      </div>

      <p class="left-align">
        <b class="red-text">SOLO DESPUES DE HABER EFECTUADO EL PAGO</b>, haga click en el boton abajo.
      </p>

      <div class = "col s12 center-align grey-text text-darken-4">  
          <Loader v-if="isLoading"/>
          <Error :warning="error" v-if="!isLoading && error!=null"/> 
      </div> 

      <div class = "col s12 center-align grey-text text-darken-4">
        <LargeButton 
          color="red" 
          icon="fa fa-check" 
          text="Pago Efectuado"
          position="left" 
          @clicked="confirmPayment"
        />
      </div>

    </div>

    <div v-else >

      <p class = 'left-align grey-text text-darken-3'>                                
        <b class = 'red-text'>Firme la Transacción, </b> 
        especificando la <b class = 'green-text'> los costos de envio (fee de mineria) </b>
        y la <b class = 'green-text'>dirección </b> 
        donde desea recibir los <i class = 'fab fa-bitcoin orange-text'></i> (puede ser de cualquier billetera).    
      </p>

      <SignTXform />

    </div>

    <p class = 'left-align grey-text text-darken-3'>
      Usted debe realizar el pago dentro de:
    </p>
    
    <CountDown />
      
  </div>
</template>

<script>

import SingleCard from '@/components/Cards/SingleCard'
import useBoilerplate from '@/composables/useBoilerplate';
import CountDown from '@/components/UI/CountDown'
import SignTXform from '@/components/Escrows/Bitcoin/SignTXform'
import LargeButton from '@/components/UI/Buttons/LargeButton';
import Loader from '@/components/UI/Loader';
import Error from '@/components/UI/Error';
import { post } from '@/composables/httpCalls';
import { socket } from '@/composables/socket'

export default {
  components: { 
    Loader, Error, CountDown, SignTXform, SingleCard, LargeButton
  },
  setup(){
    
    const { store, isLoading, error } = useBoilerplate(false);
    
    const confirmPayment = async () => {
      
      try {
        
        error.value = null;
        isLoading.value = true;
        const url = "/api/fiatSent";
        const vars = { id: store.state.escrow.escrow._id };
        const res = await post(url, vars);
        
        if(res.status == 409){
          isLoading.value = false;
          return error.value = "Usted No Esta Autorizado a Cancelar la Compraventa";
        }else if(res.status == 200){
          isLoading.value = false;
          const data = await res.json();
          store.state.escrowReplace(data.escrow);
          M.toast({html: "Pago Confirmado"});
          return socket.emit('new escrow stage', data.escrow);
        }else{
          isLoading.value = false;
          return error.value = "No Se Pudo Cancelar el Anuncio";
        }

      } catch (err) {
        console.log(err);
        error.value = "No Se Pudo Confirmar el Pago"
        isLoading.value = false;
      }

    }
    
    return { store, isLoading, error, confirmPayment };
  
  }
}
</script>
