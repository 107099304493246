<template>
    <li>
        <div class = "collapsible-header grey darken-1 white-text"><i class="fas fa-credit-card"></i><span id ="userProfile">Mis Tarjetas</span></div>
        <div class = "collapsible-body center-align grey darken-2">
            <p class = "white-text left-align">
                Especificar los datos de las tarjetas no es obligatorio, pero ayuda a agilizar el proceso de compraventa.
            </p>
            <div v-if="store.state.cards.length < 1">
                <h5 class="center orange-text" >
                    <b>No Hay Tarjetas Todavia</b>
                </h5>
            </div>

            <div v-for="card in store.state.cards" :key="card._id">
                <!--
                <SingleCard :card="card" @deleted="handleCardDelete"/>
                -->
                <SingleCard :card="card" />

            </div>
            
            <p class = "center">
                <button 
                    class = "btn green white-text margin btn-large cardBTN" 
                    @click.prevent="router.push({name: 'NewCard'})"
                >
                    <i class="fas fa-plus"></i> Tarjeta
                </button>
            </p>
            
        </div>
    </li>
</template>

<script>

import useBoilerplate from '@/composables/useBoilerplate';
import SingleCard from '@/components/Cards/SingleCard'
export default {
    //props: ['cards'],
    components: { SingleCard },
    //emits: ['deleted'],
    setup(props, context){
        const { router, store } = useBoilerplate(false);
        //const handleCardDelete = value => context.emit("deleted", value);
        return { router, store }
    }
}
</script>

<style scoped>
    .cardBTN{
        border-radius: 20px;
    }
</style>