<template>
    <div class="col s12 m6 marginTop">
        <LargeButton 
            color="light-blue"
            text="Transacciones" 
            icon="fas fa-random"
            position="left"
            @click="getTXS"
        />
    </div>
    <teleport to="body">
        <div class="modal-background" :class ="{active: isActive == true}" @click.self="handleClose">
        <div class="modal-content-bottom center black">
            
            <Error :warning="error" v-if="error!=null && isLoading==false"/>
            
            <LoadingFullPage v-if="isLoading"/>

            <div class="row">
                <div class = "col s12 center-align">
                    <h5 class="grey darken-3 orange-text title">
                       Transacciones Bitcoin
                    </h5>
                </div>
                <div class="col s12 center" v-for="tx in TXS" :key="tx.hash">
                    <SingleTX :tx="tx" />
                </div>
                <div class="col s12 center" v-if="isLoading == false && TXS.length < 1">
                    <h5 class = "red-text">0 Transacciones</h5>
                </div>

            </div>
            
            
        </div>
    </div>
    </teleport>
</template>

<script>
import Error from '@/components/UI/Error';
import LoadingFullPage from '@/components/UI/LoadingFullPage';
import LargeButton from '@/components/UI/Buttons/LargeButton';
import useBoilerplate from '@/composables/useBoilerplate';
import SingleTX from './SingleTX';
import { ref } from '@vue/runtime-core';

export default {
    props: ['address', 'xChange'],
    components: { LargeButton, Error, LoadingFullPage, SingleTX },
    emits: ['listed'],
    setup(props, context){

        const { isLoading, error, isActive } = useBoilerplate(false);

        const TXS = ref([]);

        const handleClose = () => {
            error.value = null;
            isLoading.value = false;
            isActive.value = false;
            TXS.value = [];
        }

        const getTXS = async () => {

            error.value = null;
            isLoading.value = true;
            isActive.value = true;

            try {
                const address = sessionStorage.getItem('address');
                const url = `/api/TXS/${address}`;
                const res = await fetch(url);
                if(res.status == 200){
                    const data = await res.json();
                    TXS.value = data.TXS;
                    const { balanceBTC, balanceUSD, rates } = data;
                    const obj = { balanceBTC, balanceUSD, rates };
                    isLoading.value = false;
                    return context.emit('listed', obj);
                }else if(res.status == 412){
                    store.state.logUserOut();
                    router.push({name: 'Auth'});
                }else{
                    isLoading.value = false;
                    return error.value = "No Se Pudieron Cargar las Transacciones";
                }
            } catch (err) {
                console.log(err);
                error.value = "Error";
                isLoading.value = false;
            }
        }
         
        return { isLoading, error, isActive, TXS, getTXS, handleClose }
    }
}
</script>

<style scoped>
    .title{
        padding: 10px !important
    }
</style>