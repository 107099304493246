<template>
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
        <div class="row modal-content center white">
            
            <div class = 'col s12 center-align'>
                <h2 class = 'red white-text errorIcon'><i class = 'fas fa-exclamation-circle'></i></h2>
            </div>

            <div class = 'col s12 grey-text text-darken-2 center-align'>
                <h5>{{ warning }}</h5>
            </div>
            <slot></slot>
            <div class = 'col s12 margin center-align'>
                <a href = '#' class = 'btn red white-text' @click="closeModal">
                    <i class ='far fa-window-close'></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
    props: ['warning', 'isActive'],
    emits: ['close'],
    
    setup(props, context){

        const closeModal = () => {
            context.emit('close');
        }

        return { closeModal };
    }
}
</script>