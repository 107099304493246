<template>
    
    <div class="row grey darken-3 pad20" >
        
        <div class="col s12 l3 center-align indigo z-depth-1">
            <h3 class="white-text">Usuarios</h3>
            <i class="fas fa-user white-text text70" ></i>
            <h2 class="white-text statValue" id = 'totUsers'>{{ stats.users}}</h2>
        </div>
        
        <div class="col s12 l3 center-align light-green z-depth-1">
            <h3 class="white-text">Volumen</h3>
            <i class="fas fa-dollar-sign white-text text70" ></i>
            <h2 class="white-text statValue" id = 'totVolume'>{{ stats.volume }}</h2>
        </div>
        
        <div class="col s12 l3 center-align orange z-depth-1">
            <h3 class="white-text">Volumen</h3>
            <i class="fab fa-btc white-text text70" ></i>
            <h2 class="white-text statValue" id = 'totVolumeBTC'>{{ stats.volumeBTC }}</h2>
        </div>
        
        <div class="col s12 l3 center-align red z-depth-1">
            <h3 class="white-text">Compraventas</h3>
            <i class="fas fa-random white-text text70" ></i>
            <h2 class="white-text statValue" id = 'totEscrows'>{{ stats.escrows}}</h2>
        </div>
    
    </div>

</template>

<script>
import { onMounted, reactive } from '@vue/runtime-core'
export default {
    setup(){

        let stats = reactive({});
        
        onMounted(async () => {
            const url = '/api/public/getStats';
            const res = await fetch(url, {cache: "no-cache"});
            const data = await res.json();
            stats.escrows = data.escrows;
            stats.volume = data.volume;
            stats.volumeBTC = data.volumeBTC;
            stats.users = data.users;
        })

        return { stats };
    }
}
</script>

<style scoped>
    .statValue{
        margin-left: 50px; 
        margin-right: 50px;
    }
    .pad20{
        padding-bottom: 20px;
    }
    .text70{
        font-size: 70px;
    }
</style>