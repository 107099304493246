<template>
    <li>
        <div class = "collapsible-header white-text grey"><i class="fas fa-user-shield"></i><span id = "twoFALocalization"> 2FA</span></div>
        <div class = "collapsible-body grey darken-2">
            <label for="faCheck">
                <input type="checkbox" :checked="isChecked" ref="switch2FA" id="faCheck" @change="handleChange">
                <span class = "white-text">2FA</span>
            </label>
            <p class = "white-text">Seleccione esta opción para incrementar su seguridad. Cada vez que Usted ejeute el log-in, el sistema le enviará un código de seguridad a su correo electrónico</p>
            <Loader v-if="isLoading"/>
            <Error v-if="error && isLoading==false" :warning="error"/>
        </div>
    </li>
</template>

<script>
import Loader from '@/components/UI/Loader'
import Error from '@/components/UI/Error'
import { ref } from '@vue/reactivity'
import { post } from '@/composables/httpCalls'
import { computed } from '@vue/runtime-core'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { Loader, Error },
    props: ['twoFA'],
    
    setup(props){
        
        const { store, router, error, isLoading } = useBoilerplate(false);
        
        const switch2FA = ref(null);
        const isChecked = computed(() => props.twoFA == 'checked' ? true : false);
        
        const handleChange = async () => {

            try {

                isLoading.value = true;
                error.value = null;

                const twoFactorsAuth = switch2FA.value.checked == true ? "checked" : "";
                
                const url = "/api/change2FA"
                const vars = { twoFactorsAuth }

                const res = await post(url, vars);

                if(res.status == 200){
                    const html = switch2FA.value.checked == true ? "2FA Activado" : "2FA Deactivado";
                    M.toast({ html });
                    isLoading.value = false;
                }else if(res.status == 412){
                    store.state.logUserOut();
                    return router.push({name: 'Auth'})
                }else{
                    isLoading.value = false;
                    const errorMSG = "No Se Pudo Actualizar el 2FA";
                    return error.value=errorMSG;
                }
            } catch (err) {
                console.log(err);
                isLoading.value = false;
                error.value = "Error de Conexion"
            }

        }

        return { error, isLoading, switch2FA, handleChange, isChecked }
    }
}
</script>