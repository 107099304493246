<template>
    <MenuButton isMob="false" target="Home" text="Inicio" icon="fas fa-globe-americas"/>
    <MenuButton isMob="false" target="Auth" text="Entrar" icon="fas fa-user"/>
    <MenuButton isMob="false" target="Tutorials" text="Tutoriales" icon="fas fa-graduation-cap"/>
    <MenuButton isMob="false" target="FAQ" text="Preguntas" icon="fas fa-question"/>
    <MenuButton isMob="false" target="Stats" text="Datos" icon="far fa-chart-bar"/>

    <MenuButton isMob="false" target="ContactUs" text="Contactenos" icon="fas fa-envelope"/>
</template>

<script>

import MenuButton from '@/components/UI/Buttons/MenuButton'
import { ref } from '@vue/reactivity'

export default {
    components: { MenuButton },
    setup(){
        const isMob = ref(false);
        return { isMob };
    }
}
</script>

<style>

</style>