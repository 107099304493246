//SOCKET PRODUCTION
export const socket = io();

export const connectME = (alias) => {    
    socket.emit('connect me', alias);
}

export const disconnectME = (alias) => {
    socket.emit('log me out', alias);
}

