<template>
    <li>
        <div class = "collapsible-header grey white-text"><i class="fas fa-exclamation-triangle"></i> Resetear Monedero</div>
        <div class = "collapsible-body grey darken-2">
            
            <p class = 'left-align white-text'>Reseateando las Claves, Usted va a tener la posibilidad de cambiar la semilla. Esta operacion NO va a borrar los datos de su cuenta.</p>
            
            <Error warning="Utilize esta Funcionalidad con sumo cuidado" />
            
            <form @submit.prevent="reset">
                
                <div class = "input-field marginBottom">
                    <label for="resetAgree">
                        <input type="checkbox" id="resetAgree" @change="isDisabled = !isDisabled">
                        <span class = 'white-text'>Si, quiero seguir</span>
                    </label>
                </div>
                
                <br>
                <p class = "center">
                    <button id='resetButton' ref="resetBTN" class = "btn red white-text margin" :class="isDisabled ? 'disabled' : null">
                        <i class="fas fa-check"></i>
                    </button>
                </p>
                
            </form>
            
        </div>
    </li>
</template>

<script>
import Error from '@/components/UI/Error'
import { onMounted, ref } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
export default {
    
    props: ['alias'],
    components: { Error },
    
    setup(props){
        
        const router = useRouter();
        
        const isDisabled = ref(true);
        
        const reset = () => {
            localStorage.setItem(props.alias, '');
            router.push({name: 'WalletRecover'})
        }

        const toggleClass = () => {
            resetBTN.classList.toggle('disabled');
        }
        
        onMounted(() => M.AutoInit());

        return { reset, isDisabled };
    }
}
</script>

<style scoped>
    #resetButton{
        position:relative;
        top: 20px
    }
</style>