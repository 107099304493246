<template>
    <div class="row user-profile grey lighten-1" >
        
        <div class = "col s12 center-align">
            <h5 class="grey darken-3 white-text">
                 {{ user.alias }} 
            </h5>
            <h6 v-if="store.state.alias == 'QBita'" >
                <b>{{ user.email }}</b>
            </h6>
        </div>
        
        <div class = "col s12 center-align" style = "position: relative">
            <img 
                :src="picture"
                :class="{
                    isOnline: store.state.connectedUsers[user.alias] ||
                    vips.indexOf(user.alias) >= 0
                }"
            >
            <Grade :level="user.grade"/>
        </div>

        <LastOnline 
            v-if="!store.state.connectedUsers[user.alias] && vips.indexOf(user.alias) < 0"
            :time="user.lastOnline"
        />

        <LargeButton 
            color="green" 
            icon="fas fa-comment-dots" 
            text="Contacta" 
            position="left" 
            @clicked="goChat(user.alias, store.state.alias, router)"
        />

        <LargeButton 
            v-if="isBlacklisted==false && store.state.alias == 'QBita'"
            color="orange" 
            icon="fas fas fa-ban" 
            text="Bloquear" 
            position="left" 
            @clicked="blacklist(user.alias)"
        />

        <LargeButton 
            v-if="isBlacklisted==true && store.state.alias == 'QBita'"
            color="blue" 
            icon="fas fa-check" 
            text="Desbloquear" 
            position="left" 
            @clicked="whitelist(user.alias)"
        />

        <LargeButton 
            v-if="store.state.alias == 'QBita'"
            color="red" 
            icon="fas fa-trash" 
            text="Eliminar" 
            position="left" 
            @clicked="deleteUser(user.alias)"
        />

        <div class = "col s12 center-align" v-if="user.alias==store.state.alias || store.state.alias=='QBita'">
            <h6 class="grey-text text-darken-4" v-if="store.state.alias=='QBita'"><b>Saldo BTC: </b> {{ user.walletBalance}} $</h6>
            <h6 class="grey-text text-darken-4"><b>Volume USD: </b> {{ user.volumeUSD}} $</h6>
            <h6 class="grey-text text-darken-4"><b>Volume BTC: </b> {{ user.volumeBTC}} BTC</h6>
        </div>

        <Stats 
            :score="user.score" 
            :successRate="successRate" 
            :reviews="user.reviews"
        />

        <div class = "col s12 center-align">
            <h6 class="grey-text text-darken-3">
                 <b class = "indigo-text text-darken-2"><i class = 'fab fa-facebook'></i> Facebook:</b> 
                 <a :href="user.fb" target="_blank" v-if="user.fb!='#'"> Visitar Perfil</a> 
                 <span v-else> No Disponible</span> 
            </h6>
        </div>

        <div class = "col s12 center-align">
            <h6 class="grey-text text-darken-3">
                 <b class = "green-text text-darken-2"><i class = 'fab fa-whatsapp'></i> Whatsapp:</b> {{ whatsapp }} 
            </h6>
        </div>
        
        <div class = "col s12 center-align orange white-text" style = "margin-top: 20px">
            <h5>
                  Reseñas: {{ user.textReviews.length }} 
            </h5>
        </div>

        <div v-for="review in user.textReviews" :key="review" 
            class = "aReview col s12 center-align white grey-text text-darken-3" 
        >
            <SingleReview :review="review" />
        </div>

        <!--Show Chats and TXs if the user making the call is QBita-->
        <div v-if="store.state.alias == 'QBita'">
            <div 
                class = "col s12 center-align orange white-text" 
                style = "margin-top: 20px"
            >
                <h5>Chats </h5>
            </div>

            <div v-for="chat in user.chats" :key="chat._id" >
                <router-link 
                    :to="{ name: 'Chat', params: {room: chat._id} }"
                    class="blue-text"
                >
                    {{ chat.roomClear }}
                </router-link>
            </div>

            <div 
                class = "col s12 center-align orange white-text" 
                style = "margin-top: 20px"
            >
                <h5>Escrows </h5>
            </div>

            <div v-for="escrow in user.compraventasList" :key="escrow._id" >
                <SingleEscrowAdmin :escrow="escrow" />
            </div>

            <div 
                class = "col s12 center-align orange white-text" 
                style = "margin-top: 20px"
            >
                <h5>IPS: </h5>
            </div>

            <div v-for="ip in user.ips" :key="ip._id" >
                <SingleIP :ip="ip" />
            </div>

        </div>
        

    </div>
</template>

<script>
import Grade from '@/components/Reputation/Grade'
import LastOnline from '@/components/Reputation/LastOnline'
import Stats from '@/components/Reputation/Stats'
import SingleIP from '@/components/Reputation/SingleIP'
import SingleReview from '@/components/Reputation/SingleReview'
import LargeButton from '@/components/UI/Buttons/LargeButton'
import SingleEscrowAdmin from '@/components/Escrows/SingleEscrowAdmin'
import { post } from '@/composables/httpCalls'
import { vips } from '@/composables/vips'
import { computed, onMounted, ref, watch } from '@vue/runtime-core'
import { goChat } from '@/composables/goChat'
import useBoilerplate from '@/composables/useBoilerplate';
import { formatPicUrl } from '@/composables/pictures'

export default {
    
    props: ['user'],
    components: { 
        Grade, Stats, LargeButton, LastOnline,
        SingleReview, SingleEscrowAdmin, SingleIP 
    },
    emits: ['deleteUser'],

    setup(props, context){
        
        const { store, router } = useBoilerplate(false);

        const isBlacklisted = ref(false);
        const userToBeDeleted = ref(null);
        const picture = computed(() => formatPicUrl(props.user.picture))

        const successRate = computed(() => {
            return props.user.completed + ' de ' + props.user.compraventas;
        })

        const whatsapp = computed(() => {
            return props.user.wa == '' ? 'No Disponible' : props.user.wa;
        })

        const fbLink = computed(() => {
            return props.user.fb == '#' ? 'No Disponible' : 'Ver Perfil';
        })

        const blacklist = async (alias) => {
            const url = "/api/blacklist";
            const vars = {alias: alias};
            try {
                const res = await post(url, vars);
                if(res.status == 200){
                    M.toast({html: "Agregado a la Blacklist"});
                    return isBlacklisted.value = !isBlacklisted.value;
                }else{
                    M.toast({html: "Blacklist Error"})
                }
            } catch (error) {
                console.log(error);
                M.toast({html: "Blacklist Error"})
            }
        }

        const whitelist = async (alias) => {
            const url = "/api/whitelist";
            const vars = {alias: alias};
            try {
                const res = await post(url, vars);
                if(res.status == 200){
                    M.toast({html: "Agregado a la Whitelist"});
                    return isBlacklisted.value = !isBlacklisted.value;
                }else{
                    M.toast({html: "Whitelist Error"})
                }
            } catch (error) {
                console.log(error);
                M.toast({html: "Whitelist Error"})
            }
        }

        const deleteUser = () => {
            context.emit('deleteUser');
        }

        watch(() => props.user, () => console.log(props.user));

        onMounted(() => isBlacklisted.value = props.user.isBlacklisted);

        return { router, picture, goChat, successRate, whatsapp, fbLink, vips,
                 store, isBlacklisted, blacklist, whitelist, userToBeDeleted, deleteUser };
    }
}
</script>
<style scoped>
    h5{
        padding: 5px;
        border-radius: 10px;
    }
    img{
        border-radius: 50%;
        height: 100px;
        width: 100px;
        margin: 20px auto;
    }
    .user-profile{
        max-width: 500px; 
        padding-bottom: 20px;
        margin-bottom: 0px;
    }
    .aReview{
        margin-top: 20px;
    }
    .blue-text{
        display: block;
        margin-top: 20px !important;
    }

    .isOnline{
        border: 5px solid yellowgreen;
    }

</style>

<style>

</style>