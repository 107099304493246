export const setPic = (picture) =>{ 
    
    const photo = picture.value.files[0];
    
    if(!photo){
        return {
            status: false,
            isLoading: false,
            error: "Necesario Seleccionar una Foto"
        }
    }

    const maxSize = 10 * 1024 * 1024;
    
    if(maxSize < photo.size){
        return {
            status: false,
            isLoading: false,
            error: "MAX 10 MB"
        }
    }

    if(photo.type == "image/jpeg" || photo.type == "image/png"){      
        const reader = new FileReader();  
        reader.onload = function(readerEvent) {
            const image = new Image();
            image.onload = function(imageEvent) { 
    
                var max_size = 600;
                var w = image.width;
                var h = image.height;
                    
                if(w > h){  if (w > max_size) { h*=max_size/w; w=max_size; }
                }else{  if (h > max_size) { w*=max_size/h; h=max_size; } }
            
                const canvas = document.querySelector('canvas');
                canvas.width = w;
                canvas.height = h;
                canvas.getContext('2d').drawImage(image, 0, 0, w, h);
                let dataURL;
                if(photo.type == "image/jpeg" || photo.type == "image/jpg" ) {
                    dataURL = canvas.toDataURL("image/JPG", 1.0);
                }else{
                    dataURL = canvas.toDataURL("image/png");    
                }
    
            }
            image.src = readerEvent.target.result;
        }
    
        const myReader = reader.readAsDataURL(photo);
        
        return {
            status: true,
            isLoading: true,
            error: null
        }
    
    }else{
        return {
            status: false,
            isLoading: false,
            error: "Solo Fotos en Formato PNG y JPG"
        }
        
    }
}

export const formatPicUrl = url => {

    if(!url){
        return
    }
    
    const urlSplit = url.includes('../assets') ? url.split('../assets') : url.split('..');

    return "https://qbita.org" + urlSplit[1];
    
}