<template>
    <div class = "grey darken-3 form-container">
    <form>
        
        <div class = 'input-field' v-if="isManualAddress==false">
            <input type = 'text' id='address' v-model="address" required>
            <label for = 'address' class ='orange-text'><i class='fas fa-wallet'></i> Dirección</label>
        </div>

        <h6 class = "left-align" v-else>
            <span class="orange-text">
                <i class='fas fa-wallet'></i> Dirección
            </span><br><br>
            <span class = "light-green-text">{{cutKey(address, 4)}}</span>
        </h6>

        <p class = "white-text" v-if="isManualAddress==false" @click="toggleManualAddress">
            Recibir al Monedero QBita
        </p>
        <p class = "white-text" v-else @click="toggleManualAddress">
            Recibir a otra Dirección
        </p>

        <br>
        
        <div class = "input-field col s12" v-if="isManual==true">
            <input type = "text" id="manualFee" v-model="miningFee" >
            <label for = "manualFee" class = 'grey-text'><i class="fas fa-price-tag"></i> <span>Costos de Envio (sats/byte):</span></label>
            <p class = "orange-text" @click="handleManual">Selección Automatica</p>
        </div>

        <div class="input-field col s12 margin30" v-else>
            <Select @changed="handleFee" >
              <option :value="store.state.escrow.fees[0].sats">Seleccionar Costos de Envio (mineria)</option>
              <option :value="store.state.escrow.fees[0].sats">{{store.state.escrow.fees[0].label}} - {{store.state.escrow.fees[0].sats}} sats/byte</option>
              <option :value="store.state.escrow.fees[1].sats">{{store.state.escrow.fees[1].label}} - {{store.state.escrow.fees[1].sats}} sats/byte</option>
              <option :value="store.state.escrow.fees[1].sats * 2">{{store.state.escrow.fees[2].label}} - {{store.state.escrow.fees[1].sats * 2}} sats/byte</option>
            </Select>
            <p class = "orange-text" @click="handleManual">Selección Manual</p>
        </div>

        <div class = "center-align margin">
            <label for="isRBF">
                <input type="checkbox" id = "isRBF" :checked="isRBF" @click="isRBF=!isRBF">
                <span class = "white-text">Replace By Fee <i class='fas fa-bolt orange-text'></i></span>
            </label>
        </div>

        <div class = "center-align margin">
            <Error :warning="error" v-if="error"/>
            <Loader v-if="isLoading" />
            
            <LargeButton 
                color="green" 
                icon="fab fa-bitcoin" 
                text="Firma" 
                position="left"
                @clicked="setCoinsDestination"
            />

        </div>
        
    </form>
    </div>

    <teleport to="body">
        
        <SignatureWarningModal
            :signedTX="signedTX"
            :hex="hex"
            :address="address"
            :miningFee="miningFee"
            :fiatString="store.state.escrow.escrow.toBePaid"
            :isActive="isActive" 
            @close="isActive=false"
        />

    </teleport>

</template>

<script>
import Select from '@/components/UI/Select';
import Loader from '@/components/UI/Loader';
import Error from '@/components/UI/Error';
import LargeButton from '@/components/UI/Buttons/LargeButton'
import SignatureWarningModal from '@/components/modals/SignatureWarningModal'
import { computed, onMounted, ref, watch } from '@vue/runtime-core'
import { post } from '@/composables/httpCalls'
import { cutKey } from '@/composables/helpers'
import useBoilerplate from '@/composables/useBoilerplate';

export default {

    components: { LargeButton, Error, Loader, SignatureWarningModal, Select },

    setup(){

        const {store, isLoading, error, isActive} = useBoilerplate(false);

        const address = ref('');
        const miningFee = ref(5);
        const isRBF = ref(false);
        const isManualAddress = ref(false);
        const signedTX = ref(null);
        const hex = ref(null);
        const btcXrate = ref(0);
        const isManual = ref(false);

        const handleManual = () => {
            isManual.value = !isManual.value;
            if(isManual.value == true){
            setTimeout(() => {
              document.getElementById("manualFee").focus();
            }, 200);
          }
        }

        const handleFee = value => miningFee.value = value;

        //It fixes Slider Bug
        watch(()=>miningFee.value, ()=> isActive.value = false);

        const toggleManualAddress = () => {
            isManualAddress.value = !isManualAddress.value;
            if(isManualAddress.value == true){
                address.value = sessionStorage.getItem('address');
            }else{
                address.value = '';
                setTimeout(() => {
                    document.getElementById("address").blur();
                }, 200)
            }
        }

        const setCoinsDestination = async () => {

            try{

                isActive.value = false;
                error.value = null;
                hex.value = null;
                signedTX.value = null;

                if((address.value.length < 10) || (address.value[0] != '1' && address.value[0] != '3' && address.value[0] != 'b')){
                    return error.value ='Ponga una dirección Bitcoin valida';
                }

                if(miningFee.value < 2 || isNaN(miningFee.value)){
                    return error.value ='La Fee de Mineria No Es Valida (Min: 2 Sat/Byte)';
                }

                isLoading.value = true;

                console.log("FEE: ", miningFee.value);
                
                const vars = {
                    escrow: store.state.escrow.escrow._id,
                    address: address.value,
                    miningFee: Number(miningFee.value),
                    isRBF: isRBF.value
                }
                
                const url = '/api/buildPSBT';
                const res = await post(url, vars);

                if(res.status === 500){
                    isLoading.value = false;
                    return error.value = "Error!"
                }

                if(res.status === 409){
                    isLoading.value = false;
                    return error.value='La Dirección no es valida';
                }

                if(res.status == 401){
                    isLoading.value = false;
                    return error.value = 'Usted No está autorizado. Vuelva a Loguearse de Nuevo.';
                }

                if(res.status == 404){
                    isLoading.value = false;
                    return error.value = 'Es Necesario Bajar los Costos de Envio (Mineria)';
                }

                if(res.status == 400){
                    isLoading.value = false;
                    return error.value = 'No Se Pudo Firmar la Transacción';
                }

                if(res.status == 200){

                    isLoading.value = false;
                    const myResponse = await res.json();

                    if(store.state.escrow.escrow.stage == "BTC depositados en Escrow multiSig"){
                        signedTX.value = window.firstSignature(myResponse.psbt, myResponse.encKey);
                        if(signedTX.value != false && signedTX.value != 'keyError') return isActive.value = true;
                        if(signedTX.value == false) return error.value = 'La Clave de Cifrado No Es Correcta';
                        if(signedTX.value == 'keyError') return error.value = 'La Semilla No Es Correcta';
                    }else{
                        hex.value = window.finalSignature(myResponse.psbt, myResponse.encKey, 101);
                        if(hex.value != false && hex.value != 'keyError') return isActive.value = true;
                        if(hex.value == false) return error.value = 'La Clave de Cifrado No Es Correcta';
                        if(hex.value == 'keyError') return error.value = 'La Semilla No Es Correcta';
                    }

                }

            }catch(err){
                error.value = "Error de Conextion"
                isLoading.value = false;
            }

        }

        onMounted(() => btcXrate.value = JSON.parse(sessionStorage.xRates).BTC);

        return { 
            error, isLoading, isActive, handleFee,
            isRBF, isManualAddress, toggleManualAddress, miningFee, address,
            store, setCoinsDestination,
            signedTX, hex, btcXrate, cutKey, isManual, handleManual
        };
    }
}

</script>

<style scoped>
    .form-container{
        padding: 25px 15px;
        width: 100%;
    }
</style>