export const vips = [
    'Manzana', 
    'Einstein',
    '813corporation',
    '517corp',
    'lino',
    'panthera',
    'BlackPearl',
    'BlackKnight',
    'TwoKeys',
    'Lightman',
    'Valzu',
    'Tyrian',
    'Blin',
    'Moku'
];
