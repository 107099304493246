import { post } from '@/composables/httpCalls'
import { ref } from 'vue';

const useNewCard = (error, isLoading, isSuccess, router) => {

    const phone = ref('');
    const cardNumber = ref('');
    const divisa = ref('');

    const handleDivisa = value => divisa.value = value;
    
    const addCard = async () => {
        
        try {

            isSuccess.value = false;
            error.value = null;
            isLoading.value = true;

            const url = "/api/newCard";
            const vars = {
                phone: phone.value.replace(/ /gi, ""),
                cardNumber: cardNumber.value.replace(/ /gi, ""),
                currency: divisa.value
            }

            const res = await post(url, vars);

            if(res.status == 200){
                isLoading.value = false;
                return isSuccess.value = true;
            } else if(res.status == 409){
                const data = await res.json();
                error.value = data.message;
                return isLoading.value = false;
            } else if(res.status == 412) {
                return router.push({name: "Auth"})
            } else if(res.status == 403) {
                return router.push({name: "Blocked"})
            } else if(res.status == 401) {
                return router.push({name: "TwoFA"})
            } else {
                error.value = `Error ${res.status}.`;
            }

        } catch (err) {
            console.log(err);
            error.value = "No Se Pudo Agregar la Tarjeta";
            isLoading.value = false;
        }
    }

    return { phone, cardNumber, handleDivisa, addCard }
}

export default useNewCard;