<template>
    <div class = "col s4 center-align grey darken-2">
        <img 
            :src="url" 
            class = "circle responsive-img" 
            :class="{
                isOnline: store.state.connectedUsers[alias] || alias == store.state.alias || vips.indexOf(alias) >= 0
            }"
        >
        <p v-if="vips.indexOf(alias) >= 0" @click.stop="isActive=true">
            <i class="fas fa-star yellow-text"></i>
        </p>

        <p 
            class="light-green-text"
            v-if="store.state.connectedUsers[alias] || alias == store.state.alias || vips.indexOf(alias) >= 0"
        >
            ONLINE
        </p>
        <p 
            class="grey-text text-lighten-1"
            v-else
        >
            {{timeAgo}}
        </p>
        
        <h6 class = "white-text truncate" :class="{infinity : index == store.state.infinity}">
            {{ alias }}
        </h6>
        <br>
        <slot></slot>
    </div>

    <teleport to="body">
        <EmptyModal :isActive="isActive" @close="isActive=false">
        <div class = 'col s12 center-align'>
            <h2 class = 'light-green white-text errorIcon'><i class = 'fas fa-exclamation-circle'></i></h2>
            </div>
            <div class="col s12 left-align">
            <h6>Este Usuario es un provedor de liquidez.</h6>
            <h6>Por lo tanto, se van a aplicar una estructura de fee especial. El comprador va a pagar:</h6>
            <p>
                <ul class="browser-default">
                <li>Comision de 0.85% </li>
                <li>Un Fijo de 2.50 $</li>
                </ul>
            </p>
            <p>
                Por favor revise las <router-link to="/FAQ">Preguntas Frecuentes</router-link> para profundizar el tema de los fee.
            </p>
            <br>
            
            </div>
            
            <p>
             <LargeButton color="light-green" icon="fa fa-window-close" text="Cerrar" position="left" @clicked="isActive=false"/>
            </p> 
        </EmptyModal>
    </teleport>

</template>

<script>
import { computed } from '@vue/runtime-core';
import  { vips } from '@/composables/vips'
import { timeDiff } from '@/composables/time'
import useBoilerplate from '@/composables/useBoilerplate';
import EmptyModal from '@/components/modals/EmptyModal'
import LargeButton from '@/components/UI/Buttons/LargeButton'
export default {
    props: ['alias', 'url', 'index', 'lastOnline'],
    components: { EmptyModal, LargeButton },
    setup(props){
        const {store, isActive} = useBoilerplate(false);
        const timeAgo = computed(() => props.lastOnline && props.lastOnline != "OFFLINE" ? timeDiff(props.lastOnline) : "OFFLINE")
        return { store, vips, timeAgo, isActive };
    }
}
</script>

<style scoped>
    .circle{
        width: 70px; 
        height: 70px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .isOnline{
        border: 5px solid yellowgreen;
    }

    .s4{
        position: relative;
    }

    .fa-star{
        font-size: 30px;
        position: absolute;
        top: 70px;
        
    }

</style>