import { post } from './httpCalls';

export const broadcast = async (tx, escrow) => {
    const url = "https://qbita.org/FullNode/push"
    const vars = { tx, escrow };
    
    try {
        const res = await post(url, vars);
        if(res.status == 200){
            return {status: true, error: null};
        }else{
            return {status: false, error: "No Se Pudo Efectuar la Propagación"}
        }
    } catch (error) {
        console.log(error);
        return {status: false, error: "Error de Conexión"}
    }
}