import { post } from './httpCalls';
export const appPubKey = 'BJakPGmP9_8hkD-JocEms6l0N2o10alhni4i01rRr3nZPDQZ7nGAcM7BoaMasJ1sRygGCDYeLJUCeTYbS-Nze6E';

let swRegistration;

const getSW = async () => {
  if ('serviceWorker' in navigator) {
    try{
      const registration = await navigator.serviceWorker.register('/service-worker.js')
      return registration;
    }catch(error) {
      console.log('Service worker registration failed, error:', error);
    }
  }
}

export const pushUnsub = async (alias) => {
    
    swRegistration = await getSW();

    const queryObj = {alias};

    fetch('/api/unsubPush', {
      method: 'POST',
      body: JSON.stringify(queryObj),
      headers: {
        'content-type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      swRegistration.pushManager.getSubscription()
      .then(function(subscription) {
        if (subscription) {
          localStorage.setItem('endP', '');
          const success = 'Notificaciones Push No Activas';
          M.toast({html: success});
          closeBTC('pushTipModal');
          return subscription.unsubscribe();
        }
      })
      .catch(function(error) {
        console.log('Error unsubscribing', error);
      })
    })
    .catch(error => {
      console.log(error);
      const failed = 'Notificaciones Todavia Activas';
      M.toast({html: failed});
    })
        
}

export const pushSub = async () =>{
    
    swRegistration = await getSW();
    
    const applicationServerKey = urlBase64ToUint8Array(appPubKey);
    swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey
    })
    .then(function(subscription) {
      
      //Now that user is subscribed send DATA TO DB
      return fetch('/api/subPush', {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: {
          'content-type': 'application/json'
        }
      })
    })
    .then(response=>{
      return response.json();
    })
    .then(data =>{
      localStorage.setItem('endP', data.endpoint);
      const success = 'Notificaciones Push Activas';
      M.toast({html: success});
      
    })
    .catch(function(err) {
      console.log('Failed to subscribe the user: ', err);
      const failed = 'Notificaciones Push NO Activas';
      M.toast({html: failed});
    });

  }

//Encodes Public Key
function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}