<template>
    <router-link :to="{name: 'Escrow', params: {id: escrow._id}}">
        <div 
            class = "col s12 z-depth-3 marginTop infinityCard white" 
        >
            <h6 class = "orange white-text topID"><b>#ID: {{ escrow._id}} </b></h6>
            <h6 class = "grey-text text-darken-2"><b class = "orange-text">Seller:</b> {{ escrow.seller }} </h6>
            <h6 class = "grey-text text-darken-2"><b class = "orange-text">Buyer:</b> {{ escrow.buyer }} </h6>
            <h6 class = "grey-text text-darken-2"><b class = "orange-text">Monto:</b> {{ escrow.fiat }} </h6>
            <h6 class = "grey-text text-darken-2"><b class = "orange-text">Stage:</b> {{ escrow.stage }} </h6>

        </div>
    </router-link>
    
</template>

<script>


export default {
    props: ['escrow'],
}
</script>

<style scoped>
    .topID{
        padding: 5px;
    }
</style>