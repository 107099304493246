<template>
    <div class="container">
        <div class="row hide maxDiv" id = "adsPreloader">
            <div class="col s12 center">
                <h5 class="orange-text left-align">Buscando Mas Anuncios...</h5>
            </div>
            <div class="progress">
                <div class="indeterminate"></div>
            </div>
        </div>
    </div>
    <footer class = "page-footer transparent white-text hide-on-med-and-down" id = "myFooter">
        <div class="row">
        <div class="col s12  center-align">
            <p>&copy; QBita 2021 (v6.0)</p>
        </div>
        </div>
        <div class="row modal bottom-sheet black" id = "reputationModal"></div>
    </footer>
</template>