import { useRoute, useRouter } from "vue-router";
const { ref, inject } = require("vue");

const useBoilerplate = (loading) => {
    
    const isLoading = ref(loading);
    const error = ref(null);
    const isActive = ref(null);
    const isActiveReputation = ref(null);
    const isSuccess = ref(false);
    const router = useRouter();
    const route = useRoute();
    const store = inject('store');
    
    return { isLoading, error, isActive, isSuccess, router, route, store, isActiveReputation };
}

export default useBoilerplate;