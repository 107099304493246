import { post } from './httpCalls'
import { formatDistanceToNow } from 'date-fns'
import { es } from 'date-fns/locale'

export const timeDiff = (ts) => {
    return formatDistanceToNow(ts, {locale: es});
}

//Count down for FIAT

export const getMSleft= async (id) => {
    
        const url='/api/count-down';
        const vars = {id};
        const res = await post(url, vars)
        const data = await res.json();

        return data.msLeft;
}

export const turnTimeIntoString = totMS => {
        const oneH = 1000 * 60 * 60;
        const oneM = 1000 * 60;
        //totMS = totMS - 1000;
        const h = totMS / oneH;
        const hReminder = totMS % oneH;
        const m = hReminder / oneM;
        const mReminder = hReminder % oneM;
        const s = mReminder / 1000;
        const timeLeftString = Math.floor(h) + " h : " + Math.floor(m) + " m : " + s.toFixed(0) + " s";
        return timeLeftString;
}
  
export const finishCountDown = async (id) =>{
    try{
        const url = '/api/expire';
        const vars = { id };
        await post(url, vars);
    }catch(error){
        console.log(error);
    }
   
}