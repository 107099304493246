<template>
  <div class="col s12 marginTop" id = "logInTab">
    <form @submit.prevent="logMeIn">
      
      <div class = "input-field col s12">
        <input type = "email" v-model="user" required>
        <label for = "user" class ='grey-text'><i class="far fa-envelope"></i> E-Mail</label>
      </div>
      
      <div class = "input-field col s12">
        <input type = "password" v-model="password" required>
        <label for = "passW" class='grey-text'><i class="fas fa-key"></i> <span id='pwLabel'>Contraseña</span></label>
      </div>
      
      <Loader v-if="isLoading" />
      
      <div class="col s12 center-align">
        <button class = "btn white-text orange marginTop" id = "loginTrigger" ><i class = "fas fa-check"></i></button>
      </div>
    
    </form>

    <div class="col s12 center marginTop">
      <router-link :to="{name: 'RecoverPassword'}" class = "white-text">
        Contraseña Olvidada?
      </router-link>
      
    </div>

  </div>

  <teleport to="body">
    <ErrorModal :isActive="isActive" :warning="error" @close="isActive=false">
      <div v-if="isOldVersion">
        <h6 class = "red-text">{{ version }}</h6>
        <h6 class = "grey-text text-darken-2">No Hay Que Descargar Nada. <b>Es Suficiente Abrir y Cerrar Todo</b> (App y Navegador) un Par de Veces</h6>
      </div>
    </ErrorModal>
  </teleport>
    
</template>

<script>

import Loader from '@/components/UI/Loader';
import ErrorModal from '@/components/modals/ErrorModal';
import { ref } from '@vue/reactivity';
import useBoilerplate from '@/composables/useBoilerplate';
import { post } from '@/composables/httpCalls';

export default {
    components: { Loader, ErrorModal },
    setup(){

        const { isLoading, error, router, store, isActive } = useBoilerplate(false);
        
        const user = ref('');
        const password = ref('');
        const isOldVersion = ref(false);
        const version = ref('');

        const logMeIn = async () => {
            
          isLoading.value = true;
          
          const url = "/api/loginV6";
          
          const vars = {
            username: user.value.toLowerCase().replace(/ /gi, ""),
            password: password.value,
            type: 'new'
          }

          try{

            const res = await post(url, vars);
            
            if(res.status == 200){
              store.state.logUserIn();
              if(res.url.includes('exchange')){
                return router.push('/wallet/exchange.html');
              }else{
                error.value = "Usuario y Password Incorrectos";
                isLoading.value = false;
                return isActive.value = true;
              }
              
            }
            
            else if(res.status == 404){
              error.value = "El Usuario No Existe o Cuenta No Activa";
            }

            else if(res.status == 302){
              isOldVersion.value = true;
              const data = await res.json();
              error.value = "Necesario Actualizar la Aplicacion.";
              version.value = "(Ultima Version: " + data.message + ")";
            }

            else{
              error.value = "Error de Conexion";
            }

            isActive.value = true;
            isLoading.value = false;
          
          }catch(err){
            console.log(err);
            error.value = "Error de Sistema"
            isActive.value = true;
            isLoading.value = false;
          }

        }

        return { isLoading, user, password, logMeIn, isActive, isOldVersion, error, version };
    }
}

</script>