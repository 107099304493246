<template>
    <h5 class='green-text center-align' v-if="timeLeft">
      {{ timeLeft }}
    </h5>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onBeforeUnmount, onMounted, watch } from '@vue/runtime-core';
import { getMSleft, finishCountDown, turnTimeIntoString } from '@/composables/time';
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    setup(){

        const {store} = useBoilerplate(false);
        let globInterval = null;
        let totMS = ref(null);
        const timeLeft = ref(null);

        watch(() => totMS.value, () => {
            if(totMS.value < 1000){
                timeLeft.value = "0h:0m:0s";
                clearInterval(globInterval);
                finishCountDown(store.state.escrow.escrow._id);
                return store.state.updateEscrowStage("Pago Fuera Tiempo Maximo");
                 
            }else{
                timeLeft.value = turnTimeIntoString(totMS.value);
            }
        });

        onMounted(async () => {
            try{
                totMS.value = await getMSleft(store.state.escrow.escrow._id);
                if(globInterval != null){
                    return clearInterval(globInterval);
                }
                globInterval = setInterval(() => totMS.value -= 1000, 1000);
            }catch(err){
                console.log(err);
            }
        })
        
        onBeforeUnmount(() => {
            if(globInterval != null){
                return clearInterval(globInterval);
            }
        })
        
        return { timeLeft };

    }
}
</script>