<template>
    <div class = "col s12 single-msg center">
        <p class = 'left-align'>
            <b :class="{infinity : index == store.state.infinity}">
                {{ msg.sender }}: 
            </b>
            <br>

            <span v-if="msg.message!=''">{{ msg.message }}</span>
        </p>
            
        <img :src="pictureBig" v-if="msg.message==''" @click="url=msg.pictureBig">
        
        <p class = "right-align">
            <i 
                class = 'fas fa-check green-text'
                v-if="msg.read && msg.sender == store.state.alias">
            </i> 
            <span class = "time-span">
                {{ timeAgo }}
            </span>
        </p>
        
    </div>

    <teleport to="body">
        <BigPictureModal :isActive="url!=null" :url="url" @close="url=null" />
    </teleport>
    
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import { timeDiff } from '@/composables/time'
import BigPictureModal from '@/components/modals/BigPictureModal'
import { socket } from '@/composables/socket'
import { isItBlinking } from '@/composables/blink'
import { formatPicUrl } from '@/composables/pictures'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    props: ['msg', 'index', 'room'],
    components: { BigPictureModal },
    setup(props){

        const { store } = useBoilerplate(false);
    
        const timeAgo = computed(() => {
            const ago = props.msg.timestamp != '' ? timeDiff(props.msg.timestamp) : 'Just a second ago';
            return ago;
        })
        
        const pictureBig = computed(() => formatPicUrl(props.msg.pictureBig))
        const url = ref(null);
        const isRead = props.msg.read;

        socket.on('read', data =>{
            if(data == props.msg.id){
                props.msg.read = true;
            }
        })

        onMounted(() => {
            //Send Read Emit if relevant
            if(props.msg.sender != store.state.alias && props.msg.read == false){
                const data = {
                    id: props.msg.id,
                    counterparty: props.msg.sender,
                    room: props.room
                }
                socket.emit('read', data);

                //Stop Blinking Chat
                isItBlinking()
            }
        })

        return { store, pictureBig, timeAgo, url, isRead }
    }
}
</script>

<style scoped>
    img{
        width: 100%;
        max-width: 150px;
        max-height: 200px;
    }
    .single-msg{
        border: 2px solid yellowgreen;
        border-radius: 10px;
        margin-bottom: 15px;
    }

    .time-span{
        font-size: small;
    }
</style>