<template>
    
    <li id = 'profileCollapse' class ="active">
        
        <div class = "collapsible-header white-text grey darken-1 active"><i class="fas fa-user"></i><span id ="userProfile">Perfil del Usuario</span></div>
        
        <div class = "collapsible-body center-align grey darken-2">
            
            <img :src="pictureUrl" id = 'avatarPic' class= "circle responsive-img margin" style = "width: 100px; height: 100px;">
            
            <div class="aliasDiv">
                <h5 class="marginBottom white-text"><b>{{ alias }}</b></h5>
            </div>
            
            <form class = 'picForm' @submit.prevent="setPic">
            
                <div class="file-field input-field">
                    <div class="btn-small white orange-text">
                        <span><i class = "fas fa-image"></i></span>
                        <input type="file" ref='picture'>
                    </div>
                    <div class="file-path-wrapper">
                        <input 
                            class="file-path" 
                            id = 'fileP' 
                            type="text" 
                            placeholder = 'Cargar Foto del Perfil'
                        >
                    </div>
                </div>

                <Error :warning="error" v-if="error!=null"/>
                <Loader v-if="isLoading" />
                
                <p>
                    <button class = 'btn orange white-text margin'>
                        <i class='fa fa-check'></i>
                    </button>
                </p>

            </form>

        </div>
    
    </li>

    <teleport to="body">
        <div hidden>
            <canvas></canvas>
        </div>
    </teleport>

</template>

<script>

import Error from '@/components/UI/Error';
import { computed, onMounted, ref } from '@vue/runtime-core';
import Loader from '@/components/UI/Loader.vue';
import { formatPicUrl } from '@/composables/pictures'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    
    components: { Error, Loader },
    props:['alias', 'picture'],
    
    setup(props){

        const { error, isLoading } = useBoilerplate(false);

        const picture=ref(null);
        let pictureUrl = ref(null);
        
        onMounted(() => {
            pictureUrl.value = formatPicUrl(props.picture);
        })
        
        const setPic = () =>{ 

            error.value = null;
            isLoading.value = true;
            
            const photo = picture.value.files[0];
            
            if(!photo){
                isLoading.value = false;
                return error.value = "Necesario Seleccionar una Foto";
            }

            const maxSize = 10 * 1024 * 1024;
            
            if(maxSize < photo.size){
                isLoading.value = false;
                return error.value = "MAX 10 MB";
            }
        
            if(photo.type == "image/jpeg" || photo.type == "image/png"){      
                const reader = new FileReader();  
                reader.onload = function(readerEvent) {
                    const image = new Image();
                    image.onload = function(imageEvent) { 
            
                        var max_size = 150;
                        var w = image.width;
                        var h = image.height;
                            
                        if(w > h){  if (w > max_size) { h*=max_size/w; w=max_size; }
                        }else{  if (h > max_size) { w*=max_size/h; h=max_size; } }
                    
                        const canvas = document.querySelector('canvas');
                        canvas.width = w;
                        canvas.height = h;
                        canvas.getContext('2d').drawImage(image, 0, 0, w, h);
                        let dataURL;
                        if(photo.type == "image/jpeg" || photo.type == "image/jpg" ) {
                            dataURL = canvas.toDataURL("image/JPG", 1.0);
                        }else{
                            dataURL = canvas.toDataURL("image/png");    
                        }
            
                    }
                    image.src = readerEvent.target.result;
                }
            
                const myReader = reader.readAsDataURL(photo);
                
                setTimeout(function(){uploadPic()}, 1000);
            
            }else{
                picture.value.value = ''; 
                isLoading.value = false;
                return error.value = "Solo Fotos en Formato PNG y JPG";
            }
        }
  
        const uploadPic = () =>{

            const photo = picture.value.files[0];
            //If there is no picture and alias is set already, prevent submission
            if(!photo){
                isLoading.value = false;
                return error.value = "Necesario Seleccionar una Foto";
            }
            
            const xhr = new XMLHttpRequest();
            const url = '/api/uploadProfilePicture';
            xhr.open('POST', url, true);
            //Create new FormData Object
            let formdata = new FormData();
            const canvasData = document.querySelector('canvas').toDataURL("image/png");
            const splitData = canvasData.split(';base64,');
            
            //if there s a pic append it
            if(picture.value.files[0]){
                formdata.append('photo', splitData[1]);
            }
            
            //if alias is not set, and length of alias value is > 3 append alias
            if(document.querySelector('#newAlias')){
                if(check_alias(document.querySelector('#newAlias').value) == true){
                    formdata.append('alias', document.querySelector('#newAlias').value);
                }else{
                    isLoading.value = false;
                    return error.value = 'El Alias debe contener entre 3 y 20 caracteres. Se permiten solo letras y números.';
                }
            }
            
            xhr.onreadystatechange = () => {

                if(xhr.readyState === 4 && xhr.status === 500){
                    isLoading.value = false;
                    return error.value = "Error!";
                }
        
                if(xhr.readyState === 4 && xhr.status === 401){
                    isLoading.value = false;
                    return error.value = xhr.responseText;
                }
        
                if(xhr.readyState === 4 && xhr.status === 200) {
                    const myResponse = JSON.parse(xhr.responseText);
                    sessionStorage.setItem('picture', myResponse.picture);
                    pictureUrl.value = formatPicUrl(myResponse.picture);
                    const toastMSG = 'Foto de Portada Cargada con Exito';
                    isLoading.value = false;
                    M.toast({html: toastMSG})//Show Success Toast
                }

            }

            //send http post request to submit FormData
            xhr.send(formdata);
            picture.value.value = '';
        
        }
        
        return { picture, pictureUrl, setPic, isLoading, error };
    }

}

</script>