<template>
  <div class="event">
      <p>
          <b>{{event.user}}</b> {{event.details}}
      </p>
      <p class="right-align ago">{{ ago }}</p>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { timeDiff } from '@/composables/time'
export default {
    props: ['event'],
    setup(props){
        const ago = computed(() => timeDiff(props.event.dateCreated));
        return { ago }
    }
}
</script>

<style scoped>
    .event{
        border-radius: 10px;
        border: 2px solid yellowgreen;
        margin-bottom: 10px;
        padding: 10px;
    }
    .ago{
        font-weight: lighter;
        font-size: smaller;
    }
</style>