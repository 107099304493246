<template>
    
    <li v-if="isMob=='true'"> 
        <div @click="go2" class = 'margin lateral-item' :class="extraClass">
            <a>
                <i class="white-text mobLinks mobicon" :class="icon"></i> 
                <span class= 'mobLinks white-text'> {{ text }}</span>
            </a>
        </div>
    </li>
    <li v-else> 
        <a @click="go2" class = "btn-floating btn-small orange" data-position="bottom">
            <i class="white-text" :class="icon"></i>
        </a>        
    </li>

</template>

<script>
import useBoilerplate from '@/composables/useBoilerplate';
import { goChat } from '@/composables/goChat'

export default {
    props: ['isMob', 'target', 'icon', 'text', 'extraClass'],
    setup(props){

        const { store, router } = useBoilerplate(false);

        const go2 = () => {
            if(props.isMob=='true'){
                const nav = document.querySelector('.sidenav');
                nav.classList.add('sidenav-close');
            }
            if(props.text=='Salir'){
                store.state.logUserOut();
            }
            if(props.text=='Contactenos'){
                if(store.state.isLogged!=true || store.state.alias==''){
                    return router.push({name: "ContactUs"});
                }
                return goChat('QBita', store.state.alias, router);
            }
            router.push({name: props.target});
        }

        return { go2 }
    }
}
</script>

<style scoped>

    .mobicon{
        margin-right: 7px !important;
    }

    .lateral-item{
        padding: 5px;
        padding-left: 10px;
    }
    .lateral-item.pulse{
        

        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        transform: scale(1);
        animation: pulse 1.2s infinite;
    }

    @keyframes pulse {
	0% {
		transform: scale(0.98);
	}

    40% {
		transform: scale(1);
	
		box-shadow: 0 0 0 0 yellowgreen;
	}

	60% {
		transform: scale(1);
	
		box-shadow: 0 0 0 0 yellowgreen;;
	}

    90% {
		transform: scale(1.02);
        box-shadow: 0 0 0 2px yellowgreen;
        background: rgb(240, 240, 193);
	}

}
</style>