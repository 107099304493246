import QRCode from 'qrcode'

export const showMeQR = (btcAddress, target, bool) =>{
    if(bool == "true"){
      btcAddress = "bitcoin:"+btcAddress;
      console.log("BTC Adress: ", btcAddress);
    }
    QRCode.toCanvas(document.getElementById(target), btcAddress, function (error) {
      if (error) console.error(error)
    })
}