import SimpleCrypto from 'simple-crypto-js';

export const encryptKey = (xpriv, secretKey) => {
    try{
      const simpleCrypto = new SimpleCrypto(secretKey);
      const cipherText = simpleCrypto.encrypt(xpriv);
      return cipherText;
    }catch(error){
      console.log(error);
      return false;
    }
}

export const decryptKey = (cipher, secretKey) => {
  try{
    const simpleCrypto = new SimpleCrypto(secretKey);
    const xpriv = simpleCrypto.decrypt(cipher);
    return xpriv;
  }catch(error){
    console.log(error);
    return false;
  }
}
