<template>
    <div class="col s12" v-if="events.length < 1">
        <Loader />
    </div>
    <div v-for="event in events.myLogs" :key="event._id">
        <SingleEvent :event="event"/>
        
    </div>
</template>

<script>
import Loader from '@/components/UI/Loader'
import SingleEvent from './SingleEvent'
export default {
    props: ['events'],
    components: { Loader, SingleEvent }
}
</script>

<style>

</style>