<template>
    <slot></slot>
    <p class = "grey-text text-darken-1" :class="{bigStars: isBig==true}">
        <i class = 'fas fa-star' :class="score >= 1 ? 'orange-text' : ''" @click="handleClick(1)"></i>
        <i class = 'fas fa-star' :class="score >= 2 ? 'orange-text' : ''" @click="handleClick(2)"></i>
        <i class = 'fas fa-star' :class="score >= 3 ? 'orange-text' : ''" @click="handleClick(3)"></i>
        <i class = 'fas fa-star' :class="score >= 4 ? 'orange-text' : ''" @click="handleClick(4)"></i>
        <i class = 'fas fa-star' :class="score >= 4.5 ? 'orange-text' : ''" @click="handleClick(5)"></i>
    </p>
</template>

<script>
export default {
    
    props: ['score', 'isBig', 'isForm', 'parameter'],
    emits: ['evaluated'],
    
    setup(props, context){
        
        const handleClick = n => {
            if(props.isForm == true){
                const obj = {
                    evaluation: n,
                    parameter: props.parameter
                }
                context.emit('evaluated', obj);
            }
        }

        return { handleClick }; 
    
    }
}
</script>

<style scoped>
    .fa-star{
        margin-left: 7px;
    }
    .bigStars{
        font-size: 24px;
    }
</style>