export const conversionX = (amount, margin, rates, xchange) => {
    const multiplier = 100 + Number(margin);
    const priceAfterMargin = (amount / 100) * multiplier;
    const mn = !xchange ? priceAfterMargin * 25 : amount * xchange;
    const eth = priceAfterMargin / rates.ETH;
    const btc = amount / rates.BTC;
    const conversions = {
        cubano: mn.toFixed(2)+" CUP",
        USD: mn.toFixed(2)+" CUP",
        DAI: !xchange ? priceAfterMargin.toFixed(2) + " DAI" : amount * xchange,
        USDT: !xchange ? priceAfterMargin.toFixed(2) + " USDT" : amount * xchange,
        MLC: !xchange ? priceAfterMargin.toFixed(2) + " MLC" : amount * xchange + " MLC",
        BTC: btc.toFixed(8),
        ETH: eth.toFixed(8) + " ETH",
        oneDollar: rates.oneDollar.toFixed(8),
        tenDollars: rates.tenDollars.toFixed(8),
        kDollars: rates.kDollars.toFixed(8)
    }
    return conversions;
}

export const convertBTC = (amount, rates) => {
    const result = Math.round(amount * rates * 100) / 100;
    if(isNaN(result)) return 0;
    return result;
}

export const convertUSD = (amount, rates) => {
    const result = (amount / rates).toFixed(8);
    if(isNaN(result)) return 0;
    return result;
}

