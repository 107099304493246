<template>
    <li>
        <div class = "collapsible-header white-text grey darken-1"><i class="fab fa-facebook"></i><span id ="userProfile">Facebook</span></div>
        <div class = "collapsible-body center-align grey darken-2">
            
            <p class = "white-text">Compartir el perfil Facebook con los demas usuarios no es obligatorio, pero ayuda a establecer relaciones de mayor credibilidad y confianza con la controparte en las compraventas.</p>
            
            <form @submit.prevent="saveLink">
                <div class="input-field">
                    <input type="text" v-model="myLink" placeholder = 'Link al Perfil Facebook'>
                </div>
                <Error v-if="error && isLoading==false" :warning="error" />
                <Loader v-if="isLoading && error==null" />
                <p>
                    <button class="btn orange white-text">
                        <i class='fa fa-check'></i>
                    </button>
                </p>
            </form>

        </div>
    </li>
</template>

<script>

import { ref } from '@vue/reactivity'
import { check_fb } from '@/composables/sanitize'
import { post } from '@/composables/httpCalls'
import Error from '@/components/UI/Error'
import Loader from '@/components/UI/Loader'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    
    components: { Error, Loader },
    props: ['link'],

    setup(props){

        const { store, router, isLoading, error } = useBoilerplate(false);

        const myLink = ref(props.link);
        
        const saveLink = async () => {
            try {

                if(myLink.value != ''){
                    if(check_fb(myLink.value) == false){
                        return error.value = "El Link No es Correcto";
                    }
                }
                
                isLoading.value = true;
                error.value = null;

                const url = "/api/saveFB";
                const vars = { fb: myLink.value };
                const res = await post(url, vars);

                if(res.status == 200){
                    isLoading.value = false;
                    const html = "Link Actualizado";
                    return M.toast({html});
                }else if(res.status == 412){
                    store.state.logUserOut();
                    return router.push({name: "Auth"});
                }else if(401){
                    isLoading.value = false;
                    return error.value = "El Link No es Correcto";
                }else{
                    isLoading.value = false;
                    return error.value = "No Se Pudo Actualizar el Link";
                }
            } catch (err) {
                console.log(err);
                error.value = "Error de Conexion"
                isLoading.value = false;
            }
        }
        return { myLink, saveLink, error, isLoading };
    }
}
</script>

<style>

</style>