<template>
<div class = "center">
  <div class="input-field">
    <select ref="stateChange" class = 'browser-default'>
        <option value="0">Seleccionar Estado</option>
        <option value="Inicio">Inicio</option>
        <option value="BTC enviados a Escrow multiSig">BTC enviados a Escrow multiSig</option>
        <option value="BTC depositados en Escrow multiSig">BTC depositados en Escrow multiSig</option>
        <option value="Pago Efectuado">Pago Efectuado</option>
        <option value="Compraventa Completada">Compraventa Completada</option>
        <option value="Compraventa Cancelada">Compraventa Cancelada</option>
        <option value="Depósito Parcial en Escrow multiSig">Depósito Parcial en Escrow multiSig</option>
        <option value="Pago Fuera Tiempo Maximo">Pago Fuera Tiempo Maximo</option>
        <option value="Disputa Abierta">Disputa Abierta</option>
        <option value="Disputa Resuelta">Disputa Resuelta</option>
    </select>
    </div>
    <br>
    
    <div class="center">
        <Error :warning="error" v-if="error"/>
        <Loader v-if="isLoading" />
    </div>
    
    <LargeButton 
        text="Cambia" 
        icon="fas fa-check" 
        position="left" 
        color="red"
        @click="change"
    />

  </div>
</template>

<script>
import LargeButton from '@/components/UI/Buttons/LargeButton'
import Loader from '../../UI/Loader.vue'
import Error from '../../UI/Error.vue'
import { ref } from '@vue/reactivity'
import { post } from '@/composables/httpCalls';
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { LargeButton, Loader, Error },
    setup(){

        const { store, isLoading, error } = useBoilerplate(false);
    
        const stateChange = ref(null);

        const change = async () => {
            const newStage = stateChange.value.options[stateChange.value.selectedIndex].value;
            if(newStage == "0"){
                return;
            }
            if(newStage == store.state.escrow.escrow.stage){
                return;
            }

            isLoading.value = true;
            error.value = null;

            try {
                const url = "/api/changeStage";
                const vars = {
                    id: store.state.escrow.escrow._id,
                    newStage: newStage
                }
                const res = await post(url, vars);
                if(res.status == 200){
                    store.state.updateEscrowStage(newStage);
                    M.toast({html: "Estado Modificado"});
                }else{
                    error.value = "error";
                }
                isLoading.value = false;
            } catch (err) {
                console.log(err);
                isLoading.value = false;
                error.value = err;
            }
        }
        return { isLoading, error, change, stateChange };
    }
}
</script>

<style>

</style>