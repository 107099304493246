import { post } from './httpCalls';

export const goChat = async (counterparty, me, router) => {
    
    try {
        
        if(counterparty == me){
            return;
        }
        const room = me > counterparty ? 
        me + "---" + counterparty :
        counterparty + "---" + me; 

        const url = "/api/findRoom";
        const vars = { room };
        const res = await post(url, vars);
        
        if(res.status == 200){
            const myResponse = await res.json();
            return router.push({name: 'Chat', params: { room: myResponse.room }});
        }else{
            return;
        }
        
    } catch (error) {
        return console.log(error);
    }
}