<template>
    <div class="col s12 center orange">
        
        <BlockControl :counterparty="counterparty"/>
        
        <div class="col s2 right-align">
            <img 
                    :src="picture"
                    :class="{
                        isOnline: store.state.connectedUsers[counterparty] ||
                        vips.indexOf(counterparty) >= 0
                    }"
            >
        </div>
        <div class="col s6 left-align">
            <h6 class = "counterparty" @click="userToBeChecked=counterparty">
                {{ counterparty }} <i class = "fas fa-pen" v-if="isWriting" ></i> 
            </h6>
        </div>
        <div class="col s2 right-align">
            <router-link :to="{name: 'AllRooms'}">
                <h6>
                    <i class="fas fa-window-close white-text"></i>
                </h6>
            </router-link>
        </div>
    </div>

    <teleport to="body">
        <ReputationModal :isActive="userToBeChecked!=null" :user="userToBeChecked" @close="userToBeChecked=null"/>
    </teleport>
</template>

<script>
import { ref } from '@vue/reactivity'
import ReputationModal from '@/components/modals/ReputationModal'
import BlockControl from './BlockControl.vue'
import { socket } from '@/composables/socket'
import { vips } from '@/composables/vips'
import useBoilerplate from '@/composables/useBoilerplate';
import { onMounted } from '@vue/runtime-core'

export default {
    props:['picture', 'counterparty', 'room'],
    components: { ReputationModal, BlockControl },
    setup(props){
        
        const { store, isActive } = useBoilerplate(false);
        
        const userToBeChecked = ref(null);
        const isWriting = ref(false);

        socket.on('typing', data =>{
            
            if(data == props.room){
                isWriting.value = true;
            }
        }),

        socket.on('stopped', data =>{
            
            if(data == props.room){
                isWriting.value = false;
            }
        })

        return { isActive, userToBeChecked, isWriting, store, vips }
    }

}
</script>

<style scoped>

    h6{
        margin-top: 20px;
        color: white;
        font-weight: bold;
    }

    img{
        width: 35px;
        height: 35px;
        margin-top: 10px;
        border-radius: 50%;
    }

    .isOnline{
        border: 5px solid yellowgreen;
    }

    .counterparty{
        cursor: pointer;
    }
</style>