<template>
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
        <div class="modal-content-bottom center black">
            
            <Error :warning="error" v-if="error!=null && isLoading==false"/>
            
            <LoadingFullPage v-if="isLoading"/>
            
            <ReputationProfile 
                :user="userData" 
                v-if="isLoading==false && error==null" 
                @deleteUser="promptDelete"
            />
            

        </div>
    </div>

    <teleport to="body">
        <DeleteUserModal 
            :isActive="isActiveDelete" 
            :alias="userData.alias"
            @close="isActiveDelete=false"
        />
    </teleport>

</template>

<script>

import LoadingFullPage from '@/components/UI/LoadingFullPage';
import ReputationProfile from '@/components/Reputation/ReputationProfile';
import DeleteUserModal from '@/components/modals/DeleteUserModal';
import Error from '@/components/UI/Error';
import { ref } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
import useBoilerplate from '@/composables/useBoilerplate';

export default {

    props: ['user', 'isActive'],
    components: { LoadingFullPage, Error, ReputationProfile, DeleteUserModal },
    emits: ['close'],
    
    setup(props, context){

        const { error, isLoading, store } = useBoilerplate(true);
        
        const userData=ref({});
        const isActiveDelete = ref(false);

        watch(()=> props.user, async() => {
            isLoading.value = true;
            if(!props.user){
                return;
            }
            try {
                error.value = null;
                const url = "/api/reputation/" + props.user;
                const res = await fetch(url, {cache: "no-cache"});
                //Handle Bad Response
                if(res.status == 300){
                    return router.push({name: 'Welcome'});
                }else if(res.status == 401){
                    return router.push({name: 'TwoFA'});
                }else if(res.status == 403){
                    return router.push({name: 'Blocked'});
                }else if(res.status == 412){
                    store.state.logUserOut();
                    return router.push({name: 'Auth'});
                }else if(res.status == 404){
                    isLoading.value = false;
                    return error.value = "No Existen Usuarios con el alias " + props.user;
                }else if(res.status == 200){ 
                    const data = await res.json();
                    userData.value = data;
                    return isLoading.value = false;
                }else{
                    isLoading.value = false;
                    return error.value = "Error De Sistema"
                }
                
            } catch (err) {
                console.log(err);
                error.value = err.message ? err.message : "Error de Conexion";
                isLoading.value = false;
            }
            
        })

        const closeModal = () => {
            context.emit('close');
        }

        const promptDelete = () => {
            closeModal();
            isActiveDelete.value = true;
        }

        return { isLoading, error, userData, closeModal, isActiveDelete, promptDelete };
    }
}
</script>

