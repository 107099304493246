<template>
    <div class="row grey darken-1 marginTop">

        <div class="col s12 center orange">
            <h5 class="white-text">Todos Los Chats</h5>
        </div>

        <LateralNav />

        <div class="col s12 l8 white center">

            <br><br>

            <LargeButton 
                color="green"
                icon="fas fa-search"
                text="Usuario"
                position="left"
                @click="router.push({name: 'FindUsers'})"
            />

            <ZeroResults v-if="rooms.length < 1" text="Chats" />

            <div v-for="(room, index) in rooms" :key="room._id" >
                <SingleRoom :room="room" :myAlias="myAlias" :index="index" />
            </div>

        </div>
    
    </div>
</template>

<script>

import ZeroResults from '@/components/UI/ZeroResults'
import SingleRoom from '@/components/Chats/SingleRoom'
import { onMounted } from '@vue/runtime-core';
import { isVisible } from '@/composables/infinity';
import LateralNav  from '@/components/nav/LateralNav';
import useBoilerplate from '@/composables/useBoilerplate';
import LargeButton from '@/components/UI/Buttons/LargeButton';

export default {
    props: ["rooms"],
    components: { ZeroResults, SingleRoom, LateralNav, LargeButton },
    
    setup(props){

        const { store, router } = useBoilerplate(false);
        
        const myAlias = sessionStorage.getItem('alias');

        const infinityScrolling = async () => {
            const infinityTrigger = document.querySelector('.infinity');
            if(infinityTrigger){
                if(isVisible(infinityTrigger) == true ){
                    infinityTrigger.classList.remove('infinity');
                    await store.state.loadMore();
                }
            }
        }

        onMounted(() => {
            window.addEventListener('scroll', () => {
                infinityScrolling();
            });
        })

        return { store, myAlias, infinityScrolling, router };
    }
}

</script>

<style scoped>
    .l8{
        min-height: 75vh !important;
    }
</style>