<template>
    
    <div class = "container">
        <br>
        
        <CallError v-if="!isLoading && error!=null" :warning="error"/>

        <LoadingFullPage v-if="isLoading && !error"/>

         <div class="row grey darken-1 marginTop" v-if="!isLoading && !error">

            <div class="col s12 center-align orange">
                <h5 class = "white-text">Ajustes</h5>
            </div>

            <LateralNav />

            <div class="col s12 l8 left-align white">
                <ul class = "collapsible">
                
                    <Profile :alias="userData.alias" :picture="userData.picture"/>

                    <Whatsapp :phone="userData.whatsapp"/>
                    
                    <Facebook :link="userData.facebook"/>

                    <PasswordChange />

                    <Push :push="userData.isPush"/>

                    <TwoFA :twoFA="userData.is2FA"/>

                    <Cards />

                    <Reset :alias="userData.alias"/>

                </ul>
            </div>

        </div>
        
    </div>

    <div id = 'myDiv' hidden>
        <canvas></canvas>
    </div>
            
</template>

<script>
import Profile from '@/components/Settings/Profile'
import Whatsapp from '@/components/Settings/Whatsapp'
import Facebook from '@/components/Settings/Facebook'
import PasswordChange from '@/components/Settings/PasswordChange'
import Cards from '@/components/Settings/Cards'
import Push from '@/components/Settings/Push'
import TwoFA from '@/components/Settings/TwoFA'
import Reset from '@/components/Settings/Reset'
import LoadingFullPage from '@/components/UI/LoadingFullPage'
import CallError from '@/components/UI/CallError'
import { onMounted, reactive } from '@vue/runtime-core'
import LateralNav from '@/components/nav/LateralNav'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { 
        CallError, Cards, LateralNav, LoadingFullPage, Profile, Whatsapp, Facebook, PasswordChange, Push, TwoFA, Reset
    },
    setup(){

        const {store, router, isLoading, error } = useBoilerplate(true);

        const userData = reactive({});

        /*
        const handleCardDelete = value => {
            userData.cards = userData.cards.filter(card => card._id != value);
            return M.toast({html: "Tarjeta Eliminada"});
        }
        */

        const loadUserData = async () => {

            try {
                const url = "/api/userData";
                const res = await fetch(url, {cache: "no-cache"});
                if(res.status == 200){
                    const data = await res.json();
                    userData.alias = data.alias;
                    userData.picture= data.picture;
                    userData.whatsapp = data.whatsapp;
                    userData.facebook = data.facebook;
                    userData.isPush = data.push;
                    userData.is2FA = data.twoFA;
                    store.state.setCards(data.cards);
                    return isLoading.value = false;
                }if(res.status == 300){
                    return router.push({name: 'Welcome'});
                }else if(res.status == 401){
                    return router.push({name: 'TwoFA'});
                }else if(res.status == 403){
                    return router.push({name: 'Blocked'});
                }else if(res.status == 412){
                    store.state.logUserOut();
                    return router.push({name: 'Auth'});
                }else{
                    isLoading.value = false;
                    error.value = "Error de Sistema: " + res.status;
                }
            } catch (err) {
                console.log(err);
                error.value = "Error de Conexion"
            }
            
        }

        onMounted(() => {
            loadUserData();
        })

        return { userData, error, isLoading };
    }
}
</script>