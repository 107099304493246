export const check_email = (x) =>{
	const reg = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/
	if (reg.test(x) == true){
		return true;
	}else {
		return false;
	}
}

export const check_nauta = (x) =>{
	if (x.includes("@nauta") || x.includes("@NAUTA")){
		return false;
	}else {
		return true;
	}
}

export const check_newPw = (x) =>{
  const reg = /^[^<>]{8,20}$/ig;
  if(reg.test(x) == true){
    return true;
  }else{
    return false;
  }
}

export const check_alias = (x) =>{
  const reg = /^[a-z0-9]{3,20}$/i;
  if(reg.test(x) == true){
    return true;
  }else{
    return false;
  }
}

export const check_name_and_surname = (x) =>{
  const reg = /^[a-z ]{3,20}$/i;
  if(reg.test(x) == true){
    return true;
  }else{
    return false;
  }
}

export const check_wa = (x) => {
  const reg = /^(\+)?[0-9 ]{8,20}$/g;
  if(reg.test(x) == true){
    return true;
  }else{
    return false;
  }
}

export const check_carnet = (x) => {
  const reg = /^[0-9]{11}$/g;
  if(reg.test(x) == true){
    return true;
  }else{
    return false;
  }
}

export const check_fb = (x) => {
  const reg = /^(http)?(s)?(:\/\/)?(www.)?facebook.com\/([^<>;:]{1,40})$/gi;
  if(reg.test(x) == true){
    return true;
  }else{
    return false;
  }
}

export const check_pw = (x) =>{
  const reg = /^[^<>]{8,20}$/ig;
  if(reg.test(x) == true){
    return true;
  }else{
    return false;
  }
}

export const check_message = (x) =>{
  const reg = /^[^<>&£"}{]{1,1000}$/i ;
  if(reg.test(x) == true){
    return true;
  }else{
    return false;
  }
}