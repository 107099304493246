<template>
    <div class="col s12 marginTop" id = "signUpTab">

        <form @submit.prevent="signup">
            
            <div class = "input-field col s12">
                <input type = "email" v-model="username" required>
                <label for = "username" class = 'grey-text'><i class="far fa-envelope"></i> <span>E-Mail</span></label>
            </div>
            
            <div class = "input-field col s12">
                <input type = "password" v-model="pw" required>
                <label for = "pw" class = 'grey-text'><i class="fas fa-key"></i> <span id='pwCreateLab'>Contraseña</span></label>
            </div>
            
            <div class = "input-field col s12">
                <input type = "password" v-model="pwRep" required>
                <label for = "pwRep" class ='grey-text'><i class="fas fa-key"></i> <span id = "pwCreateLabRep">Repita Contraseña</span></label>
            </div>

            <div class = "input-field col s12">
                <input type = "text" v-model="alias" required>
                <label for = "alias" class = 'grey-text'><i class="fas fa-user"></i> <span>Alias</span></label>
            </div>
            
            <label class = "center-align">
                <input type = "checkbox" ref="terms" required/>
                <span class = "white-text">Acepto Términos y Condiciones</span>
            </label>
            
            <Loader v-if="isLoading" />
            
            <div class="col s12 center-align">
                <button class = "btn orange white-text marginTop">
                    <i class = "fas fa-check"></i>
                </button>
            </div>

        </form>

    </div>

    <teleport to="body">
        <ErrorModal :isActive="isActive" :warning="error" @close="isActive=false" />
    </teleport>

</template>

<script>

import Loader from '@/components/UI/Loader';
import ErrorModal from '@/components/modals/ErrorModal';
import { check_nauta } from '@/composables/sanitize' 
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { post } from '@/composables/httpCalls';
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { Loader, ErrorModal },
    emits: ['newUserSuccess'],
    setup(props, context){

        const { isLoading, isActive, error } = useBoilerplate(false);

        const username = ref('');
        const pw = ref('');
        const pwRep = ref('');
        const alias = ref('');
        const terms = ref(null);
        
        const signup = async () => {

            //Tested with this Address
            //warikif177@to200.com

            try{

                
                if(!check_nauta(username.value)){
                    error.value = 'No Es Posible Usar el Correo NAUTA.';
                    return isActive.value = true;
                }

                if(pw.value != pwRep.value){
                    error.value = "Repita la Contraseña";
                    return isActive.value = true;
                }

                if(!terms.value.checked){
                    error.value = "Es Necesario Aceptar Los Terminos y Condiciones";
                    return isActive.value = true;
                }

                isLoading.value = true;

                const url = '/api/walletCreate';
                
                const vars = {
                    username: username.value.toLowerCase().replace(/ /gi, ""),
                    password: pw.value,
                    alias: alias.value
                }

                const res = await post(url, vars);

                if(res.status == 200){
                    isLoading.value = false;
                    return context.emit('newUserSuccess');
                }

                else if(res.status == 400){
                    isLoading.value = false;
                    isActive.value = true;
                    const data = await res.json();
                    return error.value = data.message;
                }

                else if(res.status == 409){
                    isLoading.value = false;
                    isActive.value = true;
                    return error.value = "Este Correo se encuentra ya en el sistema";
                }

                else if(res.status == 401){
                    isLoading.value = false;
                    isActive.value = true;
                    return error.value = "Este Alias ys es utilizado por otro Usuario";
                }

                else{
                    isLoading.value = false;
                    isActive.value = true;
                    return error.value = "Error (" + res.status +")";
                }
                
            }catch(err){
                console.log(err.message);
                error.value = err.message;
                isLoading.value = false;
                isActive.value = true;
            }
            
        }

        onMounted(() => {
            M.AutoInit();
        })

        return { username, pw, pwRep, alias, terms, isLoading, isActive, error, signup };
    }
}

</script>

<style>

</style>