<template>

        <RBF 
            v-if="isRBF==true && store.state.escrow.escrow.isRBF==true" 
            @goBack="isRBF=false"
        />

        <div v-else>
            <p v-if="store.state.escrow.escrow.stage=='Inicio'">
                <Inicio />
            </p>
            <p v-if="store.state.escrow.escrow.stage=='BTC enviados a Escrow multiSig'">
                <BTCenviados />
            </p>
            <p v-if="store.state.escrow.escrow.stage=='BTC depositados en Escrow multiSig'">
                <BTCdepositados />
            </p>
            <p v-if="store.state.escrow.escrow.stage=='Pago Efectuado'">
                <PagoEfectuado />
            </p>
            <p v-if="store.state.escrow.escrow.stage=='Compraventa Completada'">
                <CompraventaCompletada />
            </p>
            <p v-if="store.state.escrow.escrow.stage=='Compraventa Cancelada'">
                <CompraventaCancelada />
            </p>
            <p v-if="store.state.escrow.escrow.stage=='Disputa Abierta'">
                <DisputaAbierta />
            </p>
            <p v-if="store.state.escrow.escrow.stage=='Disputa Resuelta'">
                <DisputaResuelta />
            </p>
            <p v-if="store.state.escrow.escrow.stage=='Pago Fuera Tiempo Maximo'">
                <PagoFueraTiempoMaximo />
            </p>
            <p v-if="store.state.escrow.escrow.stage=='Depósito Parcial en Escrow multiSig'">
                <DepositoParcial />
            </p>
        </div>

        <teleport to="body">
            
            <FloatingButton 
                icon="fas fa-bolt" 
                v-if="store.state.escrow.escrow.isRBF==true &&
                        !store.state.escrow.escrow.winner &&
                        store.state.escrow.escrow.stage == 'Compraventa Completada' && 
                        store.state.alias == store.state.escrow.escrow.buyer"
                @click="isRBF=true"
            />

            <FloatingButton 
                icon="fas fa-bolt" 
                v-if="store.state.escrow.escrow.isRBF==true &&
                        !store.state.escrow.escrow.winner &&
                        store.state.escrow.escrow.stage == 'Compraventa Cancelada' && 
                        store.state.alias == store.state.escrow.escrow.seller"
                @click="isRBF=true"
            />

            <FloatingButton 
                icon="fas fa-bolt" 
                v-if="store.state.escrow.escrow.isRBF==true &&
                        store.state.escrow.escrow.winner &&
                        store.state.escrow.escrow.winner.length > 1 &&
                        store.state.escrow.escrow.stage == 'Compraventa Cancelada' && 
                        store.state.alias == store.state.escrow.escrow.winner"
                @click="isRBF=true"
            />

        </teleport>

</template>

<script>
import CompraventaCancelada from './Stages/CompraventaCancelada.vue'
import CompraventaCompletada from './Stages/CompraventaCompletada.vue'
import BTCenviados from './Stages/BTCenviados.vue'
import BTCdepositados from './Stages/BTCdepositados.vue'
import PagoFueraTiempoMaximo from './Stages/PagoFueraTiempoMaximo.vue'
import DepositoParcial from './Stages/DepositoParcial.vue'
import Inicio from './Stages/Inicio.vue'
import PagoEfectuado from './Stages/PagoEfectuado.vue'
import DisputaAbierta from './Stages/DisputaAbierta.vue'
import DisputaResuelta from './Stages/DisputaResuelta.vue'
import RBF from './Stages/RBF.vue'
import FloatingButton from '@/components/UI/Buttons/FloatingButton'
import { ref } from '@vue/runtime-core'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: {
        Inicio, PagoEfectuado,
        CompraventaCancelada, CompraventaCompletada, 
        BTCenviados, BTCdepositados, 
        PagoFueraTiempoMaximo, DepositoParcial,
        DisputaAbierta, DisputaResuelta, 
        RBF, FloatingButton
    },
    setup(){

        const { store, router } = useBoilerplate(false);

        const isRBF = ref(false);
        
        const goRBF = () => router.push({name: "RBF", params: {id: store.state.escrow.escrow._id}})
        
        return { store, goRBF, isRBF };
    }
}
</script>

<style>

</style>