import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Tutorials from '../views/Tutorials.vue'
import Stats from '../views/Stats.vue'

import Auth from '../views/Auth.vue'
import Exchange from '../views/Ads/AllAds.vue'
import WalletBTC from '../views/Wallet/WalletBTC.vue'
import SendBTC from '../views/Wallet/SendBTC.vue'
import SendBTC2Escrow from '../views/Wallet/SendBTC2Escrow.vue'
import AllRooms from '../views/Chats/AllRooms.vue'
import Chat from '../views/Chats/Chat.vue'
import Escrow from '../views/Escrows/Escrow.vue'
import AllEscrows from '../views/Escrows/AllEscrows.vue'
import NewAd from '../views/Ads/NewAd'
import Settings from '../views/config/Settings'
import NewCard from '../views/config/NewCard'

import state from '@/store/state';

const requireLogin = (to, from, next) => {
  if(state.isLogged == false){
    next({name: "Auth"});
  }else{
    next();
  }
}

const requireAdmin = (to, from, next) => {
  if(state.isAdmin == false){
    next({name: "Auth"});
  }else{
    next();
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Todo Sobre Bitcoin en Cuba - QBita'
    }
  },
  {
    path: '/tutorials',
    name: 'Tutorials',
    component: Tutorials,
    meta: {
      title: 'Recursos Para Aprender a Usar Bitcoin en Cuba'
    }
  },
  {
    path: '/stats',
    name: 'Stats',
    component: Stats,
    meta: {
      title: 'Todos los Datos de Qbita'
    }
  },
  {
    path: '/wallet/index.html',
    name: 'Auth',
    component: Auth,
    meta: {
      title: 'Entrar y Crear Cuenta'
    }
  },
  {
    path: '/wallet/exchange.html',
    name: 'Exchange',
    component: Exchange,
    beforeEnter: requireLogin,
    meta: {
      title: 'Todos Los Anuncios'
    }
  },
  {
    path: '/wallet/newAd',
    name: 'NewAd',
    component: NewAd,
    beforeEnter: requireLogin,
    meta: {
      title: 'Crear Nuevo Anuncio'
    }
  },
  {
    path: '/wallet/BTC',
    name: 'WalletBTC',
    component: WalletBTC,
    beforeEnter: requireLogin,
    meta: {
      title: 'Mi Monedero BTC'
    }
  },
  {
    path: '/wallet/sendBTC',
    name: 'SendBTC',
    component: SendBTC,
    beforeEnter: requireLogin,
    meta: {
      title: 'Enviar BTC'
    }
  },
  {
    path: '/wallet/send-BTC-to-escrow',
    name: 'SendBTC2Escrow',
    component: SendBTC2Escrow,
    beforeEnter: requireLogin,
    meta: {
      title: 'Enviar BTC al Escrow'
    }
  },
  {
    path: '/wallet/chats',
    name: 'AllRooms',
    component: AllRooms,
    beforeEnter: requireLogin,
    meta: {
      title: 'Mis Chats'
    }
  },
  {
    path: '/wallet/chat/:room',
    name: 'Chat',
    component: Chat,
    beforeEnter: requireLogin,
    props: true,
    meta: {
      title: 'Chat'
    }
  },
  {
    path: '/wallet/escrow/:id',
    name: 'Escrow',
    component: Escrow,
    beforeEnter: requireLogin,
    props: true,
    meta: {
      title: 'Compraventa'
    }
  },
  {
    path: '/wallet/escrow-success/:escrow',
    name: 'EscrowSuccess',
    meta: {
      title: 'Transacción Enviada'
    },
    beforeEnter: requireLogin,
    props:true,
    component: () => import('@/views/Escrows/EscrowSuccess.vue')
  },
  {
    path: '/wallet/escrows',
    name: 'AllEscrows',
    component: AllEscrows,
    beforeEnter: requireLogin,
    meta: {
      title: 'Mis Compraventas'
    }
  },
  {
    path: '/wallet/find-users',
    name: 'FindUsers',
    beforeEnter: requireLogin,
    meta: {
      title: 'Busca Usuarios'
    },
    component: () => import('@/views/Reviews/FindUsers.vue')
  },
  {
    path: '/wallet/settings',
    name: 'Settings',
    component: Settings,
    beforeEnter: requireLogin,
    meta: {
      title: 'Ajustes'
    }
  },
  {
    path: '/wallet/new-card',
    name: 'NewCard',
    component: NewCard,
    beforeEnter: requireLogin,
    meta: {
      title: 'Agrega Nueva Tarjeta'
    }
  },
  {
    path: '/recover-password',
    name: 'RecoverPassword',
    meta: {
      title: 'Recuperar Password'
    },
    component: () => import('@/views/RecoverPassword.vue')
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    meta: {
      title: 'Preguntas Frecuentes'
    },
    component: () => import('@/views/FAQ.vue')
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    meta: {
      title: 'Contactenos'
    },
    component: () => import('@/views/ContactUs.vue')
  },
  {
    path: '/twoFA',
    name: 'TwoFA',
    beforeEnter: requireLogin,
    meta: {
      title: 'Autenticacion Multi-Factorial'
    },
    component: () => import('@/views/RoadBlocks/TwoFA.vue')
  },
  {
    path: '/welcome',
    name: 'Welcome',
    meta: {
      title: 'Bienvenido en QBita'
    },
    component: () => import('@/views/RoadBlocks/Welcome.vue')
  },
  {
    path: '/blocked',
    name: 'Blocked',
    meta: {
      title: 'Bloqueado'
    },
    component: () => import('@/views/RoadBlocks/Blocked.vue')
  },
  {
    path: '/walletRecover',
    name: 'WalletRecover',
    beforeEnter: requireLogin,
    meta: {
      title: 'Restablecer Claves Bitcoin'
    },
    component: () => import('@/views/RoadBlocks/WalletRecover.vue')
  },
  {
    path: '/wallet/open-dispute/:id',
    name: 'OpenDispute',
    beforeEnter: requireLogin,
    meta: {
      title: 'Nueva Disputa'
    },
    props: true,
    component: () => import('@/views/Dispute/OpenDispute.vue')
  },
  {
    path: '/wallet/review/:alias/:escrow',
    name: 'Review',
    beforeEnter: requireLogin,
    meta: {
      title: 'Evaluacion'
    },
    props: true,
    component: () => import('@/views/Reviews/Review.vue')
  },
  {
    path: '/admin/control-panel',
    name: 'AdminPanel',
    beforeEnter: requireAdmin,
    meta: {
      title: 'Panel de Control'
    },
    component: () => import('@/views/Admin/ControlPanel.vue')
  },
  {
    path: '/admin/see-wallet',
    name: 'SeeWallet',
    beforeEnter: requireAdmin,
    meta: {
      title: 'Wallets'
    },
    component: () => import('@/views/Admin/SeeWallet.vue')
  },
  {
    path: '/admin/manage-escrows',
    name: 'ManageEscrows',
    beforeEnter: requireAdmin,
    meta: {
      title: 'Gestiona Escrows'
    },
    component: () => import('@/views/Admin/ManageEscrows.vue')
  },
  {
    path: '/admin/manage-disputes',
    name: 'ManageDisputes',
    beforeEnter: requireAdmin,
    meta: {
      title: 'Gestiona Disputas'
    },
    component: () => import('@/views/Admin/ManageDisputes.vue')
  },
  {
    path: '/admin/resolve-dispute/:id',
    name: 'ResolveDispute',
    beforeEnter: requireAdmin,
    meta: {
      title: 'Soluciona Disputa'
    },
    props: true,
    component: () => import('@/views/Admin/ResolveDispute.vue')
  },
  {
    path: '/admin/manage-chats',
    name: 'ManageChats',
    beforeEnter: requireAdmin,
    meta: {
      title: 'Gestiona Chats'
    },
    component: () => import('@/views/Admin/ManageChats.vue')
  },

  {
    path: '/admin/manage-users',
    name: 'ManageUsers',
    beforeEnter: requireAdmin,
    meta: {
      title: 'List Email'
    },
    component: () => import('@/views/Admin/ManageUsers.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
}) 

router.afterEach((to, from) => {
  window.scroll(0, 0);
})

export default router
