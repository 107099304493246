<template>
    
    <div class="row pad20" >
        <div class="col s12 l10 offset-l1 left-align">
            <h4 class="orange-text">
                Datos del Exchange
            </h4>
        </div>
        
        <div class="col s12 l10 offset-l1 black left-align grey-text text-lighten-3">
            
            <div class="col s6 right-align">
                <h5>Usuarios:</h5>
            </div>
            <div class="col s6 left-align">
                <h5><b class="light-green-text">{{ stats.users }}</b></h5>
            </div>

            <div class="col s6 right-align">
                <h5>Volumen BTC:</h5>
            </div>
            <div class="col s6 left-align">
                <h5><b class="light-green-text">{{ stats.volumeBTC }}</b></h5>
            </div>

            <div class="col s6 right-align">
                <h5>Volumen USD:</h5>
            </div>
            <div class="col s6 left-align">
                <h5><b class="light-green-text">{{ stats.volume }}</b></h5>
            </div>

            <div class="col s6 right-align">
                <h5>Transacciones:</h5>
            </div>
            <div class="col s6 left-align">
                <h5><b class="light-green-text">{{ stats.escrows }}</b></h5>
            </div>

            <div class="col s6 right-align">
                <h5>BTC/USD:</h5>
            </div>
            <div class="col s6 left-align">
                <h5><b class="light-green-text">{{ stats.btc_usd }}</b></h5>
            </div>

            <div class="col s6 right-align">
                <h5>BTC/CUP:</h5>
            </div>
            <div class="col s6 left-align">
                <h5><b class="light-green-text">{{ stats.btc_cup }}</b></h5>
            </div>

            <div class="col s6 right-align">
                <h5>BTC/MLC:</h5>
            </div>
            <div class="col s6 left-align">
                <h5><b class="light-green-text">{{ stats.btc_mlc }}</b></h5>
            </div>

        </div>
        <!--
        <div class="col s12 l3 center-align indigo z-depth-1">
            <h3 class="white-text">Usuarios</h3>
            <i class="fas fa-user white-text text70" ></i>
            <h2 class="white-text statValue" id = 'totUsers'>{{ stats.users}}</h2>
        </div>
        
        <div class="col s12 l3 center-align light-green z-depth-1">
            <h3 class="white-text">Volumen</h3>
            <i class="fas fa-dollar-sign white-text text70" ></i>
            <h2 class="white-text statValue" id = 'totVolume'>{{ stats.volume }}</h2>
        </div>
        
        <div class="col s12 l3 center-align orange z-depth-1">
            <h3 class="white-text">Volumen</h3>
            <i class="fab fa-btc white-text text70" ></i>
            <h2 class="white-text statValue" id = 'totVolumeBTC'>{{ stats.volumeBTC }}</h2>
        </div>
        
        <div class="col s12 l3 center-align red z-depth-1">
            <h3 class="white-text">Compraventas</h3>
            <i class="fas fa-random white-text text70" ></i>
            <h2 class="white-text statValue" id = 'totEscrows'>{{ stats.escrows}}</h2>
        </div>
        -->
    </div>

</template>

<script>
import { onMounted, reactive } from '@vue/runtime-core'
export default {
    setup(){

        let stats = reactive({});
        
        onMounted(async () => {
            const url = '/api/public/getStats';
            const res = await fetch(url, {cache: "no-cache"});
            const data = await res.json();
            stats.escrows = data.escrows;
            stats.volume = data.volume;
            stats.volumeBTC = data.volumeBTC;
            stats.users = data.users;
            const ratesUrl = '/api/public/getRates';
            const resRates = await fetch(ratesUrl, {cache: "no-cache"});
            const dataRates = await resRates.json();
            stats.btc_usd = dataRates[6];
            stats.btc_cup = dataRates[7];
            stats.btc_mlc = dataRates[8];
        })

        return { stats };
    }
}
</script>

<style scoped>
    
</style>