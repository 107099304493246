<template>
    <h6 class="left-aling orange-text">Accelera con RBF</h6>
    <p class="grey-text text-darken-3 left-align">
        RBF le permite accelerar la confirmación, recomponiendo y repropagando la transacción con costos de envio (fee de mineria) mas elevados.
    </p>
    <p class="grey-text text-darken-3 left-align">
        <b class="orange-text">
        Dirección:<br>
        <span class = "green-text">{{ address }}</span>
        </b>
    </p>
  <div class = "grey darken-3 form-container">
    <form>
        
        <div class = 'range-field margin'>
            <label class = 'orange-text'>
                <b id = 'dynFee' class = 'white-text text-darken-2' style = 'font-size: 14px'>
                    <span class ="orange-text">Costos de Envio (Mineria):</span> {{ miningFee }}
                </b> sat/byte (<b class="white-text"> ~ {{ (((miningFee * 210) / 100000000) * btcXrate).toFixed(2) }} $</b> )
            </label>
            <input type='range' id='miningFee' :min='minimumFee' max='30' step = '1' v-model="miningFee" />
            <label class = 'white-text text-darken-2' style = 'font-size: 14px'><span class = 'orange-text'></span> (Fee Rapida <b class = 'white-text'>{{ roundedFee }}</b> sat/byte)</label>
        </div>

        <div class = "center-align margin">
            <Error :warning="error" v-if="error"/>
            <Loader v-if="isLoading" />
            <LargeButton 
                color="green" 
                icon="fab fa-bitcoin" 
                text="Accelera" 
                position="left"
                @clicked="triggerRBF"
            />
        </div>
        
    </form>
    </div>

    <teleport to="body">
            <FloatingButton 
                icon="fas fa-random" 
                @click="goBack"
            />

            <SignatureWarningModal
                :hex="hex"
                :address="store.state.escrow.escrow.buyerAddr"
                :miningFee="miningFee"
                :isActive="isActive" 
                @close="isActive = !isActive"
            />

    </teleport>
</template>

<script>

import Loader from '@/components/UI/Loader'
import Error from '@/components/UI/Error'
import FloatingButton from '@/components/UI/Buttons/FloatingButton'
import LargeButton from '@/components/UI/Buttons/LargeButton'
import SignatureWarningModal from '@/components/modals/SignatureWarningModal'
import { ref } from '@vue/reactivity';
import { computed, onMounted } from '@vue/runtime-core';
import { cutKey } from '@/composables/helpers'
import { post } from '@/composables/httpCalls'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    
    emits: ['goBack'],
    components: { Error, Loader, LargeButton, FloatingButton, SignatureWarningModal },
    
    setup(props, context){
        
        const { store, isLoading, isActive, error } = useBoilerplate(false);

        const miningFee = ref(null);
        const minimumFee = ref(null);
        const btcXrate = ref(0);
        const hex = ref(null);
        const address = computed(() => cutKey(store.state.escrow.escrow.buyerAddr, 4));

        const roundedFee = computed(() => {
            let fee = Math.round(store.state.escrow.bestFee / 10) * 10;
            if(fee < 1){
                fee = 1;
            }
            if(fee > 30){
                fee = 30;
            }
            return fee;
        })

        const goBack = () => context.emit('goBack');
        
        const triggerRBF = async () => {
            error.value = null;
            isLoading.value = true;
            try {
                
                const url = '/api/buildRBF'
                const vars = {
                    escrow: store.state.escrow.escrow._id,
                    miningFee: miningFee.value
                }

                const res = await post(url, vars);

                if(res.status == 200){
                    const myResponse = await res.json();
                    hex.value = window.finalSignature(myResponse.psbt, myResponse.encKey, 101);
                    return isActive.value = true;
                }else{
                    error.value ="Error";
                    isLoading.value = false;
                }
            } catch (err) {
                console.log(err);
                error.value = "Error de Conexion";
                isLoading.value = false;
            }
        }

        onMounted(() => {
            minimumFee.value = (store.state.escrow.escrow.miningFee / 210)+ 1;
            miningFee.value = (store.state.escrow.escrow.miningFee / 210)+ 2;
            btcXrate.value = JSON.parse(sessionStorage.xRates).BTC; 
        })

        return {
                    store, miningFee, minimumFee, 
                    address, roundedFee, btcXrate,
                    goBack, triggerRBF, error, isLoading, isActive , hex
                }; 
    }
}
</script>

<style scoped>
    .form-container{
        padding: 3px 15px;
        width: 100%;
    }
</style>