<template>
    <div class="col s12 center" >
        <h6>
            <b>Ultima Vez Activo: </b> {{ timeAgo }}
        </h6>
        <br>
    </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { timeDiff } from '@/composables/time'
export default {
    props: ['time'],
    setup(props) {
        const timeAgo = computed(() => props.time == "Dato No Disponible" ? props.time : timeDiff(props.time))
        return { timeAgo }
    }
}
</script>

<style scoped>

</style>