<template>
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
        <div class="row modal-content center white">
            <div class = "col s12 center-align orange white-text">
                <h5>Gestion de Bloqueo</h5>
            </div>
            <div class = "col s12 left-align grey-text text-darken-4">
                <p>Usted va {{ action }} a {{ counterparty }}. Haga click en el boton abajo para seguir.</p>
            </div>
            <div class = "col s12 center-align grey-text text-darken-4 margin">
                
                <Loader v-if="isLoading"/>
                <Error :warning="error" v-if="error"/>
              
            </div> 
            
            <LargeButton 
              :color="color" 
              :icon="icon" 
              :text="action" 
              position="left" 
              @clicked="manageBlock"
            />

        </div>
    </div>
</template>

<script>
import LargeButton from '@/components/UI/Buttons/LargeButton';
import Loader from '@/components/UI/Loader';

import Error from '@/components/UI/Error';
import { computed } from '@vue/runtime-core';
import { post } from '@/composables/httpCalls';
import { socket } from '@/composables/socket';
import useBoilerplate from '@/composables/useBoilerplate';


export default {

    props: ['isActive', 'counterparty'],
    components: { Loader, Error, LargeButton },
    emits: ['close'],
    
    setup(props, context){

        const {store, error, isLoading} = useBoilerplate(false);

        const action = computed(() => store.state.isBlocked == true ? ' Desbloquear' : 'Bloquear');
        const icon = computed(() => store.state.isBlocked == true ? 'fas fa-check' : 'fas fa-ban');
        const color = computed(() => store.state.isBlocked == true ? 'green' : 'red');

        const closeModal = () => {
            context.emit('close');
        }
        
        const manageBlock = async () => {
            isLoading.value = true;
            error.value = null;
            if(props.counterparty == 'QBita'){
                return;
            }
            try{
                const url = store.state.isBlocked == true ? '/api/unblockUser' : '/api/blockUser/';
                const vars = {
                    room: store.state.room,
                }
                const res = await post(url, vars);
                if(res.status == 200){
                    const myResponse = await res.json(); 
                    isLoading.value = false;
                    const html = "Se acaba de " + action.value + " el usuario " + props.counterparty;
                    M.toast({html});
                    store.state.isBlocked = myResponse.room.blocked;
                    store.state.blocker = myResponse.room.blocker;
                    error.value = null;
                    context.emit('close');
                    const data = {
                        counterparty: props.counterparty,
                        room: myResponse.room
                    }
                    socket.emit('blockage', data);
                }else{
                    isLoading.value = false;
                    error.value = "Error";
                }
            }catch(err){
                console.log(err);
                error.message = "Error!";
                isLoading.value = false;
            }
            
        }

        socket.on('blockage', data => {
            store.state.isBlocked = data.room.blocked;
            store.state.blocker = data.room.blocker;
        })

        return { isLoading, error, closeModal, action, color, icon, manageBlock };
    }
}
</script>