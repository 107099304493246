<template>
    <div class="row grey darken-1 marginTop">

        <div class="col s12 center orange">
            <h5 class="white-text">Todos Los Anuncios</h5>
        </div>

        <LateralNav />

        <div class="col s12 l8 center white">
            <br><br>
            <LargeButton 
                color="green"
                icon="fas fa-plus"
                text="Nuevo Anuncio"
                position="left"
                @click="router.push({name: 'NewAd'})"
            />

            <ZeroResults text="Anuncios" v-if="ads.length < 1" />

            <div v-for="(ad, index) in ads" :key="ad._id" >
                <SingleAd :ad="ad" :myAlias="myAlias" :index="index" @click="triggerDetails(ad)"/>
            </div>
            
        </div>

    </div>

    <teleport to="body">
        <AdDetailsModal :isActive="isActive" :ad="adData" @close="isActive=false" @reputation="triggerReputation" />
    </teleport>

    <teleport to="body">
        <ReputationModal :isActive="isActiveReputation" :user="userToBeChecked" @close="isActiveReputation = !isActiveReputation"/>
    </teleport>

</template>

<script>

import SingleAd from '@/components/Ads/SingleAd'
import AdDetailsModal from '@/components/modals/AdDetailsModal'
import ReputationModal from '@/components/modals/ReputationModal'
import LateralNav from '@/components/nav/LateralNav'
import LargeButton from '@/components/UI/Buttons/LargeButton'
import { onMounted, ref } from '@vue/runtime-core'
import { isVisible } from '@/composables/infinity'
import useBoilerplate from '@/composables/useBoilerplate';
import ZeroResults from '@/components/UI/ZeroResults'

export default {
    
    props: ["ads"],
    components: { LateralNav, SingleAd, AdDetailsModal, ReputationModal, LargeButton, ZeroResults },
    
    setup(){

        const { store, router, isActive, isActiveReputation } = useBoilerplate(false);

        const myAlias = sessionStorage.getItem('alias');
        const adData = ref({});
        const userToBeChecked = ref('');

        const infinityScrolling = async () => {
            const infinityTrigger = document.querySelector('.infinity');
            if(infinityTrigger){
                if(isVisible(infinityTrigger) == true ){
                    infinityTrigger.classList.remove('infinity');
                    await store.state.loadMore();
                }
            }
        }

        const triggerDetails = ad => {
            isActive.value = true;
            adData.value = ad;
        }

        const triggerReputation = (user) => {
            userToBeChecked.value = user;
            isActive.value=false;
            isActiveReputation.value=true;
        }

        onMounted(() => {
            window.addEventListener('scroll', () => {
                infinityScrolling();
            });
        })

        return { 
            store, 
            adData, 
            userToBeChecked, 
            isActive, 
            isActiveReputation,
            myAlias, 
            infinityScrolling, 
            triggerReputation, 
            triggerDetails,
            router
        };
    }
}

</script>

<style scoped>
    .l8{
        min-height: 75vh !important;
    }
</style>