<template>
  <div class = "container">
    
    <CallError v-if="!isLoading && error!=null" :warning="error"/>

    <LoadingFullPage v-if="isLoading && !error"/>

    <EscrowsList v-if="!isLoading && !error" :escrows="escrows" />

    <Loader v-if="store.state.isLoadingMore">
        <h5 class="orange-text left-align">Cargando...</h5>
    </Loader>

  </div>
</template>

<script>
import Loader from '@/components/UI/Loader'
import LoadingFullPage from '@/components/UI/LoadingFullPage'
import CallError from '@/components/UI/CallError'
import EscrowsList from '@/components/Escrows/EscrowsList'
import { ref } from '@vue/reactivity'
import { onMounted, watchEffect } from '@vue/runtime-core';
import useBoilerplate from '@/composables/useBoilerplate';

export default {
  
  components: { LoadingFullPage, Loader, EscrowsList, CallError },
  
  setup(){
    
    const { store, router, error, isLoading } = useBoilerplate(true);
    
    const escrows = ref([]);

    //Update ads list every time ads  changes in globalstate
    watchEffect(() => {
      escrows.value = store.state.escrows;
      store.state.setInfinity(escrows.value.length);
    })
    
    onMounted(async () => {
      
      M.AutoInit();
      store.state.reset('escrows');
      try{
        
        const url = "/api/getFirstEscrows";
        const res = await fetch(url, {cache: "no-cache"});
      
        //Handle Bad Response
        if(res.status == 300){
            return router.push({name: 'Welcome'});
        }else if(res.status == 401){
            return router.push({name: 'TwoFA'});
        }else if(res.status == 403){
            return router.push({name: 'Blocked'});
        }else if(res.status == 412){
            store.state.logUserOut();
            return router.push({name: 'Auth'});
        }else if(res.status == 200){ //Handles Successfull Response

            const data = await res.json();

            //Update Ads List
            store.state.loadFirst(data.escrows);
            return isLoading.value = false;

        }else{
            isLoading.value = false;
            return error.value = "Error De Sistema"
        }

      }catch(err){
        console.log(err);
        isLoading.value = false;
        return error.value = "Problema de Conexion"
      }

    })
    
    return { escrows, store, isLoading, error };
  }

}
</script>