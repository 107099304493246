<template>
  <p class = 'green-text left-align'>
    <i class = 'fas fa-exclamation-circle'></i> Compraventa Completada
  </p>

  <p class = "left-align" v-if="store.state.escrow.escrow.hashDelivery != ''">
    <a :href="url" target="_blank">
      Reastrear Bitcoin Entregados
    </a>
  </p>
  
  <div  v-if="store.state.alias == store.state.escrow.escrow.seller && store.state.escrow.escrow.reviewedSeller != true"
  >

    <p class='left-align'>
      Comparta con los demás usuarios su opinión sobre el Comprador
    </p>

    <br>

    <p class = 'center-align'>
      
      <LargeButton 
        text="Evalua"
        color="green"
        icon="fas fa-star"
        position="left"
        @clicked="goReview(store.state.escrow.escrow.buyer, store.state.escrow.escrow._id)"
      />

    </p>

  </div>

  <div  v-if="store.state.alias == store.state.escrow.escrow.buyer && 
          store.state.escrow.escrow.reviewedBuyer != true"
  >

    <p class='left-align'>
      Comparta con los demás usuarios su opinión sobre el Vendedor
    </p>

    <br>

    <p class = 'center-align'>
      
      <LargeButton 
        text="Evalua"
        color="green"
        icon="fas fa-star"
        position="left"
        @clicked="goReview(store.state.escrow.escrow.seller, store.state.escrow.escrow._id)"
      />
      
    </p>

  </div>

</template>

<script>
import LargeButton from '@/components/UI/Buttons/LargeButton'
import useBoilerplate from '@/composables/useBoilerplate';
import { computed } from '@vue/runtime-core';

export default {
  components: { LargeButton },
  setup(){
    
    const { store, router } = useBoilerplate(false);
    
    const goReview = (alias, escrow) => {
      const destination = {name: "Review", params: {alias, escrow}};
      router.push(destination);
    }

    const url = computed(() => `https://mempool.space/es/tx/${store.state.escrow.escrow.hashDelivery}`);

    return { goReview, store, url };
  }
}
</script>