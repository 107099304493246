<template>
  <div class="col s12 l6 box">
      <div class="col s12 white-text black">
          <h6>
              <b>{{tx.btc}} <i class="fab fa-btc orange-text"></i> ({{tx.fiat}} $) <i class = "fas fa-arrow-right green-text"></i> {{tx.price}}</b>
          </h6>

          <h6>
              <b>Hash: </b><br> <a :href="url" target="_blank">{{ cutKey(tx.hash, 7) }}</a>
          </h6>
          
          <h6>
              <b>Tasa de Cambio: </b> {{ tx.xchange }}
          </h6>

          <p class="right-align time orange-text">
              <b>Hace </b> {{ timeAgo }}
          </p>
          
      </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { cutKey } from '@/composables/helpers'
import { timeDiff } from '@/composables/time'

export default {
    props: ['tx'],
    setup(props){
        const url = computed(() => `https://mempool.space/es/tx/${props.tx.hash}`);
        const timeAgo = computed(() => timeDiff(props.tx.timestamp))
        return { url, cutKey, timeAgo }
    }
}
</script>

<style scoped>
    .black{
        padding-top: 15px !important;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .box{
        margin-bottom: 10px !important;
    }
    .fa-arrow-right{
        font-size: 18px;
    }
    .time{
        font-style: italic;
        font-size: 14px;
    }
</style>