<template>
    <div class="col s12 l8 left-align grey darken-2">
        <form @submit.prevent="previewAd">
            <div class="col s12 input-type">
              <p>
                <label>
                  <input v-model='opType' type="radio" value = "Vendo" checked/>
                  <span class = "white-text">Quiero Vender Bitcoin <a href='https://youtu.be/C0wOC29acKo' target='_blank' class = "light-blue-text text-lighten-2">(tutorial)</a></span>
                </label>
              </p>
              <p>
                <label>
                  <input v-model='opType' type="radio" value = "Compro"/>
                  <span class = "white-text">Quiero Comprar Bitcoin <a href='https://youtu.be/giOWLSV3fjc' target='_blank' class = "light-blue-text text-lighten-2">(tutorial)</a></span>
                </label>
              </p>
            </div>
            <div class="input-field col s12">
              <Select @changed="handleProvince">
                <option value="">Seleccionar Provincia</option>
                <option value="Toda Cuba">Toda Cuba</option>
                <option value="Artemisa y Mayabeque">Artemisa y Mayabeque</option>
                <option value="Camaguey">Camaguey</option>
                <option value="Ciego de Avila">Ciego de Avila</option>
                <option value="Cienfugos">Cienfuegos</option>
                <option value="Granma">Granma</option>
                <option value="Guantanamo">Guantanamo</option>
                <option value="Habana">Habana</option>
                <option value="Holguin">Holguin</option>
                <option value="Isla de la Juventud">Isla de la Juventud</option>
                <option value="Las Tunas">Las Tunas</option>
                <option value="Matanzas">Matanzas</option>
                <option value="Pinar del Rio">Pinar del Rio</option>
                <option value="Sancti Spiritus">Sancti Spiritus</option>
                <option value="Santiago de Cuba">Santiago de Cuba</option>
                <option value="Villa Clara">Villa Clara</option>
              </Select>
            </div>
            
            <div class = "col s12 input-field">
                <input type = 'text' v-model='amount' required>
                <label for="monto">Cuantidad de Bitcoin (en USD)</label>
            </div>
            
            <div class="input-field col s12">
              <Select @changed="handleDivisa">
                <option value="">Seleccionar Divisa</option>
                <option value="cubano">CUP</option>
                <option value="MLC">MLC</option>
              </Select>
            </div>

            <div class="range-field col s12" style= 'margin-bottom: 20px;' v-if="divisa=='cubano' || divisa == 'CUP'">
              <h6 class = "white-text">Tasa de Cambio: <b class = "orange-text">{{ margen }} CUP</b> por 1 USD</h6>
              <input type="range" v-model="margen" :min="xChangeData.minMarginCUP" :max="xChangeData.maxMarginCUP" :step="1"/>
              <p class="white-text right-align">Tasa promedia en QBita: <b>{{xChangeData.cup}}</b> por $</p>
            </div>

            <div class="range-field col s12" style= 'margin-bottom: 20px;' v-if="divisa=='MLC'">
              <h6 class = "white-text">Tasa de Cambio: <b class = "orange-text">{{ margen }} MLC</b> por 1 USD</h6>
              <input type="range" v-model="margen" :min="xChangeData.minMarginMLC" :max="xChangeData.maxMarginMLC" :step="0.01"/>
              <p class="white-text right-align">Tasa promedia en QBita: <b>{{xChangeData.mlc}}</b> por $</p>
            </div>

            <div class="input-field col s12">
              <Select @changed="handlePayment">
                <option value="">Metodo de Pago</option>
                <option value="Trasferencia Bancaria - TransferMovil / EnZona">Trasferencia Bancaria - TransferMovil / EnZona</option>
                <option value="Efectivo">Efectivo</option>
              </Select>
            </div>

            <div 
              class="input-field col s12"
              v-if="payMethod=='Trasferencia Bancaria - TransferMovil / EnZona' && opType=='Vendo'"
            >
              <Select 
                @changed="handleCard"
              >
                <option v-for="(card, index) in cards" :key="card._id" :value="card._id">{{card.cardNumber}} <span v-if="index > 1">{{"(" + card.currency + ")"}}</span></option>
              </Select>
            </div>
            
            <div class = "col s12 center-align">
              
              <Error :warning="error" v-if="!isActive && error!=null" />
              
              <button class = "btn orange white-text green-text margin">
                <i class = 'fas fa-check'></i>
              </button>
            
            </div>
        </form>
    </div>

    <teleport to="body">
      <AdPreviewModal v-if="isActive" :ad="userInputs" :isActive="isActive" @close="isActive=false" @success="renderSuccess"/>
    </teleport>

</template>

<script>

import LoadingFullPage from '@/components/UI/LoadingFullPage'
import Select from '@/components/UI/Select';
import Error from '@/components/UI/Error';
import AdPreviewModal from '@/components/modals/AdPreviewModal';
import AdSuccess from '@/components/Ads/AdSuccess';
import NewAdForm from '@/components/Ads/NewAdForm';
import Success from '@/components/UI/Success';
import LateralNav from '@/components/nav/LateralNav'
import useBoilerplate from '@/composables/useBoilerplate';
import useNewAd from '@/composables/useNewAd'
import { onMounted } from '@vue/runtime-core';

export default {
  
    components: { 
        Select, Error, AdPreviewModal, Success, AdSuccess, 
        LateralNav, LoadingFullPage, NewAdForm
    },
    props: ['allCards', 'xChangeData'],
    emits: ['success'],
    setup(props, context){

        const { error, isActive ,isSuccess, isLoading, router } = useBoilerplate(); 
        
        const {
            provincia, payMethod, divisa, margen, cards,
            amount, opType, userInputs,
            previewAd , handleCard,
            handleProvince, handlePayment,
        } = useNewAd(error, isActive, isSuccess, isLoading, router);

        const renderSuccess = () => {
            margen.value = props.xChangeData.cup;
            amount.value = null,
            opType.value = 'Vendo';
            isActive.value = false;
            return context.emit('success');
        }

        const handleDivisa = value => {
            divisa.value = value;
            console.log(props.xChangeData);
            if(divisa.value == "cubano"){
                margen.value = Math.round(props.xChangeData.cup);
            }else{
                margen.value = Number(props.xChangeData.mlc);
            }
        }

        onMounted(() => {
            cards.value = [...cards.value, ...props.allCards];
            console.log("CARDS in Form: ", cards.value);
            margen.value = props.xChangeData.margen;
            console.log("xChangeData");
            console.log(props.xChangeData)
        });

        return {
            opType, margen, amount, provincia, divisa,
            payMethod, previewAd, userInputs, renderSuccess,
            handleDivisa, handleProvince, handlePayment,
            error, isActive, cards, handleCard,
        }
    
    }

}

</script>

<style scoped>
  
</style>