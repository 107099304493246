<template>
    <div class="col l4 hide-on-med-and-down grey darken-1" style ="padding: 20px">
        <div class="col s12 grey darken-1">
            <router-link :to="{name: 'Exchange'}" class = "white-text lateral-item"><i class = "fas fa-bullhorn"></i> Anuncios</router-link>
            <router-link :to="{name: 'NewAd'}" class = "white-text lateral-item"><i class = "fas fa-plus"></i> Nuevo Anuncio</router-link>
            <router-link :to="{name: 'WalletBTC'}" class = "white-text lateral-item wallet" ><i class = "fas fa-wallet"></i> Monedero</router-link>
            <router-link :to="{name: 'AllRooms'}" class = "white-text lateral-item chats" ><i class = "fas fa-comment-dots"></i> Chats</router-link>
            <router-link :to="{name: 'AllEscrows'}" class = "white-text lateral-item escrows"><i class = "fas fa-random"></i> Compraventas</router-link>
            <router-link :to="{name: 'Settings'}" class = "white-text lateral-item"><i class = "fas fa-cog"></i> Ajustes</router-link>
            
            <router-link 
                :to="{name: 'AdminPanel'}" 
                class = "white-text lateral-item"
                v-if="store.state.isAdmin"
            >
                <i class = "fas fa-users-cog"></i> Admin
            </router-link>

            <router-link :to="{name: 'Tutorials'}" 
                class = "white-text lateral-item"
            >
                <i class = "fas fa-graduation-cap"></i> Tutoriales
            </router-link>

            <router-link :to="{name: 'Stats'}" 
                class = "white-text lateral-item"
            >
                <i class = "far fa-chart-bar"></i> Datos
            </router-link>

            <a 
                class = "white-text lateral-item"
                @click="goChat('QBita', store.state.alias, router)"
            >
                <i class = "fas fa-envelope"></i> Contactos
            </a>
            <router-link :to="{name: 'FAQ'}" class = "white-text lateral-item"><i class = "fas fa-question"></i> Preguntas</router-link>
        </div>
    </div>
</template>

<script>

import { onMounted } from '@vue/runtime-core'
import { isItBlinking } from '@/composables/blink'
import { goChat } from '@/composables/goChat'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    setup(){
        
        const { store, router } = useBoilerplate(false);

        onMounted(async () => {
            setTimeout(()=> isItBlinking(), 1500);
        });
        return { store, router, goChat }
    }
}
</script>

<style scoped>
    .lateral-item{
        padding: 5px;
        padding-left: 8px;
        display: block;
        font-size: 16px;
        margin-bottom: 20px;
        max-width: 170px;
        max-height: 510px;
        cursor: pointer;
        transition: all .2s ease-in-out; 
    }

    .lateral-item:hover{
        transform: scale(1.02);
        background-color: orange !important;
    }

    .lateral-item.pulse{
        
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        transform: scale(1);
        animation: pulse 1.8s infinite;
    }

    @keyframes pulse {
	0% {
		transform: scale(1);
        
	}

	50% {
		transform: scale(1);
	
		box-shadow: 0 0 0 1px greenyellow;
	}

}
</style>