<template>

  <div 
    v-if="store.state.escrow.escrow.seller == store.state.alias"
  >
    
    <p class = 'left-align'>
      Confirme que Usted recibió 
      <b class = 'orange-text'>{{store.state.escrow.escrow.toBePaid}}</b>
      haciendo click en el botón verde
    </p>

    <div>
      <Error :warning="error" v-if="error" />
      <Loader v-if="isLoading" />
    </div>
    
    <p class = 'center-align marginTop'>
      <LargeButton 
        color="green"
        icon="fab fa-bitcoin"
        position="right"
        text="Entrega"
        @clicked="sellerSigns"
      />
    </p>
  
  </div>

  <div 
    v-if="store.state.escrow.escrow.buyer == store.state.alias"
  >
    
    <p class = 'left-align grey-text text-darken-3'>
      Espere que el vendedor confirme la recepción de los 
      <b class = 'orange-text'>{{ store.state.escrow.escrow.toBePaid}}</b>, 
      para que los <b class = 'orange-text'>Bitcoin</b> sean entregados a su monedero.
    </p>
      
  </div>

  <teleport to="body">
        
    <SignatureWarningModal
        signedTX=""
        :hex="hex"
        address=""
        miningFee=""
        :fiatString="store.state.escrow.escrow.toBePaid"
        :isActive="isActive" 
        @close="isActive=false"
    />

  </teleport>

</template>

<script>

import { ref } from '@vue/runtime-core';
import LargeButton from '@/components/UI/Buttons/LargeButton'
import Loader from '@/components/UI/Loader'
import Error from '@/components/UI/Error'
import { post } from '@/composables/httpCalls'
import SignatureWarningModal from '@/components/modals/SignatureWarningModal'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
  
  components: { LargeButton, Error, Loader, SignatureWarningModal },
  
  setup(){
    
    const { store, error, isLoading, isActive } = useBoilerplate(false);

    const hex= ref(null);
    
    const sellerSigns = async () => {
      isActive.value = false;
      error.value = null;
      isLoading.value = true;
      try{
        const url = '/api/releaseBTCbuild';
        const vars = {id: store.state.escrow.escrow._id};
        const res = await post(url, vars);
        if(res.status == 500){
          isLoading.value = false;
          return error.value = 'Error!';
        }
        if(res.status == 401){
          isLoading.value = false;
          return error.value = 'Usted No está autorizado. Vuelva a Loguearse de nuevo.';
        }
        if(res.status == 200){
          isLoading.value = false;
          const myResponse = await res.json();
          hex.value = window.finalSignature(myResponse.psbt, myResponse.encKey, 101);
          if(hex.value != false && hex.value != 'keyError'){
            return isActive.value = true;
          }
          if(hex.value == false){
            return error.value = 'La Clave de Cifrado No Es Correcta';
          }
          if(hex.value == 'keyError'){
            return error.value = 'La Semilla No Es Correcta';
          }
          
        }
      }catch(err){
        error.value = "Error de Conexion"
        return isLoading.value = false;
      }
      
    }
    return { store, sellerSigns, isActive, hex, error, isLoading };
  }
}
</script>
