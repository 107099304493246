<template>
    <div class = 'col s12 grey darken-2'>
        <ul class = "tabs">
            <li class="tab col s6 center-align active">
                <a href="#logInTab" class="white-text grey darken-1">Entrar</a>
            </li>
            <li class="tab col s6 center-align">
                <a href="#signUpTab" class="white-text grey darken-1">Registrarse</a>
            </li>
        </ul>
    </div>
</template>