<template>
    <div class="row marginTop">
        <div class="col s12 center">
            <br><br><br><br><br><br>
            <h4 class="orange-text marginTop">
                {{ warning }}
            </h4>
        </div>
    </div>
</template>

<script>
export default {
    props: ['warning']
}
</script>
<style scoped>
    .s12{
        height: 400px;
    }
</style>