<template>
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
        <div class="row pic-modal-content center white">
            <img :src="picUrl">            
        </div>
    </div>
</template>

<script>
import { computed } from '@vue/runtime-core';
import { formatPicUrl } from '@/composables/pictures'

export default {

    props: ['isActive', 'url'],
    emits: ['close'],
    setup(props, context){
        const closeModal = () => context.emit('close');
        const picUrl = computed(() => formatPicUrl(props.url));
        return { closeModal, picUrl };
    }
}
</script>

<style scoped>
    .pic-modal-content{
        margin: 100px auto;
        width: 95%;
        max-width: 400px;
        max-height: 450px;
        border-radius: 20px;
        padding: 10px;
    }

    img{ 
        width: 90%;
        max-height: 400px; 
    }
</style>