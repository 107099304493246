<template>
    <router-link :to="{name: 'Escrow', params: {id: escrow.id}}">
        <div 
            class = "col s12 valign-wrapper z-depth-3 valign-wrapper marginTop infinityCard" 
            :class="{unseen: escrow.seen !== true && escrow.user !== myAlias}"
        >

            <Presence :alias="escrow.counterParty" :lastOnline="escrow.lastOnline" :url="picUrl" :index="index"/>

            <div class = "col s8 left-align grey-text text-darken-2">

                <h6 class = "truncate" v-if="escrow.buyer==myAlias">
                    <b class="red-text"> 
                        <i class="fas fa-arrow-down"></i> COMPRA <i class="fas fa-arrow-down"></i> 
                    </b>
                </h6>

                <h6 class = "truncate" v-if="escrow.seller==myAlias">
                    <b class="green-text"> 
                        <i class="fas fa-arrow-up"></i> VENTA <i class="fas fa-arrow-up"></i> 
                    </b>
                </h6>
                
                <p>
                    <b>Monto: </b> <br>
                    <span v-if="escrow.buyer==myAlias">{{escrow.btc4Buyer}} </span>
                    <span v-if="escrow.seller==myAlias">{{escrow.btc4Seller}} </span>
                    <i class="fab fa-bitcoin orange-text" style="margin-left: 5px"></i> por {{escrow.fiat}}<br>
                </p>
                
                <p>
                    <b>Estado:</b> <br>{{escrow.stage}}
                </p>
            
            </div>

        </div>
    </router-link>
    
</template>

<script>
import Presence from '@/components/UI/Presence'
import { computed } from '@vue/runtime-core'
import { formatPicUrl } from '@/composables/pictures'
import useBoilerplate from '@/composables/useBoilerplate';

export default {

    components: { Presence },
    props: ['escrow', 'index', 'myAlias'],
    
    setup(props){
        
        const { store } = useBoilerplate(false);

        const picUrl = computed(() => formatPicUrl(props.escrow.counterPartyPic));

        return { store, picUrl };
    }
}
</script>

<style scoped>
    .left-align{
        margin-left: 15% !important;
    }
    .unseen{
        border: 8px solid yellowgreen;
    }
</style>