<template>
    <div class="row grey darken-1 marginTop">

        <div class="col s12 center orange">
            <h5 class="white-text">Todas Las Compraventas</h5>
        </div>

        <LateralNav />

        
        <div class="col s12 l8 white">

            <ZeroResults text="Compraventas" v-if="escrows.length < 1"/>

            <div v-for="(escrow, index) in escrows" :key="escrow._id" >
                <SingleEscrow :escrow="escrow" :myAlias="myAlias" :index="index" />
            </div>
            
        </div>
    
    </div>
</template>

<script>

import SingleEscrow from '@/components/Escrows/SingleEscrow'
import { onMounted } from '@vue/runtime-core';
import { isVisible } from '@/composables/infinity';
import LateralNav  from '@/components/nav/LateralNav';
import useBoilerplate from '@/composables/useBoilerplate';
import ZeroResults from '@/components/UI/ZeroResults'

export default {
    props: ["escrows"],
    components: { SingleEscrow, LateralNav, ZeroResults },
    
    setup(){

        const {store} = useBoilerplate(false);
        
        const myAlias = sessionStorage.getItem('alias');

        const infinityScrolling = async () => {
            const infinityTrigger = document.querySelector('.infinity');
            if(infinityTrigger){
                if(isVisible(infinityTrigger) == true ){
                    infinityTrigger.classList.remove('infinity');
                    await store.state.loadMore();
                }
            }
        }

        onMounted(() => {
            window.addEventListener('scroll', () => {
                infinityScrolling();
            });
        })

        return { store, myAlias, infinityScrolling };
    }
}

</script>

<style scoped>
    .l8{
        min-height: 75vh !important;
    }
</style>