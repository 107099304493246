<template>
    <div class="card z-depth-1 left-align">
        <h5 class="right-align"><b class="pill orange white-text">{{ card.currency }}</b></h5>
        <br>
        <h6>
            # Tarjeta:<br>
            <b class="larger orange-text">{{card.cardNumber}}</b></h6>
        <h6>
            # SMS de Confirmación:<br>
            <b class="larger orange-text">{{card.phone && card.phone.length > 2 ? card.phone : "No Envie SMS"}}</b>
        </h6>
        <br>
        <p class="center-align" v-if="route.path.includes('/settings')">
            <b @click="deleteCard" class="btn-large red white-text delBTN">
                <i class="fas fa-trash"></i> Eliminar
            </b>
        </p>
    </div>
</template>

<script>
import { post } from '@/composables/httpCalls'
import useBoilerplate from '@/composables/useBoilerplate'
export default {
    props: ['card'],
    //emits: ['deleted'],
    setup(props, context){

        const { route, store } = useBoilerplate();

        const deleteCard = async () => {
            try{
            const vars = {id: props.card._id}
            const url = "/api/deleteCard";
            const res = await post(url, vars);
            if(res.status == 200){
                return store.state.removeCard(props.card._id);
                //context.emit("deleted", props.card._id);
            }else if(res.status == 404){
                return M.toast({html: "Esta Tarjeta No Existe"});
            }else{
                console.log(error);
                return M.toast({html: `Error ${res.status}. No Se Pudo Eliminar la Tarjeta`});
            }
            }catch(error){
                console.log(error);
                return M.toast({html: "No Se Pudo Eliminar la Tarjeta"});
            }

        }
        return { deleteCard, route }
    }
}
</script>

<style scoped>
    .delBTN{
        border-radius: 20px;
    }
    .larger{
        margin-top: 7px !important;
        padding-left: 5px !important;
        font-size: 24px;
        display: block;
    }
    .pill{
        border-radius: 30px;
        padding: 10px !important;
    }
    .card{
        border-radius: 15px;
        width: 95%;
        margin: 20px auto !important;
        padding: 15px !important;
    }
    @media screen and (min-width: 700px) {
        h6{
            padding-left: 10%;
        }
        .larger{
            font-size: 36px;
            margin-bottom: 25px;
            margin-top: 10px !important;
        }
    }
   
</style>