<template>
  <div class="row modal-content center grey darken-3">
      <div class="col s12 center">
            <h1>
                <i class="fas fa-check green-text"></i>
            </h1>
            <h5 class = "white-text marginBottom">
                Nueva Compraventa Creada
                <br><br> 
                <span class = 'green-text'>{{ id }}</span>
            </h5>
        
            <LargeButton color="orange" icon="" text="Revisa" position="none" @clicked="escrowCheck"/>

      </div>
  </div>
</template>

<script>
import LargeButton from '@/components/UI/Buttons/LargeButton'
import { useRouter } from 'vue-router'
export default {
    components: {LargeButton}, 
    props: ['id'],
    setup(props){
        const router = useRouter();
        const escrowCheck = () => {
            router.push({name: "Escrow", params: {id: props.id}})
        }
        return { escrowCheck }
    }
}
</script>

<style>

</style>