<template>
  <div class = "col s12 center-align orange white-text" >
        <h5>Compra Segura</h5>
    </div>
    <div class = "col s12">
        <p class = 'left-align white-text'>
            Usted va a comprar <b> {{ sat }} </b> (menos fee).
        </p>
        <p class = 'left-align white-text'>
            Pagarà <b> {{ fiatString }}</b> <b class = 'orange-text'>SOLO DESPUES</b> que el vendedor haya enviado los Bitcoin a la dirección escrow
        </p>
    </div>
</template>

<script>
export default {
    props: ['sat', 'fiatString']
}
</script>

<style>

</style>