<template>
    <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
        <div class="row modal-content center white">
            <div class = "col s12 center-align orange-text">
                <h2>
                    <i class = "fas fa-exclamation-circle red-text"></i>
                </h2>
                <h5 class = "red-text">Elimina Usuario</h5>
            </div>
            <div class = "col s12 left-align grey-text text-darken-4">
                <p>Vas a eliminar el usuario <b class="orange-text">{{ alias }}</b></p>
                <p>Esta Accion NO es reversible</p>
                <p><b>Seguro que quiere eleiminar el Usuario?</b></p>
            </div>
            <div class = "col s12 center-align grey-text text-darken-4 margin">
                
                <Loader v-if="isLoading"/>
                <Error :warning="error" v-if="!isLoading && error!=null"/>
              
            </div> 
            
            <LargeButton 
              color="red" 
              icon="fa fa-trash" 
              text="Elimina" 
              position="left" 
              @clicked="deleteUser"
            />

        </div>
    </div>
</template>

<script>
import LargeButton from '@/components/UI/Buttons/LargeButton';
import Loader from '@/components/UI/Loader';
import Error from '@/components/UI/Error';
import { ref } from '@vue/reactivity';
import { post } from '@/composables/httpCalls';
import useBoilerplate from '@/composables/useBoilerplate';

export default {

    props: ['isActive', 'alias'],
    components: { Loader, Error, LargeButton },
    emits: ['close'],
    
    setup(props, context){

      const { error, isLoading } = useBoilerplate(false);

        const closeModal = () => {
            context.emit('close');
        }

        const deleteUser = async () => {

          error.value = null;
          isLoading.value = true;

          try{
        
            const url = "/api/deleteUser";
            const vars = {alias: props.alias};
            const res = await post(url, vars);
            
            if(res.status == 409){
              isLoading.value = false;
              return error.value = "Usted No Esta Autorizado a eliminar el Usuario";
            }else if(res.status == 200){
              isLoading.value = false;
              M.toast({html: "Usuario Eliminado"});
              return context.emit('close');
            }else{
              isLoading.value = false;
              return error.value = "No Se Pudo Cancelar el Usuario";
            }

          }catch(err){
            console.log(err);
            isLoading.value = false;
            return error.value = "No Se Pudo Cancelar el Usuario";
          }
        }

        return { isLoading, error, closeModal, deleteUser };
    }
}
</script>