<template>
    <div class="input-field col s12 m6 l7 center">
        <input type = "text" id="BTC" @keyup="noMax" v-model="btc" required>
        <label for = "BTC" class = 'grey-text'><i class="fab fa-bitcoin"></i> <span>Monto en BTC:</span></label>
        <h6 class="left-align orange-text"  @click="setMax"><b>MAX</b></h6>
    </div>
    <div class="col s12 m6  l5 center">
        <h5 class = "grey-text text-lighten-1"><b>$ {{ usd }}</b></h5>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core';
import { convertBTC } from '@/composables/ratesX'

export default {

    props: ['xChange', 'max'],
    emits: ['typed', 'max', 'noMax'],

    setup(props, context){

        const btc = ref(null);
        const usd = ref(0);
        const isMax = ref(false);

        const setMax = () => {
            isMax.value = true;
            btc.value = props.max;
            usd.value = convertBTC(btc.value, props.xChange);
            document.getElementById("BTC").focus();
            context.emit('max');
        };

        const noMax = () => {
            isMax.value = false;
            context.emit('noMax');
        }

        watch(()=> btc.value, () => {
            usd.value = convertBTC(btc.value, props.xChange);
            context.emit('typed', btc.value);
        });

        return { btc, usd, setMax, isMax, noMax }
    }

}
</script>

<style scoped>

</style>