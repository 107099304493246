<template>
<div class="container">
    
    <br>
    <Data />
    <Ticker />
    <LastTXS />
    <FloatingButton icon="fas fa-arrow-left" @click="goBack"/>
    
</div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core';
import Ticker from '@/components/Stats/Ticker';
import LastTXS from '@/components/home/LastTXS';
import Data from '@/components/Stats/Data';
import useBoilerplate from '@/composables/useBoilerplate';
import FloatingButton from '@/components/UI/Buttons/FloatingButton'

export default {
    components: { Ticker, FloatingButton, LastTXS, Data },
    setup(){
        
        const { store, router } = useBoilerplate(false);
        const data = ref({
            users: 22222,
            btcVolume: 39.343434,
            fiatVolume: 14738473847
        })
        const goBack = () => router.go(-1);
        
        onMounted(() => {
            M.AutoInit();
        })
        
        return { store, goBack, data }
    }
}
</script>

<style scoped>
    .container{
        width: 100%;
        padding: 0 !important;
    }
    .row{
        padding: 0 !important;
    }
</style>