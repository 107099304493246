<template>

    <p><b>Estado:</b> {{ store.state.escrow.escrow.stage }}</p>

    <p>
        <b>Tipo de Operacion: 
            <span class = 'green-text' v-if="store.state.alias==store.state.escrow.escrow.seller">Venta</span>
            <span class = 'red-text' v-else>Compra</span>
        </b>
    </p>

    <p v-if="store.state.alias==store.state.escrow.escrow.seller">
        <b>Comprador: </b> 
        <span 
            class="blue-text blockchain" 
            @click="triggerReputation(store.state.escrow.escrow.buyer)"
        >
            {{ store.state.escrow.escrow.buyer }}
        </span>
    </p>

    <p v-if="store.state.alias==store.state.escrow.escrow.buyer">
        <b>Vendedor: </b> 
        <span 
            class="blue-text blockchain" 
            @click="triggerReputation(store.state.escrow.escrow.seller)"
        >
            {{ store.state.escrow.escrow.seller }}
        </span>
    </p>

    <p><b>Monto a Pagar: </b>{{store.state.escrow.escrow.toBePaid}}</p>

    <p><b>Monto BTC: </b>
        <span v-if="store.state.alias==store.state.escrow.escrow.seller">
            {{ store.state.escrow.escrow.btc4Seller }}
        </span>
        <span v-if="store.state.alias==store.state.escrow.escrow.buyer">
            {{ store.state.escrow.escrow.btc4Buyer }}
        </span>
        (fees de compraventa incluidas).
    </p>
    <p v-if="store.state.escrow.escrow.xchange != null">
        <b>Tasa de Cambio: </b>{{ store.state.escrow.escrow.xchange}} por 1 USD
    </p>
    <p v-else>
        <b>Margen del Vendedor: </b>{{ store.state.escrow.escrow.margin}}%
    </p>
    
    <p v-if="buyerAddrShown">
        <b>Dirección del comprador: </b><br> 
        <a :href="recieverLink" target="_blank" class="blue-text blockchain">{{ buyerAddrShown }}</a>
    </p>

    <p>
        <b>Dirección del MutiFirma: </b><br> 
        <a :href="escrowLink" target="_blank" class="blue-text blockchain">{{ escrowAddr }}</a>
    </p>

    <p v-if="hashDeposit">
        <b>Hash Deposito: </b><br> 
        <a :href="depositLink" target="_blank" class="blue-text blockchain">{{ hashDeposit }}</a>
    </p>

    <p v-if="hashDelivery">
        <b>Hash Entrega: </b><br> 
        <a :href="deliveryLink" target="_blank" class="blue-text blockchain">{{ hashDelivery }}</a>
    </p>

    <teleport to="body">
        <ReputationModal 
            :isActive="isActiveReputation" 
            :user="userToBeChecked" 
            @close="isActiveReputation = !isActiveReputation"
        />
    </teleport>

</template>

<script>
import { computed, ref } from '@vue/runtime-core'
import ReputationModal from '@/components/modals/ReputationModal'
import { cutKey } from '@/composables/helpers'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { ReputationModal },
    setup(){

        const { store, isActiveReputation } = useBoilerplate(false);

        const baseUrl = "https://mempool.space/es";
        
        const buyerAddrShown = computed(() => cutKey(store.state.escrow.escrow.buyerAddrShown, 4))
        const escrowAddr = computed(() => cutKey(store.state.escrow.escrow.escrowAddr, 4))
        const hashDeposit = computed(() => cutKey(store.state.escrow.escrow.hash, 7))
        const hashDelivery = computed(() => cutKey(store.state.escrow.escrow.hashDelivery, 7))
        
        const recieverLink = computed(() => baseUrl + "/address/" +store.state.escrow.escrow.buyerAddrShown)
        const escrowLink =computed(() => baseUrl + "/address/" +store.state.escrow.escrow.escrowAddr);
        const depositLink = computed(() => baseUrl + "/tx/" + store.state.escrow.escrow.hash);
        const deliveryLink =  computed(() => baseUrl + "/tx/" + store.state.escrow.escrow.hashDelivery);        
        const userToBeChecked = ref('');    

        const triggerReputation = user => {
            userToBeChecked.value = user;
            isActiveReputation.value=true;
        }

        return {    store, buyerAddrShown, escrowAddr, hashDeposit, hashDelivery, 
                    recieverLink, escrowLink, depositLink, deliveryLink, 
                    triggerReputation, isActiveReputation, userToBeChecked
                }
    }
}
</script>

<style scoped>
    b{color: orange;}
    .blockchain{
        font-weight: bold;
        cursor: pointer;
    }
</style>