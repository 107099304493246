<template>
  <div 
    v-if="store.state.escrow.escrow.seller == store.state.alias"
  >
    <p class = 'left-align grey-text text-darken-3'>
      Envie los Bitcoin al multifirma. Es muy importante enviar <b class = "red-text">EXACTAMENTE</b> la cuantidad de satoshi indicada a continuacion.
    </p>

    <p class = "center-align">
      <canvas id="qrCanvas" width="300" height="300"></canvas>
    </p>
    
    <p class = 'orange-text center-align' id = 'pubAddress'>
      <span class = "orange-text left-align copySpan">Dirección</span><br>
      <b class ="copy white-text grey darken-3 left-align">{{ store.state.escrow.escrow.escrowAddr }}</b>
    </p>

    <p class = 'orange-text center-align' id = 'pubAddress'>
      <span class = "orange-text left-align copySpan">Monto</span><br>
      <b class="copy white-text grey darken-3 left-align">{{ store.state.escrow.escrow.btc4Seller }}</b>
    </p>

    <h5 @click="useQbita" class = "center-align">
      <span class="light-green white-text useQbita">Usar Saldo QBita</span>
    </h5>
    
  
  </div>

  <div 
    v-if="store.state.escrow.escrow.buyer == store.state.alias"
  >

    <p class = 'left-align grey-text text-darken-3'>
      Espere que el vendedor envie los Bitcoin a la dirección escrow multiSig abajo:</p>
    <p class = 'center-align orange-text'>
      <b>{{ multiSig }}</b>
    </p>
  
  </div>

</template>

<script>
import LargeButton from '@/components/UI/Buttons/LargeButton'
import { computed, onMounted } from '@vue/runtime-core'
import { cutKey } from '@/composables/helpers'
import { showMeQR } from '@/composables/qr'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
  components: { LargeButton },
  setup(){

    const { store, router } = useBoilerplate(false);
    
    const multiSig = computed(() => cutKey(store.state.escrow.escrow.escrowAddr, 4));
    
    const useQbita = () => {
      const obj = {
        address: store.state.escrow.escrow.escrowAddr,
        amount: store.state.escrow.escrow.btc4Seller
      }
      sessionStorage.setItem('escrowSendTX', JSON.stringify(obj));
      return router.push({name: "SendBTC2Escrow"})
    }
    
    onMounted(() => {
      if(store.state.escrow.escrow.seller == store.state.alias){
        const fullQR = store.state.escrow.escrow.escrowAddr+'?amount='+store.state.escrow.escrow.btc4Seller;
        showMeQR(fullQR, "qrCanvas", "true");
      }
    });

    return { store, multiSig, useQbita }
  
  }
}
</script>

<style scoped>
  .copy{
    width: 100%;
    max-width: 290px !important;
    display: inline-block;
    font-size: 12px;
    padding: 5px;
  }
  .copySpan{
    width: 100%;
    max-width: 290px !important;
    display: inline-block;
    font-size: 12px;
    padding: 5px;
    font-weight: bold;
  }

  .useQbita{
    border-radius: 15px !important;
    display: inline-block;
    padding: 20px !important;
    margin: 15px !important;
    cursor: pointer;
    max-width: 230px !important;
  }

  @media screen and (max-width: 335px) {
    .copy{
      width: 100%;
      max-width: 290px !important;
      display: inline-block;
      font-size: 11px;
      padding: 5px;
    }
  }
</style>