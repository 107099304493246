<template>
    <div class="row">
        <div class="col s10 offset-s1">
            <h4 class = 'orange-text'>
                Por Qué Bitcoin en Cuba?
            </h4>
        </div>
        <div class="col s10 offset-s1">
            <p class ='white-text text-darken-2 bigText'>
                <b>Bitcoin en Cuba</b> es una verdadera necesidad, para mitigar los problemas de exclusión financiera que sufre el pueblo Cubano por causa del Bloqueo económico y comercial impuesto de forma unilateral e ilegal por EE.UU.
            </p>
            <p class ='white-text text-darken-2 bigText'>
                Como consecuencia directa de estas sanciones, en el año  2019 todavía los Cubanos no tienen acceso a muchos servicios financieros modernos como por ejemplo Visa, Mastercard, AmericanExpress, PayPal, Skrill, Stripe ni otras pasarelas de pagos electrónicos internacionales.   
            </p>
            <p class ='white-text text-darken-2 bigText'>
                El desarrollo del Exchange Bitcoin QBita fue inspirado por la resistencia heróica del pueblo Cubano, que enfrenta  la censura financiera del poderoso enemigo del Norte.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>