<template>
    <div class="row black light-green-text center">
        <div class="col s12" v-if="isLoading==true">
            <h5><b>...Cargando Tasas de Cambio</b></h5>
        </div>
        <div class="col s12" v-else>
            <h5>
                <i class="fas fa-arrow-circle-up light-green-text" v-if="variation==true"></i>
                <i class="fas fa-arrow-circle-down red-text" v-if="variation==false"></i>
                <b>{{ ' ' + rate }} </b>
            </h5>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import useBoilerplate from '@/composables/useBoilerplate'
import { onMounted } from '@vue/runtime-core';
export default {
    setup(){

        const { isLoading } = useBoilerplate(true);
    
        const displayRates = [];
        const variationRates = [];
        const rate = ref(null);
        const variation = ref(null);
        let counter = 0;

        onMounted(async () => {

            const url = '/api/public/getRates';
            const res = await fetch(url, {cache: "no-cache"});
            const data = await res.json();
            displayRates.push(`CUP/BTC: ${data[0]} CUP por 1 $`);
            displayRates.push(`MLC/BTC: ${data[1]} MLC por 1 $`);
            variationRates.push(data[4]);
            variationRates.push(data[5]);

            setInterval(() => {
                isLoading.value = false;
                rate.value = displayRates[counter];
                variation.value = variationRates[counter];
                counter = counter == 0 ? 1 : 0;
            }, 3000);
        })

        return { isLoading, rate, variation }
    }
}
</script>

<style>

</style>