<template>
    <h1 class = "margin">
        <i class="fas fa-credit-card grey-text"></i>
    </h1>
</template>

<style scoped>
    h1{
        font-size: 120px;
    }
</style>