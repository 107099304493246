<template>
    <a class="btn-large white-text" :class="color" @click="handleClick"> 
        <i :class="icon" v-if="position=='left'"></i> 
        {{ text }}
        <i :class="icon" v-if="position=='right'"></i>
    </a>
</template>

<script>
export default {
    props: ['color', 'text', "icon", "position"],
    emits: ['clicked'],
    setup(props, context){
        const handleClick = () => {
            context.emit('clicked');
        }
        return { handleClick }
    }
}
</script>

<style scoped>
    .btn-large{
        margin-bottom: 20px;
        width: 95%;
        max-width: 330px;
        border-radius: 20px;
        transition: all .2s ease-in-out; 
    }

    .btn-large:hover{
        transform: scale(1.02);
    }
</style>