<template>
  <Nav/>
  <router-view/>
  <Footer />
</template>

<script>

import { provide } from 'vue';
import Nav from '@/components/nav/Nav'
import Footer from '@/components/UI/Footer'
import state from '@/store/state'

export default {
  
  components: { Nav, Footer },
  
  setup(){
    provide('store', state);
  }

}
</script>

