<template>
    <MenuButton isMob="true" target="Home" text="Inicio" icon="fas fa-globe-americas"/>
    <MenuButton isMob="true" target="Auth" text="Entrar" icon="fas fa-user"/>
    <MenuButton isMob="true" target="Tutorials" text="Tutoriales" icon="fas fa-graduation-cap"/>
    <MenuButton isMob="true" target="Stats" text="Datos" icon="far fa-chart-bar"/>
    <MenuButton isMob="true" target="ContactUs" text="Contactenos" icon="fas fa-envelope"/>

</template>
<script>

import MenuButton from '@/components/UI/Buttons/MenuButton'

export default {
    components: { MenuButton }
}
</script>

<style>

</style>