<template>
    <h1 class = "margin">
        <i class="fas fa-bullhorn grey-text"></i>
    </h1>
    <h6 class="grey-text">
        Su Anuncio Va a Ser Visible por 7 Dias
    </h6>
</template>

<style scoped>
    .fa-bullhorn{
        font-size: 120px;
    }
</style>