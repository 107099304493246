<template>
    <div class="col s12 msgBox grey darken-4">
        
        <div class="col s10 ">
            <form class = 'picForm'>
            
                <div class="file-field input-field" v-if="error==null && isLoading == false">
                    <div class="btn-small white orange-text">
                        <span><i class = "fas fa-image"></i></span>
                        <input type="file" ref='picture' @change="triggerUpload">
                    </div>
                    <div class="file-path-wrapper">
                        <input 
                            class="file-path" 
                            id = 'fileP' 
                            type="text" 
                            placeholder = 'Enviar Foto'
                        >
                    </div>
                </div>

                <div class="col s12 loaderDIV" v-if="isLoading">
                    <Loader  />
                </div>
                

            </form>
        </div>

        <div class="col s2 center" >
            <p v-if="!isLoading">
                <i class="fas fa-pen orange-text" @click="switchView"></i> 
            </p>
        </div>

    </div>

    <teleport to="body">
        <div hidden>
            <canvas></canvas>
        </div>
    </teleport>
    
</template>

<script>

import { socket } from '@/composables/socket'
import { ref } from '@vue/reactivity'
import { saveMSG } from '@/composables/saveMSG'
import { setPic } from '@/composables/pictures'
import Loader from '@/components/UI/Loader'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    
    props: ['room', 'counterparty'],
    emits: ['switch'],
    components: { Loader },
    setup(props,context){

        const { store, router, error, isLoading } = useBoilerplate(false);
        
        const picture = ref(null);
        let pictureBig;
        let pictureSmall;

        const switchView = () => {
            context.emit('switch');
        }

        const uploadPic = () =>{
            if(store.state.alias == ''){
                return router.push({name: 'Auth'});
            }
            const photo = picture.value.files[0];
            //If there is no picture and alias is set already, prevent submission
            
            if(!photo){
                isLoading.value = false;
                return error.value = "Necesario Seleccionar una Foto";
            }
            
            const xhr = new XMLHttpRequest();
            const url = '/api/uploadChatPicture';
            xhr.open('POST', url, true);
            //Create new FormData Object
            let formdata = new FormData();
            const canvasData = document.querySelector('canvas').toDataURL("image/png");
            const splitData = canvasData.split(';base64,');
            
            //if there s a pic append it
            if(picture.value.files[0]){
                formdata.append('photo', splitData[1]);
            }

            //Append Room
            //Set up object
            formdata.append('room', props.room);
            
            xhr.onreadystatechange = () => {

                if(xhr.readyState === 4 && xhr.status === 500){
                    isLoading.value = false;
                    return error.value = "Error!";
                }
        
                if(xhr.readyState === 4 && xhr.status === 401){
                    isLoading.value = false;
                    return error.value = xhr.responseText;
                }
        
                if(xhr.readyState === 4 && xhr.status === 200) {
                    const myResponse = JSON.parse(xhr.responseText);
                    pictureSmall = myResponse.pictureSmall;
                    pictureBig = myResponse.pictureBig;
                    sendMsg();
                    isLoading.value = false;
                    context.emit('switch');
                }

            }

            //send http post request to submit FormData
            xhr.send(formdata);
            picture.value.value = '';
            isLoading.value = true;
        }

        const sendMsg = () =>{
            
            //Build Object
            const roomClear = store.state.alias > props.counterparty ? 
                                store.state.alias + "---" + props.counterparty :
                                props.counterparty + "---" + store.state.alias; 
            
            const msg2BeSaved = {
                message: '',
                room: roomClear,
                roomId: props.room,
                sender: store.state.alias,
                counterparty: props.counterparty,
                pictureBig:  pictureBig,
                pictureSmall: pictureSmall
            }

            //Send it through socket
            socket.emit('send message', msg2BeSaved);
            
            //Update UI Locally
            msg2BeSaved.timestamp = '';
            store.state.updateMSGlist(msg2BeSaved);
            
            //Save MSG
            msg2BeSaved.pictureBig = pictureBig;
            msg2BeSaved.pictureSmall = pictureSmall;
            saveMSG(msg2BeSaved);

        }

        const triggerUpload = () => {
            
            const compression = setPic(picture);

            if(compression.status == false){
                error.value = compression.error;
                isLoading.value = compression.isLoading;
                return context.emit('switch');
            }else{
                setTimeout(function(){uploadPic()}, 1000);
            }

        }

        return { picture, switchView, error, isLoading, triggerUpload }
    
    }
    
}
</script>

<style scoped>
    
    .msgBox{
        margin: 0 auto !important;
        position: absolute;
        width: 100% !important;
        bottom: 0px !important;
        left: 0% !important;
        height: 15%;
        border-top: 5px solid orange;
    }

    .fa-pen {
        font-size: 18px;
        position: relative;
        top: 15px;
    }

    .loaderDIV{
        margin-top: 20px;
    }

</style>