<template>
    <router-link :to="{name: 'Chat', params: {room: room.id}}">
        <div 
            class = "col s12 valign-wrapper z-depth-3 valign-wrapper marginTop infinityCard" 
            :class="{unseen: room.read !== true && room.sender !== myAlias}"
        >

            <Presence :alias="room.counterParty" :lastOnline="room.lastOnline" :url="picUrl" :index="index"/>

            <div class = "col s8 left-align grey-text text-darken-2">
                <h6 class = "truncate"><b>{{room.sender}}:</b></h6>
                <p class = "truncate" v-if="room.message!=''">{{room.message}}</p>
                <p class = "truncate" v-if="room.pictureSmall && room.message == ''">
                    Foto <i class = "fas fa-paperclip grey-text"></i>
                </p>
            </div>
        </div>
    </router-link>
    

</template>

<script>
import Presence from '@/components/UI/Presence'
import { computed } from '@vue/runtime-core'
import { formatPicUrl } from '@/composables/pictures'
import useBoilerplate from '@/composables/useBoilerplate';

export default {

    components: { Presence },
    props: ['room', 'index', 'myAlias'],
    
    setup(props){
        
        const {store} = useBoilerplate(false);
        
        const bgColor = computed(() => {
            if(props.room.read !== true && props.room.sender !== props.myAlias){
                return 'orange ';
            }else{
                return 'white';
            }
        })

        const txColor = computed(() => {
            if(props.room.read !== true && props.room.sender !== props.myAlias){
                return 'white-text ';
            }else{
                return 'grey-text text-darken-2';
            }
        })

        const picUrl = computed(() => formatPicUrl(props.room.counterPartyPic))

        return { store, bgColor, txColor, picUrl };
    }
}
</script>

<style scoped>
    .truncate{
        margin-left: 15% !important;
    }
    .unseen{
        border: 8px solid yellowgreen;
    }
</style>