<template>

    <div class="row grey darken-3" style='padding-bottom: 20px'>
        <div class="col s10 offset-s1 white-text  bigText">
            <h4 class = 'orange-text'>Cómo Comprar y Vender Bitcoin en Cuba</h4>
            <p>Con QBita es posible comprar y vender Bitcoin en Cuba de forma totalmente segura.</p>
            <p>QBita garantiza la seguridad de vendedores y compradores gracias a la tecnologia MultiSig del protocolo Bitcoin, reduciendo a zero el riesgo de estafas.</p>
            <p>Ademas, siendo QBita un Exchange descentralizado, no Custodial y P2P (el usuario Fulano vende al usuario Mengano), las compraventas son perfectamente legales.</p>
            <p>Mire el video para ver cómo funciona el proceso de conraventa segura en QBita.</p>
        </div>
        <div class = 'col s10 offset-s1 center-align' id='iframeContainer0'></div>
    </div>

    <div class="row grey lighten-5" style='padding-bottom: 20px'>
        <div class="col s10 offset-s1 orange-text  bigText grey-text text-darken-2">
            <h4 class = 'orange-text'>Cómo Adquirir MLC por medio de Bitcoin en Cuba</h4>
            <p>No todos los Cubanos tienen familiares y amigos en el exterior que puedan enviar MLC a su tarjeta.</p>
            <p>Estas personas, en muchos casos, pueden conseguir MLC solo por medio del mercado informal, donde se le aplicarán tasas de cambio que pueden llegar hasta 40 cup por cada Dolar Americano depositado en la tarjeta en MLC.</p>
            <p><b class = 'orange-text'>Gracias a Bitcoin, existe una alternativa más rentable para adquirir MLC en Cuba.</b></p>
            <p>Para conseguir MLC es suficiente vender Bitcoin en QBita especificando <b class = 'orange-text'>MLC</b> (Moneda Libremente Convertible) como metodo de pago.</p>
            <p>El comprador (otro usuario de QBita) paga los Bitcoin por medio de una transferencia de saldo MLC a la tarjeta del vendedor.</p>
            <p>En dependencia de la relación demanda/oferta de Bitcoin en Cuba en el momento que se ejecute la operación de intercambio, es posible que, vendiendo Bitcoin por MLC en QBITA, el usuario goze de tasas de cambio positivas (margen del vendedor positivo).</p>
            <p><b class = "orange-text">EJEMPLO:</b> especificando MLC como metodo de pago, el usuario Fulano vende en QBita 100 usd en Bitcoin al usuario Mengano aplicando un margen del 2%. MEngano paga los Bitcoin a Fulano por medio de Transfermovil.</p>
            <p>Fulano recibe 102 MLC a su tarjeta ese mismo dia. Sin colas.</p>
            <p>Barato, rápido y fácil.</p>
        </div>
        <!--<div class = 'col s10 offset-s1 center-align' id='iframeContainer1'></div>-->
    </div>

    <div class="row">
        <div class="col s10 offset-s1 grey-text text-darken-2">
            <h4 class = 'orange-text'>Tecnología del Exchange QBita</h4>
            <p class = 'bigText white-text'>
                El exchange QBita se caracteriza por la facilidad de su uso.
                El Usuario solo necesita hacer click en pocos botones para beneficiarse de lo último en la tecnología Bitcoin:
            </p>
        </div>
        <div class="col s10 offset-s1 white-text" style = 'padding-left: 30px;' >
            <ul class = 'bigText'>
                <li><i class="fas fa-check green-text"></i> <b>NO es Custodial:</b> el usuario (y solo el usuario) tiene el control directo de sus claves privadas. Esto maximiza la seguridad de las operaciones de intercambio entre los usuarios.</li>
                <br>
                <li><i class="fas fa-check green-text"></i> <b>Deposito 0: </b> Compra y Vende Bitcoin en Cuba sin depositar absolutamente nada en ningun monedero de la plataforma. Ni 1 satoshi.</li>
                <br>
                <li><i class="fas fa-check green-text"></i> <b>NO VPN:</b> funciona sin VPN en todos los Países (también en Cuba por supuesto), porque no depende de terceras partes. QBita se apoya a un Full Node Bitcoin propio.</li>
                <br>
                <li><i class="fas fa-check green-text"></i> <b>SegWit:</b> transacciones más livianas, más rápidas y más baratas.</li>
                <br>
                <li><i class="fas fa-check green-text"></i> <b>MultiSig:</b> depósitos seguros en direcciónes MultiSig (2 de 3) dinamicas durante las operaciones de compraventa Bitcoin/FIAT en el Exchange de Qbita. Zero riesgo de ser estafado cuando compras o vendes Bitcoin en Cuba (<a href = 'https://youtu.be/z9i-ZSe1MZc' target='_blank'>Más Informaciones sobre Bitcoin MultiSig</a>).</li>
                <br>
                <li><i class="fas fa-check green-text"></i> <b>Multi-Currency:</b> compra y vende Bitcoin en Cuba con dinero FIAT Cubano (CUP), MLC, ETH y stable-coins (USDT y DAI)</li>
                <br>
                <li><i class="fas fa-check green-text"></i> <b>BIP39 (Seed Mnemónico de 12 palabras):</b> exporta/importa las claves. Interoperable con cualquier monedero que adopte el estándar BIP39 (<a href = 'https://www.youtube.com/watch?v=WX68qidJEVg'>Guía Completa</a>)</li>
                <br>
                <li><i class="fas fa-check green-text"></i> <b>BIP32 (HD Wallet): </b> direcciónes multiSig (multifirma) mono-uso para maximizar la privacidad y la seguridad de los usuarios</li>
                <br>
                <li><i class="fas fa-check green-text"></i> <b>2FA (Opcional): </b> doble protección para los fondos</li>
            </ul>
        </div>
        <div class = 'col s10 offset-s1 bigText grey-text text-darken-2'>
            <p><b class = 'green-text'>Todo sin obligar el Usuario a graduarse en ciencias informáticas</b></p>
        </div>
    </div>

    <div class="row yellow lighten-5" style='padding-bottom: 20px'>
        <div class="col s10 offset-s1 orange-text  bigText grey-text text-darken-2">
            <h4 class = 'orange-text'>Tutoriales: cómo utilizar Bitcoin en Cuba</h4>
            <p>Mire el video para descubrir cómo crear su cuenta en el Exchange Bitcoin #1 de Cuba en 1 minuto.</p>
            <p>Si desea aprender más sobre Bitcoin, revise todos los tutoriales de QBita en nuestro canal <a href='https://www.youtube.com/channel/UC4aeB8P_aKbH4JjGX8HJk-g' target = '_blank'>YouTube</a> o directamente <router-link :to="{name: 'Tutorials'}">en nuestro sitio</router-link>.</p>
        </div>
        <div class = 'col s10 offset-s1 center-align' id='iframeContainer'></div>
    </div>

    <div class="row">
        <div class="col s10 offset-s1">
            <h4 class = 'orange-text'>Roadmap de desarrollo</h4>
        </div>
        <div class="col s10 offset-s1 grey-text text-darken-2 center-align">
            <img src="@/assets/images/media/roadmap.webp" alt="Desarrollo del Exchange Bitcoin QBita - Hecho 100% en Cuba" class = "responsive-img">
        </div>
    </div>

    <div class="divider"></div>

    <div class = 'row grey lighten-5' style='padding-bottom: 20px'>
        <div class="col s10 offset-s1 orange-text">
            <h4 class = 'orange-text'>QBita en los Medios</h4>
        </div>
        <div class="col s10 offset-s1 m3 offset-m1 center-align">
            <a href="https://decrypt.co/es/26436/cuba-tiene-plataforma-intercambio-bitcoin-btc-p2p-qbita" target = '_blank'>
                <h6 class = 'grey-text center-align'><b>decrypt.co</b></h6>
                <img src="@/assets/images/media/decrypt.webp" style = 'width: 95%; height: 150px; margin: auto' alt='Articulo de Decrypt sobre QBita, el exchange Bitcoin Cubano'>
            </a>
        </div>
        <div class="col s10 offset-s1 m4 center-align">
            <a href="https://cryptocrew.org/article/5dc0a47e64c0cb7076e7956e?fbclid=IwAR3JrpyV58hFpqvhj_3pOdvPDyW2WcArr00Fqi7Qk_U4xz96jJ50YRNG530" target = '_blank'>
                <h6 class = 'grey-text center-align'><b>cryptocrew.org</b></h6>
                <img src="@/assets/images/media/cryptocrew.webp" style = 'width: 95%; height: 150px; margin: auto' alt='Intrevista de Cryptocrew al creador del primer mondero Bitcoin cubano'>
            </a>
        </div>
        <div class="col s10 offset-s1 m3 center-align">
            <a href="https://cointelegraph.com/news/cubas-first-p2p-bitcoin-exchange-launches-amid-regulatory-uncertainty" target = '_blank'>
                <h6 class = 'grey-text center-align'><b>cointelegraph.com</b></h6>
                <img src="@/assets/images/media/cointelegraph.webp" style = 'width: 95%; height: 150px; margin: auto' alt='Pagina de Reddit dedicada a Qbita y Bitcoin en Cuba'>
            </a>
        </div>
        <div class="col s10 offset-s1 m4 offset-m1 center-align">
            <a href="https://es.beincrypto.com/conociendo-a-qbita-la-primera-wallet-de-bitcoin-desarrollada-en-cuba/" target = '_blank'>
                <h6 class = 'grey-text center-align'><b>beincrypto.com</b></h6>
                <img src="@/assets/images/media/be.webp" style = 'width: 95%; height: 150px; margin: auto'>
            </a>
        </div>
        <div class="col s10 offset-s1 m3 center-align">
            <a href="https://bitcoinmagazine.nl/2019/11/bitcoin-btc-wallet-cuba/" target = '_blank'>
                <h6 class = 'grey-text center-align'><b>bitcoinmagazine.nl</b></h6>
                <img src="@/assets/images/media/magazine.webp" style = 'width: 95%; height: 150px; margin: auto' alt='BitcoinMagazine hable de QBita y Bitcoin en Cuba'>
            </a>
        </div>
        <div class="col s10 offset-s1 m3 center-align">
            <a href="https://www.morocotacoin.com/2019/11/criptomonedas-en-cuba-mito-o-realidad/" target = '_blank'>
                <h6 class = 'grey-text center-align'><b>morocotacoin.com</b></h6>
                <img src="@/assets/images/media/morocota.webp" style = 'width: 95%; height: 150px; margin: auto' alt='Articulo de MorocotaCoin sobre el utilizo de Bitcoin en Cuba'>
            </a>
        </div>
        <div class="col s10 offset-s1 m3 offset-m1 center-align">
            <a href="https://www.cubanet.org/tecnologia-2/empresario-italo-cubano-crea-qbita-un-exchange-de-bitcoin-para-cuba/" target = '_blank'>
                <h6 class = 'grey-text center-align'><b>cubanet.org</b></h6>
                <img src="@/assets/images/media/cubanet.webp" style = 'width: 95%; height: 150px; margin: auto' alt='Articulo sobre QBita y Bitcoin en Cuba en publicacion Cubana'>
            </a>
        </div>
        <div class="col s10 offset-s1 m3 center-align">
            <a href="https://blockcrypto.io/en/crypto-news/6393/cuba-bitcoin-wallet-tailor-made-e-commerce-island" target = '_blank'>
                <h6 class = 'grey-text center-align'><b>blockcrypto.io</b></h6>
                <img src="@/assets/images/media/bitcoin_cuba_cryptoamigo.webp" style = 'width: 95%; height: 150px; margin: auto' alt='Qbita en cryptoamigo'>
            </a>
        </div>
        <div class="col s10 offset-s1 m4 center-align">
            <a href="https://www.financemagnates.com/cryptocurrency/exchange/cubas-first-bitcoin-exchange-launches-despite-restrictions/" target = '_blank'>
                <h6 class = 'grey-text center-align'><b>financemagnates.com</b></h6>
                <img src="@/assets/images/media/financial_magnates.webp" style = 'width: 95%; height: 150px; margin: auto' alt='Articulo del elHodler que describe el papel del Exchange Bitcoin QBita para la difusion de Bitcoin en Cuba'>
            </a>
        </div>
    </div>

    <div class="row">
        <div class="col s10 offset-s1 grey-text text-darken-2">
            <h4 class = 'orange-text'>No Android?</h4>
            <h5 class = 'white-text'>No Hay Problema!</h5>
        </div>
        <div class="col s10 offset-s1 white-text" style = 'padding-left: 30px;' >
            <p class = 'bigText'>El Exchange QBita, además de ser una aplicación Android Nativa, es también un Exchange Web, que puede ser utilizado por medio de todos los navegadores más comunes, (como por ejemplo Chrome, Firefox, Opera, Safari y Edge) con todos los Sistemas Operativos (Microsoft, Linux, IOS) y todos los dispositivos (laptop, tablet y móvil).</p>
            <p class = 'bigText'>Además, siendo el Exchange QBita una Progressive Web App, puede ser descargado también en los dispositivos IOS y puede ser utilizado como si fuera una aplicación nativa.</p>
        </div>
    </div>

    <div class="divider"></div>

</template>