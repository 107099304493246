<template>
    <div class = "col s12 center-align orange white-text">
        <h5>Venta Segura</h5>
    </div>
    <div class = "col s12">
        <p class = 'left-align white-text'>
            Los Bitcoin que Usted envie al escrow multifirma serán entregados al comprador
            <b class = 'orange-text'> SOLO DESPUES </b> 
            que Usted haya confirmado la recepción de los <b>{{ fiatString }}.</b>
        </p>
    </div>
    
    <div class="input-field col s12">
    <Select 
        @changed="handleCard"
    >
        <option 
            :value="card._id"
            v-for="(card, index) in cards" 
            :key="card._id"
        >
            {{card.cardNumber}} <span v-if="index > 1">({{card.currency}})</span>
        </option>
    </Select>
    </div>
</template>

<script>
import Select from '@/components/UI/Select'
export default {
    props: ['fiatString', 'cards'],
    emits: ['cardSelected'],
    components: { Select },
    setup(props, context){
        const handleCard = value => context.emit('cardSelected', value);
        return { handleCard }
    }
}
</script>

<style>

</style>