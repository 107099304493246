<template>
    <select ref='selector' class="browser-default" @change="handleChange">
        <slot></slot>
    </select>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {

    emits: ['changed'],

    setup(props, context){
        
        const selector = ref(null);
        
        const handleChange = () => {
            const value = selector.value.options[selector.value.selectedIndex].value;
            context.emit('changed', value);
        }

        return { selector, handleChange }
    }
}
</script>