<template>
    
    <Slider />
    <Ticker />
    <Porque />
    <LastTXS />
    <Difundiendo />
    <HomeText />
    
</template>

<script>
import Slider from '@/components/home/Slider'
import Ticker from '@/components/home/Ticker'
import Porque from '@/components/home/Porque'
import LastTXS from '@/components/home/LastTXS'
import Difundiendo from '@/components/home/Difundiendo'
import HomeText from '@/components/home/HomeText'

export default {
  components: { Slider, Ticker, HomeText, LastTXS, Porque, Difundiendo }
}
</script>
