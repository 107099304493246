<template>
  <main>

        <div class="container">

            <div class="row">
              <div class="col s10 offset-s1 center-align">
                  <h1 class="orange-text">Tutoriales</h1>
                  <h4 class='white-text'>Aprenda cómo utilizar Bitcoin en Cuba</h4>
              </div>
            </div>

            <div class="divider"></div>

            <div class="row">
              <div class="col s10 offset-s1 center-align">
                  <h2 class="white-text">Exchange</h2>
              </div>
            </div>

            <div class="divider"></div>

            <div class="row">
              <div class="col s10 offset-s1 center-align">
                <h5 class="orange-text marginTop marginBottom">Cómo publicar en anuncio para comprar/vender Bitcoin en QBita</h5>
                <iframe width="100%" height= "450px" src="https://www.youtube.com/embed/xK2YPi5kbQU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class = 'marginBottom'></iframe>
              </div>
            </div>

            <div class="divider"></div>
            
            <div class="row">
              <div class="col s10 offset-s1 center-align">
                <h5 class="orange-text marginTop marginBottom">Cómo Comprar y Vender Bitcoin en QBita</h5>
                <iframe width="100%" height= "450px" src="https://www.youtube.com/embed/rV4K5zMcA1M" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class = 'marginBottom'></iframe>
              </div>
            </div>

            <div class="row">
              <div class="col s10 offset-s1 center-align">
                <h5 class="orange-text marginTop marginBottom">Cómo Comprar Bitcoin en QBita</h5>
                <iframe width="100%" height= "450px" src="https://www.youtube.com/embed/giOWLSV3fjc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class = 'marginBottom'></iframe>
              </div>
            </div>

            <div class="row">
              <div class="col s10 offset-s1 center-align">
                <h5 class="orange-text marginTop marginBottom">Cómo Vender Bitcoin en QBita</h5>
                <iframe width="100%" height= "450px" src="https://www.youtube.com/embed/C0wOC29acKo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class = 'marginBottom'></iframe>
              </div>
            </div>
          
            <div class="row">
              <div class="col s10 offset-s1 center-align">
                <h5 class="orange-text marginTop marginBottom">Las Fees en el Exchange QBita</h5>
                <iframe width="100%" height= "450px" src="https://www.youtube.com/embed/xwaQig7817A" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class = 'marginBottom'></iframe>
              </div>
            </div>

            <div class="divider"></div>
            
            <div class="row">
              <div class="col s10 offset-s1 center-align">
                <h5 class="orange-text marginTop marginBottom">Que es una dirección multifirma?</h5>
                <iframe width="100%" height= "450px" src="https://www.youtube.com/embed/z9i-ZSe1MZc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class = 'marginBottom'></iframe>
              </div>
            </div>

            <div class="divider"></div>

          </div>

    </main>
</template>
