<template>
    
    <Success v-if="isSuccess" title="Transacción Enviada" @goBack="goBack">
        <h1><i class="fas fa-check light-green-text"></i></h1>
    </Success>

    <form @submit.prevent="compose" v-else>
        
        <div class = "input-field col s12">
            <input type = "text" id="beneficiary" v-model="beneficiary" required>
            <label for = "beneficiary" class = 'grey-text'><i class="fas fa-wallet"></i> <span>Enviar a:</span></label>
        </div>

        <div class = "input-field col s12" v-if="isManual==true">
            <input type = "text" id="manualFee" v-model="fee" >
            <label for = "manualFee" class = 'grey-text'><i class="fas fa-price-tag"></i> <span>Costos de Envio (sats/byte):</span></label>
            <p class = "orange-text" @click="handleManual">Selección Automatica</p>
        </div>

        <div class="input-field col s12 margin30" v-else>
            <Select @changed="handleFee" >
              <option :value="fees[0].sats">Seleccionar Costos de Envio (mineria)</option>
              <option :value="fees[0].sats">{{fees[0].label}} - {{fees[0].sats}} sats/byte</option>
              <option :value="fees[1].sats">{{fees[1].label}} - {{fees[1].sats}} sats/byte</option>
              <option :value="fees[1].sats * 2">{{fees[2].label}} - {{fees[1].sats * 2}} sats/byte</option>
            </Select>
            <p class = "orange-text" @click="handleManual">Selección Manual</p>
        </div>

        <div class="col s12 left-align white-text margin">
            <h5 @click="switchCurrency" v-if="isBTC==true"><b class="orange-text">BTC</b> <i class="fas fa-arrow-right"></i> <span class="white-text">USD</span></h5>
            <h5 @click="switchCurrency" v-else><b class="orange-text">USD</b> <i class="fas fa-arrow-right"></i> <span class="white-text">BTC</span></h5>
        </div>

        <AmountBTC v-if="isBTC==true" :max="max" :xChange="xChange" @typed="handleBTC" @max="isMax=true" @noMax="isMax=false"/>
        <AmountUSD v-else :xChange="xChange" :max="max" @typed="handleBTC" @max="isMax=true" @noMax="isMax=false"/>

        <div class="col s12 center feedback-box">
          <Loader v-if="isLoading" />
          <Error v-if="error" :warning="error" />
        </div>
        
        <div class="col s12 center">
            <LargeButton 
                color="green marginTop" 
                text="Enviar" 
                icon="fas fa-paper-place"
                position="left"
                @click="compose"
            />
            <div class="col s12" v-if="isMax">
            <p class = "orange-text center" >
              Se va a enviar un poco menos, para pagar los costos de envio (mineria)
            </p>
        </div>
        </div>
    </form>

    <teleport to="body">
        <SendBtcModal 
            :isActive="isActive" 
            :hex="hex"
            :address="beneficiary"
            :amount="amountBTC"
            :feeBTC="feeBTC"
            :feeUSD="feeUSD"
            @close="isActive=false" 
            @success="handleSuccess"
        />
    </teleport>

</template>

<script>
import LargeButton from '@/components/UI/Buttons/LargeButton';
import Error from '@/components/UI/Error';
import Loader from '@/components/UI/Loader';
import Select from '@/components/UI/Select';
import Success from '@/components/UI/Success';
import SendBtcModal from '@/components/modals/SendBtcModal';
import AmountBTC from '@/components/Wallet/AmountBTC'
import AmountUSD from '@/components/Wallet/AmountUSD'
import useBoilerplate from '@/composables/useBoilerplate';
import { getSize } from '@/composables/fee';
import { post } from '@/composables/httpCalls'
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';

export default {
    components: { 
      LargeButton, AmountBTC, AmountUSD, Error, Loader, Select, SendBtcModal, Success
    },
    props: ['max', 'xChange', 'fees', 'utxos'],

    setup(props, context){
        
        const { error, isLoading, router, store, isActive, isSuccess } = useBoilerplate(false); 
        
        let address = '';
        const beneficiary = ref('');
        const isBTC = ref(false);
        const amountBTC = ref(0);
        const hex = ref('');
        const feeBTC = ref(0);
        const feeUSD = ref(0);
        const fee = ref(props.fees[0].sats);
        const isManual = ref(false);
        const isMax = ref(false);

        const switchCurrency = () => {
          amountBTC.value = 0;
          isMax.value = false;
          isBTC.value = !isBTC.value;
        }

        const handleBTC = value => amountBTC.value = value;

        const goBack = () => router.push({name: "WalletBTC"});

        const handleFee = value => fee.value = value;

        const handleManual = () => {
          isManual.value = !isManual.value;
          if(isManual.value == true){
            setTimeout(() => {
              document.getElementById("manualFee").focus();
            }, 200);
          }
        }

        const handleSuccess = () => {
          isActive.value = false;
          isSuccess.value = true;
        }

        const compose = async () => {
          try {

            console.log('compose');
            error.value = null;
            
            console.log(address, beneficiary.value, Number(amountBTC.value).toFixed(8));
            
            if(beneficiary.value == ''){
              return error.value = "Es Necesario Poner la Dirección del Beneficiario";
            }

            if(isNaN(amountBTC.value)){
              return error.value = "Monto Incorrecto";
            }

            if(Number(amountBTC.value).toFixed(8) > props.max){
              return error.value = "Monto Insuficiente";
            }

            if(fee.value < 0 || isNaN(fee.value)){
              return error.value = "Costos de Envio (Mineria) Incorrectos";
            }

            if(fee.value == 1){
              fee.value = 2;
            }

            const miningFee = Math.round(fee.value) * getSize(props.utxos);
            
            console.log("FEE: ", miningFee);
            const amount = isMax.value == false ? amountBTC.value * 100000000 : (amountBTC.value * 100000000) - miningFee;
            let change = isMax.value == false ? (props.max * 100000000) - miningFee - amount : 0;
            console.log("Change: ", change);

            if(change < 0){
              return error.value = "Es Necesario Bajar los Costos de Envio (Fee de Mineria)";
            }
            if(change < 600){
              change = 0;
            }

            const vars = {
              spender: address,
              utxos: props.utxos,
              beneficiary: beneficiary.value,
              change,
              amount,
              xPub: sessionStorage.getItem('xPub')
            }

            console.log(vars);

            isLoading.value = true;
            const url = "/api/composeBtcTX";

            const res = await post(url, vars);
            
            if(res.status == 200){
                feeBTC.value = (miningFee / 100000000).toFixed(8);
                feeUSD.value = Math.round(feeBTC.value * props.xChange * 100) / 100;
                const data = await res.json();
                hex.value = window.finalSignature(data.psbt, data.encKey, 1);
                console.log("HEX:\n\n", hex.value);
                if(hex.value == "keyError"){
                  error.value = "No Se Pudo Firmar la Transacción";
                  return isLoading.value = false;
                }
                isLoading.value = false;
                return isActive.value = true;
            }else if(res.status == 409){
              error.value = "La Dirección No Es Correcta"
              return isLoading.value = false;
            }else if(res.status == 404){
              error.value = "Es Necesario Bajar los Costos de Envio (Fee de Mineria)"
              return isLoading.value = false;
            }
            else if(res.status == 412){
              store.state.logUserOut();
              return router.push({name: 'Auth'});
            }else{
              console.log(error);
              error.value = `Error (${res.status})`;
              return isLoading.value = false;
            }
          } catch (err) {
            console.log(err);
            error.value = "Error";
            isLoading.value = false;
          }
        }

        onMounted(() => address = sessionStorage.getItem('address'));

        return { 
            switchCurrency, handleBTC, compose, hex, isLoading, isMax,
            beneficiary, isBTC, amountBTC, isSuccess, error, fee, handleSuccess,
            isActive, feeBTC, feeUSD, goBack, handleFee, isManual, handleManual
        }
        
    }

}
</script>

<style scoped>
  .margin30{
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 600px) {
    .feedback-box{
      min-height: 80px !important;
    }
  }
 
</style>