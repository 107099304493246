<template>
    
    <div class="col s12 center-align orange white-text">
        <h5>Download APK</h5>
    </div>

    <div class="col s12 m6 center-align marginTop">
        <img src = '@/assets/images/media/downAndroid.webp' style = 'width: 70px; height: 70px' alt='Descarga el Exchange Bitcoin QBita en GooglePlay'>
        <h6>
            <a href='https://play.google.com/store/apps/details?id=org.qbita.bitcoin_wallet' target ='_blank'>Descarga con GooglePlay</a>
        </h6>
    </div>

    <div class="col s12 m6 center-align marginTop">
        <img src = '@/assets/images/media/downCubapk.png' style = 'width: 70px; height: 70px' alt = 'Descarga el Exchange Bitcoin QBita en CubApk.com'>
        <h6>
            <a href='https://cubapk.com/store/org.qbita.bitcoin_wallet' target ='_blank'>Descarga con CubApk</a>
        </h6>
    </div>

</template>