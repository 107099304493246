<template>
  <p class = 'red-text left-align'>
    <i class = 'fas fa-exclamation-circle icon20'></i> Depósito Parcial en Escrow multiSig
  </p>
  
  <p class = 'left-align'>
    La Compraventa ha sido cancelada por que el vendedor ha depositado un monto insuficiente en el multifirma. Por ende, es necesario empezar la compraventa desde zero.
  </p>

  <p 
    class = 'left-align'
    v-if="store.state.escrow.escrow.seller == store.state.alias"
  >
    Para recuperar los Bitcoin depositados, especifique los costos de envio (fee de mineria) y la dirección donde quiere recibir los Bitcoin (puede ser de cualquier billetera).<br>
    Luego haga click en el boton verde para recuperar los fondos depositados en el multiSig.
  </p>
  
  <SignTXform 
    v-if="store.state.escrow.escrow.seller == store.state.alias"
  />
    
</template>

<script>
import SignTXform from '@/components/Escrows/Bitcoin/SignTXform';
import useBoilerplate from '@/composables/useBoilerplate';
export default {
  components: { SignTXform },
  setup(){
    const { store } = useBoilerplate(false);
    return { store };
  }
}
</script>