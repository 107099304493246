<template>
    <div 
        @click.stop="showAdDetails" 
        class = "col s12 white valign-wrapper z-depth-3 grey-text text-darken-3 margin infinityCard" 
        :class="ad.type" 
    >
        
        <Presence :url="picture" :alias="ad.user" :index="index" :lastOnline="ad.lastOnline" />

        <div class = "col s8 left-align">
            
            <h6 class = "orange-text">
                <b>{{ad.adType}} Bitcoin</b>
            </h6>
            
            <p>
                <b>Monto:</b> {{ad.monto}} $ en <i class = "fab fa-bitcoin orange-text icon20"></i> por {{ fiatString }}
            </p>

            <p v-if="ad.xchange != null">
                <b>Tasa de Cambio:</b> {{ad.xchange}} {{divisa}} por 1 USD
            </p>
            
            <p v-else>
                <b>Margen:</b> {{ad.margen}}%
            </p>
            
            <DeleteAdBTN v-if="ad.user==myAlias" :id="ad._id"/>

            <a v-else href = "#" class = "blue-text">- Más detalles -</a>
        
        </div>

    </div>

</template>

<script>
import { computed } from '@vue/runtime-core'
import DeleteAdBTN from '@/components/Ads/DeleteAdBTN'
import Presence from '@/components/UI/Presence'
import { conversionX } from '@/composables/ratesX'
import { formatPicUrl } from '@/composables/pictures'

export default {
    props: ['ad', 'myAlias', 'index'],
    components: { DeleteAdBTN, Presence },
    emits: ['showAdDetails'],
    
    setup(props, context){

        const picture = computed(() => formatPicUrl(props.ad.picture))

        const fiatString = computed(() => {
            const conversions = conversionX(props.ad.monto, props.ad.margen, JSON.parse(sessionStorage.xRates), props.ad.xchange);
            return conversions[props.ad.divisa];
        });

        const divisa = computed(() => props.ad.divisa == "cubano" ? "CUP" : "MLC");

        return { picture, fiatString, divisa }
    }
}
</script>

<style scoped>

    .s8{
        margin-left: 15% !important;
    }
    .pushUp{
        position: relative;
        bottom: 15px;
    }
</style>