<template>

    <p class = "red-text" @click.stop="isActive=true">
        <i class = "fa fa-trash"></i> Borrar Anuncio
    </p>

    <teleport to="body">
        <CancelAdModal :isActive="isActive" :id="id" @close="isActive=false" />
    </teleport>

</template>

<script>

import { ref } from '@vue/reactivity'
import CancelAdModal from '@/components/modals/CancelAdModal';

export default {
    
    props: ['id'],
    components: { CancelAdModal },
    
    setup(){
        const isActive = ref(false);
        return { isActive };
    }

}

</script>