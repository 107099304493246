<template>
    <div class="col s12 center zero-results" >
        <h4 class="orange-text marginTop">
            0 {{ text }}
        </h4>
    </div>
</template>

<script>
export default {
    props: ['text']
}
</script>

<style scoped>
    .zero-results{
        margin-top: 150px !important;
    }
</style>