<template>
  <div class="col s12 ip z-depth-1">
      <div class="col s12 l4 left-align">
          <p>
              <b>IP</b>: {{ ip.ip }}
          </p>
      </div>
      <div class="col s12 l4 left-align">
          <p>
              <b>Lugar</b>: {{ ip.city }} ({{ ip.country }})
          </p>
      </div>
      <div class="col s12 l4 left-align">
          <p>
              <b>Fecha</b>: {{ fecha }}
          </p>
      </div>
      <div class="col s12 left-align">
          <p>
              <b>ISP</b>: {{ ip.isp }}
          </p>
      </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
export default {
    props: ['ip'],
    setup(props){
        const fecha = computed(() => {
            const date = new Date(props.ip.time);
            const month = date.getMonth() + 1;
            const myDate = date.getDate() + "/" +  month + "/" + date.getFullYear();
            return myDate;
        })

        return { fecha }
    }
}
</script>

<style scoped>
    
</style>