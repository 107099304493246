<template>
  <div class="modal-background" :class ="{active: isActive == true}" @click.self="closeModal">
    <div class="row modal-content center grey darken-3">
            <div class = "col s12 center-align orange white-text">
                <h5>Enviar BTC</h5>
            </div>
            
            <div class = "col s12 left-align white-text marginTop">
                <h6> Usted va a enviar {{ amount }} <i class="fab fa-bitcoin orange-text"></i> a la dirección:</h6>
                <h6 class="center">
                    <b class="light-green-text">{{cutKey(address, 4)}}</b>
                </h6>
                <h6><b>Costos de Envio (Mineria):</b> {{ feeBTC }} BTC ({{feeUSD}} USD)</h6>
            </div>

            <div class = "col s12 center-align grey-text text-darken-4" style ="margin-bottom: 20px">
                
                <Loader v-if="isLoading"/>
                <Error :warning="error" v-if="error!=null"/>                

            </div> 

            <LargeButton 
              color="green" 
              icon="fas fa-check" 
              text="Enviar" 
              position="left" 
              @clicked="handleClick"
            />

        </div>

    </div>

</template>

<script>

import Loader from '@/components/UI/Loader'
import Error from '@/components/UI/Error'
import LargeButton from '@/components/UI/Buttons/LargeButton'
import { broadcast } from '@/composables/broadcast'
import { cutKey } from '@/composables/helpers'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { Loader, Error, LargeButton },
    props: ['hex', 'address', 'address', 'amount', 'feeBTC', 'feeUSD', 'isActive'],
    emits: ['success', 'close'],

    setup(props, context){

        const {store, router, error, isLoading } = useBoilerplate(false);

        const handleClick = async () => {

            error.value = null;
            isLoading.value = true;
            
            try {
            
                const data = await broadcast(props.hex, "spend");
                
                isLoading.value = false;

                if(data.status == false){  
                    return error.value = data.error;
                }

                context.emit('success');
            
            } catch (err) {
                console.log(err);
                error.value = "Error"
                isLoading.value = false;
            }
            
        }

        const closeModal = () => {
            error.value = null;
            context.emit('close');
        }

        return { isLoading, error, cutKey, handleClick, closeModal }

      }

}
</script>