<template>
    <div class="row valign-wrapper">
        <Loader>
            <h5 class="orange-text left-align">Cargando...</h5>
        </Loader>
    </div>
</template>

<script>

import Loader from './Loader'

export default {
    components: { Loader }
}

</script>

<style scoped>
    .valign-wrapper{
        max-width: 600px;
        height: 500px;
    }
</style>