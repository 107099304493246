<template>
  <header v-if="hasNav">
    <div class="navbar-fixed">
        <nav class = "nav-wrapper white">
            <div class="container">
                <a href="#" class = "brand-logo blue-text"> <img src="@/assets/images/logo/bitcoincuba.png" alt="QBita Logo - Bitcoin para Cuba" class = 'logo'> </a>
                <a href="#" class = "sidenav-trigger btn orange" data-target = "mobile-links"> <i class="fas fa-bars white-text"></i></a>
                <ul class = "right hide-on-med-and-down">
                    <UnloggedMenuDesk v-if="menu !== 'logged' && menu != 'admin'"/>
                    <LoggedMenuDesk :menu="menu" v-else/>
                </ul>
                <ul class = "right show-on-medium-and-down" hidden>
                    <MenuButton isMob="false" target="FAQ" text="Preguntas" icon="fas fa-question"/>
                </ul>
            </div>
        </nav>
    </div>
    <ul class="sidenav orange" id = "mobile-links">
            <UnloggedMenuMob v-if="menu !== 'logged' && menu != 'admin'"/>
            <LoggedMenuMob v-else :menu="menu" />
        <li>
            <ul class = 'center-align white socialUL' >
            <a href="https://www.facebook.com/QBita-Monedero-Bitcoin-115076776534375/" target='_blank'><i class="fab fa-facebook-square blue-text text-darken-4 mobLinks icon30"></i></a>
            <a href="https://www.youtube.com/channel/UC4aeB8P_aKbH4JjGX8HJk-g" target='_blank' style = 'margin-right: 30px; margin-left: 30px'><i class="fab fa-youtube red-text mobLinks icon30"></i></a>
            <a href="https://t.me/Qbitaexchange" target='_blank'><i class="fab fa-telegram cyan-text text-darken-1 mobLinks icon30"></i></a> 
            </ul>
        </li> 
        <li class = 'mobLinks center-align'><p class = 'white-text'>Versión 6.0</p></li>
    </ul>
    
  </header>
</template>

<script>


import LoggedMenuDesk from '@/components/nav/nav-links/LoggedMenuDesk'
import LoggedMenuMob from '@/components/nav/nav-links/LoggedMenuMob'
import UnloggedMenuDesk from '@/components/nav/nav-links/UnloggedMenuDesk'
import UnloggedMenuMob from '@/components/nav/nav-links/UnloggedMenuMob'
import { socket } from '@/composables/socket'
import { play } from '@/composables/blink'

//import Faq from '@/components/FAQ/FaqBtn'
import MenuButton from '@/components/UI/Buttons/MenuButton'

import { onMounted, ref, watchEffect } from '@vue/runtime-core'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { MenuButton, LoggedMenuDesk, UnloggedMenuDesk, UnloggedMenuMob, LoggedMenuMob },
    setup(){

        const { store, route } = useBoilerplate(false);

        const hasNav = ref(true);
        const menu = ref('');
        
        onMounted(() => {
            M.AutoInit();
            if(sessionStorage.alias != null){
                store.state.logUserIn();
                store.state.setAlias(sessionStorage.alias);
            }
        });

        watchEffect(() => {
            if(store.state.isAdmin){
                return menu.value = 'admin';
            }else if(store.state.isLogged == true){
                return menu.value = 'logged';
            }else{
                return menu.value = '';
            }
        });

        watchEffect(() => {
            //Show or Hide Menu
            const navlessRoutes = ['/blocked', '/welcome', '/twoFA', '/walletRecover'];
            hasNav.value = navlessRoutes.indexOf(route.path) < 0 ? true : false;
        })

        socket.on('new message', data => {
            if(!route.path.includes(data.roomId)){
                play("chat");
                const menuBTN = document.querySelector('.sidenav-trigger');
                const chats = document.querySelectorAll('.chats');
                chats.forEach(item => item.classList.add('pulse'));
                if(menuBTN) menuBTN.classList.add('pulse');
            }
        })

        socket.on('stage bip', data => {
            console.log("i ll bip for new escrow evt");
            console.log(data._id);
            if(!route.path.includes(data._id)){
                play("escrow");
                const menuBTN = document.querySelector('.sidenav-trigger');
                const escrows = document.querySelectorAll('.escrows');
                escrows.forEach(item => item.classList.add('pulse'));
                if(menuBTN) menuBTN.classList.add('pulse');
            }
            
        })
        
        return { menu, hasNav };
    }
}
</script>

<style scoped>
    .socialUL{
        margin-top: 30px; 
        padding-top: 15px; 
        padding-bottom: 15px;
        border-top: 3px solid orange;
        border-bottom: 3px solid orange;
    }
</style>